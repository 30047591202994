import { OptionModel } from '../models/OptionModel';

const timezones: OptionModel[] = [
  // UTC+14:00
  {
    label: 'UTC+14:00',
    value: 'Pacific/Kiritimati'
  },
  // UTC+13:00
  { label: 'UTC+13:00', value: 'Pacific/Apia' },
  {
    label: 'UTC+13:00',
    value: 'Pacific/Enderbury'
  },
  { label: 'UTC+13:00', value: 'Pacific/Fakaofo' },
  {
    label: 'UTC+13:00',
    value: 'Pacific/Tongatapu'
  },
  // UTC+12:45
  { label: 'UTC+12:45', value: 'Pacific/Chatham' },
  // UTC+12:00
  {
    label: 'UTC+12:00',
    value: 'Antarctica/McMurdo'
  },
  { label: 'UTC+12:00', value: 'Asia/Anadyr' },
  { label: 'UTC+12:00', value: 'Asia/Kamchatka' },
  { label: 'UTC+12:00', value: 'Pacific/Auckland' },
  { label: 'UTC+12:00', value: 'Pacific/Fiji' },
  { label: 'UTC+12:00', value: 'Pacific/Funafuti' },
  {
    label: 'UTC+12:00',
    value: 'Pacific/Kwajalein'
  },
  { label: 'UTC+12:00', value: 'Pacific/Majuro' },
  { label: 'UTC+12:00', value: 'Pacific/Nauru' },
  { label: 'UTC+12:00', value: 'Pacific/Tarawa' },
  { label: 'UTC+12:00', value: 'Pacific/Wake' },
  { label: 'UTC+12:00', value: 'Pacific/Wallis' },
  // UTC+11:00
  { label: 'UTC+11:00', value: 'Antarctica/Casey' },
  {
    label: 'UTC+11:00',
    value: 'Antarctica/Macquarie'
  },
  { label: 'UTC+11:00', value: 'Asia/Magadan' },
  { label: 'UTC+11:00', value: 'Asia/Sakhalin' },
  {
    label: 'UTC+11:00',
    value: 'Asia/Srednekolymsk'
  },
  {
    label: 'UTC+11:00',
    value: 'Pacific/Bougainville'
  },
  { label: 'UTC+11:00', value: 'Pacific/Efate' },
  {
    label: 'UTC+11:00',
    value: 'Pacific/Guadalcanal'
  },
  { label: 'UTC+11:00', value: 'Pacific/Kosrae' },
  { label: 'UTC+11:00', value: 'Pacific/Norfolk' },
  { label: 'UTC+11:00', value: 'Pacific/Noumea' },
  { label: 'UTC+11:00', value: 'Pacific/Pohnpei' },
  // UTC+10:30
  {
    label: 'UTC+10:30',
    value: 'Australia/Lord_Howe'
  },
  // UTC+10:00
  {
    label: 'UTC+10:00',
    value: 'Antarctica/DumontDUrville'
  },
  { label: 'UTC+10:00', value: 'Asia/Ust-Nera' },
  { label: 'UTC+10:00', value: 'Asia/Vladivostok' },
  {
    label: 'UTC+10:00',
    value: 'Australia/Brisbane'
  },
  { label: 'UTC+10:00', value: 'Australia/Currie' },
  { label: 'UTC+10:00', value: 'Australia/Hobart' },
  {
    label: 'UTC+10:00',
    value: 'Australia/Lindeman'
  },
  {
    label: 'UTC+10:00',
    value: 'Australia/Melbourne'
  },
  { label: 'UTC+10:00', value: 'Australia/Sydney' },
  { label: 'UTC+10:00', value: 'Pacific/Chuuk' },
  { label: 'UTC+10:00', value: 'Pacific/Guam' },
  {
    label: 'UTC+10:00',
    value: 'Pacific/Port_Moresby'
  },
  { label: 'UTC+10:00', value: 'Pacific/Saipan' },
  // UTC+09:30
  {
    label: 'UTC+09:30',
    value: 'Australia/Adelaide'
  },
  {
    label: 'UTC+09:30',
    value: 'Australia/Broken_Hill'
  },
  { label: 'UTC+09:30', value: 'Australia/Darwin' },
  // UTC+09:00
  { label: 'UTC+09:00', value: 'Asia/Chita' },
  { label: 'UTC+09:00', value: 'Asia/Dili' },
  { label: 'UTC+09:00', value: 'Asia/Jayapura' },
  { label: 'UTC+09:00', value: 'Asia/Khandyga' },
  { label: 'UTC+09:00', value: 'Asia/Seoul' },
  { label: 'UTC+09:00', value: 'Asia/Tokyo' },
  { label: 'UTC+09:00', value: 'Asia/Yakutsk' },
  { label: 'UTC+09:00', value: 'Pacific/Palau' },
  // UTC+08:45
  { label: 'UTC+08:45', value: 'Australia/Eucla' },
  // UTC+08:30
  { label: 'UTC+08:30', value: 'Asia/Pyongyang' },
  // UTC+08:00
  { label: 'UTC+08:00', value: 'Asia/Brunei' },
  { label: 'UTC+08:00', value: 'Asia/Choibalsan' },
  { label: 'UTC+08:00', value: 'Asia/Hong_Kong' },
  { label: 'UTC+08:00', value: 'Asia/Irkutsk' },
  {
    label: 'UTC+08:00',
    value: 'Asia/Kuala_Lumpur'
  },
  { label: 'UTC+08:00', value: 'Asia/Kuching' },
  { label: 'UTC+08:00', value: 'Asia/Macau' },
  { label: 'UTC+08:00', value: 'Asia/Makassar' },
  { label: 'UTC+08:00', value: 'Asia/Manila' },
  { label: 'UTC+08:00', value: 'Asia/Shanghai' },
  { label: 'UTC+08:00', value: 'Asia/Singapore' },
  { label: 'UTC+08:00', value: 'Asia/Taipei' },
  { label: 'UTC+08:00', value: 'Asia/Ulaanbaatar' },
  { label: 'UTC+08:00', value: 'Australia/Perth' },
  // UTC+07:00
  { label: 'UTC+07:00', value: 'Antarctica/Davis' },
  { label: 'UTC+07:00', value: 'Asia/Bangkok' },
  { label: 'UTC+07:00', value: 'Asia/Barnaul' },
  { label: 'UTC+07:00', value: 'Asia/Ho_Chi_Minh' },
  { label: 'UTC+07:00', value: 'Asia/Hovd' },
  { label: 'UTC+07:00', value: 'Asia/Jakarta' },
  { label: 'UTC+07:00', value: 'Asia/Krasnoyarsk' },
  {
    label: 'UTC+07:00',
    value: 'Asia/Novokuznetsk'
  },
  { label: 'UTC+07:00', value: 'Asia/Novosibirsk' },
  { label: 'UTC+07:00', value: 'Asia/Phnom_Penh' },
  { label: 'UTC+07:00', value: 'Asia/Pontianak' },
  { label: 'UTC+07:00', value: 'Asia/Tomsk' },
  { label: 'UTC+07:00', value: 'Asia/Vientiane' },
  { label: 'UTC+07:00', value: 'Indian/Christmas' },
  // UTC+06:30
  { label: 'UTC+06:30', value: 'Asia/Yangon' },
  { label: 'UTC+06:30', value: 'Indian/Cocos' },
  // UTC+06:00
  {
    label: 'UTC+06:00',
    value: 'Antarctica/Vostok'
  },
  { label: 'UTC+06:00', value: 'Asia/Almaty' },
  { label: 'UTC+06:00', value: 'Asia/Bishkek' },
  { label: 'UTC+06:00', value: 'Asia/Dhaka' },
  { label: 'UTC+06:00', value: 'Asia/Omsk' },
  { label: 'UTC+06:00', value: 'Asia/Qyzylorda' },
  { label: 'UTC+06:00', value: 'Asia/Thimphu' },
  { label: 'UTC+06:00', value: 'Asia/Urumqi' },
  { label: 'UTC+06:00', value: 'Indian/Chagos' },
  // UTC+05:45
  { label: 'UTC+05:45', value: 'Asia/Kathmandu' },
  // UTC+05:30
  { label: 'UTC+05:30', value: 'Asia/Colombo' },
  { label: 'UTC+05:30', value: 'Asia/Kolkata' },
  // UTC+05:00
  {
    label: 'UTC+05:00',
    value: 'Antarctica/Mawson'
  },
  { label: 'UTC+05:00', value: 'Asia/Aqtau' },
  { label: 'UTC+05:00', value: 'Asia/Aqtobe' },
  { label: 'UTC+05:00', value: 'Asia/Ashgabat' },
  { label: 'UTC+05:00', value: 'Asia/Atyrau' },
  { label: 'UTC+05:00', value: 'Asia/Dushanbe' },
  { label: 'UTC+05:00', value: 'Asia/Karachi' },
  { label: 'UTC+05:00', value: 'Asia/Oral' },
  { label: 'UTC+05:00', value: 'Asia/Samarkand' },
  { label: 'UTC+05:00', value: 'Asia/Tashkent' },
  {
    label: 'UTC+05:00',
    value: 'Asia/Yekaterinburg'
  },
  { label: 'UTC+05:00', value: 'Indian/Kerguelen' },
  { label: 'UTC+05:00', value: 'Indian/Maldives' },
  // UTC+04:30
  { label: 'UTC+04:30', value: 'Asia/Kabul' },
  { label: 'UTC+04:30', value: 'Asia/Tehran' },
  // UTC+04:00
  { label: 'UTC+04:00', value: 'Asia/Baku' },
  { label: 'UTC+04:00', value: 'Asia/Dubai' },
  { label: 'UTC+04:00', value: 'Asia/Muscat' },
  { label: 'UTC+04:00', value: 'Asia/Tbilisi' },
  { label: 'UTC+04:00', value: 'Asia/Yerevan' },
  { label: 'UTC+04:00', value: 'Europe/Astrakhan' },
  { label: 'UTC+04:00', value: 'Europe/Samara' },
  { label: 'UTC+04:00', value: 'Europe/Saratov' },
  { label: 'UTC+04:00', value: 'Europe/Ulyanovsk' },
  { label: 'UTC+04:00', value: 'Indian/Mahe' },
  { label: 'UTC+04:00', value: 'Indian/Mauritius' },
  { label: 'UTC+04:00', value: 'Indian/Reunion' },
  // UTC+03:00
  {
    label: 'UTC+03:00',
    value: 'Africa/Addis_Ababa'
  },
  { label: 'UTC+03:00', value: 'Africa/Asmara' },
  {
    label: 'UTC+03:00',
    value: 'Africa/Dar_es_Salaam'
  },
  { label: 'UTC+03:00', value: 'Africa/Djibouti' },
  { label: 'UTC+03:00', value: 'Africa/Juba' },
  { label: 'UTC+03:00', value: 'Africa/Kampala' },
  { label: 'UTC+03:00', value: 'Africa/Khartoum' },
  { label: 'UTC+03:00', value: 'Africa/Mogadishu' },
  { label: 'UTC+03:00', value: 'Africa/Nairobi' },
  { label: 'UTC+03:00', value: 'Antarctica/Syowa' },
  { label: 'UTC+03:00', value: 'Asia/Aden' },
  { label: 'UTC+03:00', value: 'Asia/Amman' },
  { label: 'UTC+03:00', value: 'Asia/Baghdad' },
  { label: 'UTC+03:00', value: 'Asia/Bahrain' },
  { label: 'UTC+03:00', value: 'Asia/Beirut' },
  { label: 'UTC+03:00', value: 'Asia/Damascus' },
  { label: 'UTC+03:00', value: 'Asia/Famagusta' },
  { label: 'UTC+03:00', value: 'Asia/Gaza' },
  { label: 'UTC+03:00', value: 'Asia/Hebron' },
  { label: 'UTC+03:00', value: 'Asia/Jerusalem' },
  { label: 'UTC+03:00', value: 'Asia/Kuwait' },
  { label: 'UTC+03:00', value: 'Asia/Nicosia' },
  { label: 'UTC+03:00', value: 'Asia/Qatar' },
  { label: 'UTC+03:00', value: 'Asia/Riyadh' },
  { label: 'UTC+03:00', value: 'Europe/Athens' },
  { label: 'UTC+03:00', value: 'Europe/Bucharest' },
  { label: 'UTC+03:00', value: 'Europe/Chisinau' },
  { label: 'UTC+03:00', value: 'Europe/Helsinki' },
  { label: 'UTC+03:00', value: 'Europe/Istanbul' },
  { label: 'UTC+03:00', value: 'Europe/Kiev' },
  { label: 'UTC+03:00', value: 'Europe/Kirov' },
  { label: 'UTC+03:00', value: 'Europe/Mariehamn' },
  { label: 'UTC+03:00', value: 'Europe/Minsk' },
  { label: 'UTC+03:00', value: 'Europe/Moscow' },
  { label: 'UTC+03:00', value: 'Europe/Riga' },
  {
    label: 'UTC+03:00',
    value: 'Europe/Simferopol'
  },
  { label: 'UTC+03:00', value: 'Europe/Sofia' },
  { label: 'UTC+03:00', value: 'Europe/Tallinn' },
  { label: 'UTC+03:00', value: 'Europe/Uzhgorod' },
  { label: 'UTC+03:00', value: 'Europe/Vilnius' },
  { label: 'UTC+03:00', value: 'Europe/Volgograd' },
  {
    label: 'UTC+03:00',
    value: 'Europe/Zaporozhye'
  },
  {
    label: 'UTC+03:00',
    value: 'Indian/Antananarivo'
  },
  { label: 'UTC+03:00', value: 'Indian/Comoro' },
  { label: 'UTC+03:00', value: 'Indian/Mayotte' },
  // UTC+02:00
  { label: 'UTC+02:00', value: 'Africa/Blantyre' },
  { label: 'UTC+02:00', value: 'Africa/Bujumbura' },
  { label: 'UTC+02:00', value: 'Africa/Cairo' },
  { label: 'UTC+02:00', value: 'Africa/Ceuta' },
  { label: 'UTC+02:00', value: 'Africa/Gaborone' },
  { label: 'UTC+02:00', value: 'Africa/Harare' },
  {
    label: 'UTC+02:00',
    value: 'Africa/Johannesburg'
  },
  { label: 'UTC+02:00', value: 'Africa/Kigali' },
  {
    label: 'UTC+02:00',
    value: 'Africa/Lubumbashi'
  },
  { label: 'UTC+02:00', value: 'Africa/Lusaka' },
  { label: 'UTC+02:00', value: 'Africa/Maputo' },
  { label: 'UTC+02:00', value: 'Africa/Maseru' },
  { label: 'UTC+02:00', value: 'Africa/Mbabane' },
  { label: 'UTC+02:00', value: 'Africa/Tripoli' },
  { label: 'UTC+02:00', value: 'Antarctica/Troll' },
  {
    label: 'UTC+02:00',
    value: 'Arctic/Longyearbyen'
  },
  { label: 'UTC+02:00', value: 'Europe/Amsterdam' },
  { label: 'UTC+02:00', value: 'Europe/Andorra' },
  { label: 'UTC+02:00', value: 'Europe/Belgrade' },
  { label: 'UTC+02:00', value: 'Europe/Berlin' },
  {
    label: 'UTC+02:00',
    value: 'Europe/Bratislava'
  },
  { label: 'UTC+02:00', value: 'Europe/Brussels' },
  { label: 'UTC+02:00', value: 'Europe/Budapest' },
  { label: 'UTC+02:00', value: 'Europe/Busingen' },
  {
    label: 'UTC+02:00',
    value: 'Europe/Copenhagen'
  },
  { label: 'UTC+02:00', value: 'Europe/Gibraltar' },
  {
    label: 'UTC+02:00',
    value: 'Europe/Kaliningrad'
  },
  { label: 'UTC+02:00', value: 'Europe/Ljubljana' },
  {
    label: 'UTC+02:00',
    value: 'Europe/Luxembourg'
  },
  { label: 'UTC+02:00', value: 'Europe/Madrid' },
  { label: 'UTC+02:00', value: 'Europe/Malta' },
  { label: 'UTC+02:00', value: 'Europe/Monaco' },
  { label: 'UTC+02:00', value: 'Europe/Oslo' },
  { label: 'UTC+02:00', value: 'Europe/Paris' },
  { label: 'UTC+02:00', value: 'Europe/Podgorica' },
  { label: 'UTC+02:00', value: 'Europe/Prague' },
  { label: 'UTC+02:00', value: 'Europe/Rome' },
  {
    label: 'UTC+02:00',
    value: 'Europe/San_Marino'
  },
  { label: 'UTC+02:00', value: 'Europe/Sarajevo' },
  { label: 'UTC+02:00', value: 'Europe/Skopje' },
  { label: 'UTC+02:00', value: 'Europe/Stockholm' },
  { label: 'UTC+02:00', value: 'Europe/Tirane' },
  { label: 'UTC+02:00', value: 'Europe/Vaduz' },
  { label: 'UTC+02:00', value: 'Europe/Vatican' },
  { label: 'UTC+02:00', value: 'Europe/Vienna' },
  { label: 'UTC+02:00', value: 'Europe/Warsaw' },
  { label: 'UTC+02:00', value: 'Europe/Zagreb' },
  { label: 'UTC+02:00', value: 'Europe/Zurich' },
  // UTC+01:00
  { label: 'UTC+01:00', value: 'Africa/Algiers' },
  { label: 'UTC+01:00', value: 'Africa/Bangui' },
  {
    label: 'UTC+01:00',
    value: 'Africa/Brazzaville'
  },
  {
    label: 'UTC+01:00',
    value: 'Africa/Casablanca'
  },
  { label: 'UTC+01:00', value: 'Africa/Douala' },
  { label: 'UTC+01:00', value: 'Africa/El_Aaiun' },
  { label: 'UTC+01:00', value: 'Africa/Kinshasa' },
  { label: 'UTC+01:00', value: 'Africa/Lagos' },
  {
    label: 'UTC+01:00',
    value: 'Africa/Libreville'
  },
  { label: 'UTC+01:00', value: 'Africa/Luanda' },
  { label: 'UTC+01:00', value: 'Africa/Malabo' },
  { label: 'UTC+01:00', value: 'Africa/Ndjamena' },
  { label: 'UTC+01:00', value: 'Africa/Niamey' },
  {
    label: 'UTC+01:00',
    value: 'Africa/Porto-Novo'
  },
  { label: 'UTC+01:00', value: 'Africa/Tunis' },
  { label: 'UTC+01:00', value: 'Africa/Windhoek' },
  { label: 'UTC+01:00', value: 'Atlantic/Canary' },
  { label: 'UTC+01:00', value: 'Atlantic/Faroe' },
  { label: 'UTC+01:00', value: 'Atlantic/Madeira' },
  { label: 'UTC+01:00', value: 'Europe/Dublin' },
  { label: 'UTC+01:00', value: 'Europe/Guernsey' },
  {
    label: 'UTC+01:00',
    value: 'Europe/Isle_of_Man'
  },
  { label: 'UTC+01:00', value: 'Europe/Jersey' },
  { label: 'UTC+01:00', value: 'Europe/Lisbon' },
  { label: 'UTC+01:00', value: 'Europe/London' },
  // UTC
  { label: 'UTC', value: 'Africa/Abidjan' },
  { label: 'UTC', value: 'Africa/Accra' },
  { label: 'UTC', value: 'Africa/Bamako' },
  { label: 'UTC', value: 'Africa/Banjul' },
  { label: 'UTC', value: 'Africa/Bissau' },
  { label: 'UTC', value: 'Africa/Conakry' },
  { label: 'UTC', value: 'Africa/Dakar' },
  { label: 'UTC', value: 'Africa/Freetown' },
  { label: 'UTC', value: 'Africa/Lome' },
  { label: 'UTC', value: 'Africa/Monrovia' },
  { label: 'UTC', value: 'Africa/Nouakchott' },
  { label: 'UTC', value: 'Africa/Ouagadougou' },
  { label: 'UTC', value: 'Africa/Sao_Tome' },
  {
    label: 'UTC',
    value: 'America/Danmarkshavn'
  },
  {
    label: 'UTC',
    value: 'America/Scoresbysund'
  },
  { label: 'UTC', value: 'Atlantic/Azores' },
  { label: 'UTC', value: 'Atlantic/Reykjavik' },
  { label: 'UTC', value: 'Atlantic/St_Helena' },
  { label: 'UTC', value: 'UTC' },
  // UTC-01:00
  {
    label: 'UTC-01:00',
    value: 'Atlantic/Cape_Verde'
  },
  // UTC-02:00
  { label: 'UTC-02:00', value: 'America/Godthab' },
  { label: 'UTC-02:00', value: 'America/Miquelon' },
  { label: 'UTC-02:00', value: 'America/Noronha' },
  {
    label: 'UTC-02:00',
    value: 'Atlantic/South_Georgia'
  },
  // UTC-02:30
  { label: 'UTC-02:30', value: 'America/St_Johns' },
  // UTC-03:00
  {
    label: 'UTC-03:00',
    value: 'America/Araguaina'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Buenos_Aires'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Catamarca'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Cordoba'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Jujuy'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/La_Rioja'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Mendoza'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Rio_Gallegos'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Salta'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/San_Juan'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/San_Luis'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Tucuman'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Argentina/Ushuaia'
  },
  { label: 'UTC-03:00', value: 'America/Bahia' },
  { label: 'UTC-03:00', value: 'America/Belem' },
  { label: 'UTC-03:00', value: 'America/Cayenne' },
  {
    label: 'UTC-03:00',
    value: 'America/Fortaleza'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Glace_Bay'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Goose_Bay'
  },
  { label: 'UTC-03:00', value: 'America/Halifax' },
  { label: 'UTC-03:00', value: 'America/Maceio' },
  { label: 'UTC-03:00', value: 'America/Moncton' },
  {
    label: 'UTC-03:00',
    value: 'America/Montevideo'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Paramaribo'
  },
  {
    label: 'UTC-03:00',
    value: 'America/Punta_Arenas'
  },
  { label: 'UTC-03:00', value: 'America/Recife' },
  { label: 'UTC-03:00', value: 'America/Santarem' },
  {
    label: 'UTC-03:00',
    value: 'America/Sao_Paulo'
  },
  { label: 'UTC-03:00', value: 'America/Thule' },
  {
    label: 'UTC-03:00',
    value: 'Antarctica/Palmer'
  },
  {
    label: 'UTC-03:00',
    value: 'Antarctica/Rothera'
  },
  { label: 'UTC-03:00', value: 'Atlantic/Bermuda' },
  { label: 'UTC-03:00', value: 'Atlantic/Stanley' },
  // UTC-04:00
  { label: 'UTC-04:00', value: 'America/Anguilla' },
  { label: 'UTC-04:00', value: 'America/Antigua' },
  { label: 'UTC-04:00', value: 'America/Aruba' },
  { label: 'UTC-04:00', value: 'America/Asuncion' },
  { label: 'UTC-04:00', value: 'America/Barbados' },
  {
    label: 'UTC-04:00',
    value: 'America/Blanc-Sablon'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Boa_Vista'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Campo_Grande'
  },
  { label: 'UTC-04:00', value: 'America/Caracas' },
  { label: 'UTC-04:00', value: 'America/Cuiaba' },
  { label: 'UTC-04:00', value: 'America/Curacao' },
  { label: 'UTC-04:00', value: 'America/Detroit' },
  { label: 'UTC-04:00', value: 'America/Dominica' },
  {
    label: 'UTC-04:00',
    value: 'America/Grand_Turk'
  },
  { label: 'UTC-04:00', value: 'America/Grenada' },
  {
    label: 'UTC-04:00',
    value: 'America/Guadeloupe'
  },
  { label: 'UTC-04:00', value: 'America/Guyana' },
  { label: 'UTC-04:00', value: 'America/Havana' },
  {
    label: 'UTC-04:00',
    value: 'America/Indiana/Indianapolis'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Indiana/Marengo'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Indiana/Petersburg'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Indiana/Vevay'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Indiana/Vincennes'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Indiana/Winamac'
  },
  { label: 'UTC-04:00', value: 'America/Iqaluit' },
  {
    label: 'UTC-04:00',
    value: 'America/Kentucky/Louisville'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Kentucky/Monticello'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Kralendijk'
  },
  { label: 'UTC-04:00', value: 'America/La_Paz' },
  {
    label: 'UTC-04:00',
    value: 'America/Lower_Princes'
  },
  { label: 'UTC-04:00', value: 'America/Manaus' },
  { label: 'UTC-04:00', value: 'America/Marigot' },
  {
    label: 'UTC-04:00',
    value: 'America/Martinique'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Montserrat'
  },
  { label: 'UTC-04:00', value: 'America/Nassau' },
  { label: 'UTC-04:00', value: 'America/New_York' },
  { label: 'UTC-04:00', value: 'America/Nipigon' },
  {
    label: 'UTC-04:00',
    value: 'America/Pangnirtung'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Port-au-Prince'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Port_of_Spain'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Porto_Velho'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Puerto_Rico'
  },
  { label: 'UTC-04:00', value: 'America/Santiago' },
  {
    label: 'UTC-04:00',
    value: 'America/Santo_Domingo'
  },
  {
    label: 'UTC-04:00',
    value: 'America/St_Barthelemy'
  },
  { label: 'UTC-04:00', value: 'America/St_Kitts' },
  { label: 'UTC-04:00', value: 'America/St_Lucia' },
  {
    label: 'UTC-04:00',
    value: 'America/St_Thomas'
  },
  {
    label: 'UTC-04:00',
    value: 'America/St_Vincent'
  },
  {
    label: 'UTC-04:00',
    value: 'America/Thunder_Bay'
  },
  { label: 'UTC-04:00', value: 'America/Toronto' },
  { label: 'UTC-04:00', value: 'America/Tortola' },
  // UTC-05:00
  { label: 'UTC-05:00', value: 'America/Atikokan' },
  {
    label: 'UTC-05:00',
    value: 'America/Bahia_Banderas'
  },
  { label: 'UTC-05:00', value: 'America/Bogota' },
  { label: 'UTC-05:00', value: 'America/Cancun' },
  { label: 'UTC-05:00', value: 'America/Cayman' },
  { label: 'UTC-05:00', value: 'America/Chicago' },
  { label: 'UTC-05:00', value: 'America/Eirunepe' },
  {
    label: 'UTC-05:00',
    value: 'America/Guayaquil'
  },
  {
    label: 'UTC-05:00',
    value: 'America/Indiana/Knox'
  },
  {
    label: 'UTC-05:00',
    value: 'America/Indiana/Tell_City'
  },
  { label: 'UTC-05:00', value: 'America/Jamaica' },
  { label: 'UTC-05:00', value: 'America/Lima' },
  {
    label: 'UTC-05:00',
    value: 'America/Matamoros'
  },
  {
    label: 'UTC-05:00',
    value: 'America/Menominee'
  },
  { label: 'UTC-05:00', value: 'America/Merida' },
  {
    label: 'UTC-05:00',
    value: 'America/Mexico_City'
  },
  {
    label: 'UTC-05:00',
    value: 'America/Monterrey'
  },
  {
    label: 'UTC-05:00',
    value: 'America/North_Dakota/Beulah'
  },
  {
    label: 'UTC-05:00',
    value: 'America/North_Dakota/Center'
  },
  {
    label: 'UTC-05:00',
    value: 'America/North_Dakota/New_Salem'
  },
  { label: 'UTC-05:00', value: 'America/Panama' },
  {
    label: 'UTC-05:00',
    value: 'America/Rainy_River'
  },
  {
    label: 'UTC-05:00',
    value: 'America/Rankin_Inlet'
  },
  { label: 'UTC-05:00', value: 'America/Resolute' },
  {
    label: 'UTC-05:00',
    value: 'America/Rio_Branco'
  },
  { label: 'UTC-05:00', value: 'America/Winnipeg' },
  // UTC-06:00
  { label: 'UTC-06:00', value: 'America/Belize' },
  { label: 'UTC-06:00', value: 'America/Boise' },
  {
    label: 'UTC-06:00',
    value: 'America/Cambridge_Bay'
  },
  {
    label: 'UTC-06:00',
    value: 'America/Chihuahua'
  },
  {
    label: 'UTC-06:00',
    value: 'America/Costa_Rica'
  },
  { label: 'UTC-06:00', value: 'America/Denver' },
  { label: 'UTC-06:00', value: 'America/Edmonton' },
  {
    label: 'UTC-06:00',
    value: 'America/El_Salvador'
  },
  {
    label: 'UTC-06:00',
    value: 'America/Guatemala'
  },
  { label: 'UTC-06:00', value: 'America/Inuvik' },
  { label: 'UTC-06:00', value: 'America/Managua' },
  { label: 'UTC-06:00', value: 'America/Mazatlan' },
  { label: 'UTC-06:00', value: 'America/Ojinaga' },
  { label: 'UTC-06:00', value: 'America/Regina' },
  {
    label: 'UTC-06:00',
    value: 'America/Swift_Current'
  },
  {
    label: 'UTC-06:00',
    value: 'America/Tegucigalpa'
  },
  {
    label: 'UTC-06:00',
    value: 'America/Yellowknife'
  },
  { label: 'UTC-06:00', value: 'Pacific/Easter' },
  {
    label: 'UTC-06:00',
    value: 'Pacific/Galapagos'
  },
  // UTC-07:00
  { label: 'UTC-07:00', value: 'America/Creston' },
  { label: 'UTC-07:00', value: 'America/Dawson' },
  {
    label: 'UTC-07:00',
    value: 'America/Dawson_Creek'
  },
  {
    label: 'UTC-07:00',
    value: 'America/Fort_Nelson'
  },
  {
    label: 'UTC-07:00',
    value: 'America/Hermosillo'
  },
  {
    label: 'UTC-07:00',
    value: 'America/Los_Angeles'
  },
  { label: 'UTC-07:00', value: 'America/Phoenix' },
  { label: 'UTC-07:00', value: 'America/Tijuana' },
  {
    label: 'UTC-07:00',
    value: 'America/Vancouver'
  },
  {
    label: 'UTC-07:00',
    value: 'America/Whitehorse'
  },
  // UTC-08:00
  {
    label: 'UTC-08:00',
    value: 'America/Anchorage'
  },
  { label: 'UTC-08:00', value: 'America/Juneau' },
  {
    label: 'UTC-08:00',
    value: 'America/Metlakatla'
  },
  { label: 'UTC-08:00', value: 'America/Nome' },
  { label: 'UTC-08:00', value: 'America/Sitka' },
  { label: 'UTC-08:00', value: 'America/Yakutat' },
  { label: 'UTC-08:00', value: 'Pacific/Pitcairn' },
  // UTC-09:00
  { label: 'UTC-09:00', value: 'America/Adak' },
  { label: 'UTC-09:00', value: 'Pacific/Gambier' },
  // UTC-09:30
  {
    label: 'UTC-09:30',
    value: 'Pacific/Marquesas'
  },
  // UTC-10:00
  { label: 'UTC-10:00', value: 'Pacific/Honolulu' },
  {
    label: 'UTC-10:00',
    value: 'Pacific/Rarotonga'
  },
  { label: 'UTC-10:00', value: 'Pacific/Tahiti' },
  // UTC-11:00
  { label: 'UTC-11:00', value: 'Pacific/Midway' },
  { label: 'UTC-11:00', value: 'Pacific/Niue' },
  { label: 'UTC-11:00', value: 'Pacific/Pago_Pago' }
];

const getUtcOffset = (label: string) =>
  label === 'UTC' ? label : label.replace('UTC', '');

const getReplacedTimeZoneValue = (value: string) => value.replace(/_/g, ' ');

export const getUtcOffsetFromTimezone = (timezone: string) => {
  const found = timezones.find((x) => x.value === timezone);
  return found ? getUtcOffset(found.label) : '';
};

export const IANA_TIMEZONES: OptionModel[] = timezones.map((x) => ({
  ...x,
  label: `${getReplacedTimeZoneValue(x.value)} (${getUtcOffset(x.label)})`
}));
