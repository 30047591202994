import { IntegrationDefinitionTypes } from '@/common/models/site/IntegrationTypes';
import { Site } from '@/common/models/site/Site';

import { FrontIntegrationEventTypes } from './FrontIntegrationEventTypes';

export interface IIntegration {
  loadAsync(): Promise<any>;
  trackEventAsync(eventName: string, eventData: any): Promise<any>;
  updateConsentAsync(consented: boolean): Promise<any>;
}

export enum IntegrationTypes {
  GoogleTagManager = 'GoogleTagManager',
  AdobeTagManager = 'AdobeTagManager',
  KomoCookieConsent = 'KomoCookieConsent',
  OneTrust = 'OneTrust'
}

export type IntegrationConfig =
  | {
      type: IntegrationTypes.GoogleTagManager;
      // category: TagManager, ?
      containerId: string;
    }
  | {
      type: IntegrationTypes.AdobeTagManager;
      // category: TagManager, ?
      scriptSource: string;
    }
  | {
      type: IntegrationTypes.KomoCookieConsent;
      // category: Consent, ?
      enabled: boolean;
    }
  | {
      type: IntegrationTypes.OneTrust;
      id: string;
    };

export interface IntegrationServiceProps {
  integrations?: IntegrationConfig[];
  debug?: boolean;
}

export class IntegrationService implements IIntegration {
  public static buildIntegrationConfig = (site?: Site) => {
    const i: IntegrationConfig[] = [];
    const gtmIntegration = site?.frontIntegrations?.find(
      (i) => i.integration.type === IntegrationDefinitionTypes.GoogleTagManager
    );
    if (!!gtmIntegration) {
      i.push({
        type: IntegrationTypes.GoogleTagManager,
        containerId: gtmIntegration.properties?.ScriptId
      });
    }

    const adobeIntegration = site?.frontIntegrations?.find(
      (i) => i.integration.type === IntegrationDefinitionTypes.AdobeAnalytics
    );

    if (!!adobeIntegration) {
      i.push({
        type: IntegrationTypes.AdobeTagManager,
        scriptSource: adobeIntegration.properties?.ScriptId
      });
    }

    if (site?.properties?.CookieConsentEnabled) {
      i.push({ type: IntegrationTypes.KomoCookieConsent, enabled: true });
    }

    const oneTrustIntegration = site?.frontIntegrations?.find(
      (i) => i.integration.type === IntegrationDefinitionTypes.OneTrust
    );

    if (!!oneTrustIntegration) {
      i.push({
        type: IntegrationTypes.OneTrust,
        id: oneTrustIntegration.properties?.ScriptId
      });
    }

    return i;
  };

  private readonly integrations?: IntegrationConfig[];
  private readonly debug: boolean;

  constructor(props: IntegrationServiceProps = {}) {
    this.integrations = props.integrations;
    this.debug = !!props.debug;
  }

  private hasAnyIntegrations(): boolean {
    return this.integrations?.length > 0;
  }

  private isCookieConsentEnabled(): boolean {
    // todo other consent providers
    const cookieConfig: {
      type: IntegrationTypes.KomoCookieConsent;
      enabled: boolean;
    } = this.integrations?.find(
      (i) => i.type === IntegrationTypes.KomoCookieConsent
    ) as { type: IntegrationTypes.KomoCookieConsent; enabled: boolean };
    return cookieConfig?.enabled;
  }

  public async loadAsync(): Promise<any> {
    if (!this.hasAnyIntegrations()) {
      return;
    }

    if (this.debug) {
      console.debug(
        'loading integrations with config:',
        JSON.stringify(this.integrations)
      );
    }

    for (let i = 0; i < this.integrations.length; i++) {
      try {
        const integrationConfig = this.integrations[i];
        switch (integrationConfig.type) {
          case IntegrationTypes.GoogleTagManager:
            (
              await import(
                '@/front/data/IntegrationService/_GoogleTagManagerService'
              )
            ).GoogleTagManagerService.load(
              integrationConfig.containerId,
              this.isCookieConsentEnabled()
            );
            break;

          case IntegrationTypes.AdobeTagManager:
            (
              await import(
                '@/front/data/IntegrationService/_AdobeTagManagerService'
              )
            ).AdobeTagManagerService.load(
              integrationConfig.scriptSource,
              this.isCookieConsentEnabled()
            );
            break;

          case IntegrationTypes.OneTrust:
            (
              await import('@/front/data/IntegrationService/_OneTrustService')
            ).OneTrustService.load(integrationConfig.id);
            break;

          default:
            break;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  public async trackEventAsync(
    eventName: string,
    eventData: any = {}
  ): Promise<unknown> {
    if (!this.hasAnyIntegrations()) {
      return;
    }

    // stringify and parse to force any GUIDs to flatten out to strings.
    const flattenGuids = (x) => JSON.parse(JSON.stringify(x));

    const hostInfo = !!window
      ? {
          pageTitle: window.document?.title,
          hostName: window.location?.hostname,
          pathName: window.location?.pathname,
          queryString: window.location?.search,
          url: window.document?.URL,
          referrer: window.document?.referrer,
          languages: window.navigator?.languages
        }
      : {};

    const enrichedData = flattenGuids({ ...hostInfo, ...eventData });

    for (let i = 0; i < this.integrations.length; i++) {
      try {
        const integrationConfig = this.integrations[i];
        switch (integrationConfig.type) {
          case IntegrationTypes.GoogleTagManager:
            (
              await import(
                '@/front/data/IntegrationService/_GoogleTagManagerService'
              )
            ).GoogleTagManagerService.trackEvent(eventName, enrichedData);
            break;

          case IntegrationTypes.AdobeTagManager:
            (
              await import(
                '@/front/data/IntegrationService/_AdobeTagManagerService'
              )
            ).AdobeTagManagerService.trackEvent(eventName, enrichedData);
            break;
          default:
            break;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Static wrapper for track event that instantiates a copy of the integration service.
   * Todo: obsolete using an integration context and a shared service
   */
  public static staticTrackEventAsync(
    eventName: FrontIntegrationEventTypes,
    eventData: any,
    site?: Site
  ) {
    return new IntegrationService({
      integrations: IntegrationService.buildIntegrationConfig(site)
    }).trackEventAsync(eventName, eventData);
  }

  public async updateConsentAsync(consented: boolean): Promise<any> {
    if (!this.hasAnyIntegrations()) {
      return;
    }

    for (let i = 0; i < this.integrations.length; i++) {
      try {
        const integrationConfig = this.integrations[i];
        switch (integrationConfig.type) {
          case IntegrationTypes.GoogleTagManager:
            (
              await import(
                '@/front/data/IntegrationService/_GoogleTagManagerService'
              )
            ).GoogleTagManagerService.updateConsent(consented);
            break;

          case IntegrationTypes.AdobeTagManager:
            (
              await import(
                '@/front/data/IntegrationService/_AdobeTagManagerService'
              )
            ).AdobeTagManagerService.updateConsent(consented);
            break;

          default:
            break;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  /**
   * Static wrapper for update consent that instantiates a copy of the integration service.
   * Todo: obsolete using an integration context and a shared service
   */
  public static staticUpdateConsentAsync(consented: boolean, site?: Site) {
    return new IntegrationService({
      integrations: IntegrationService.buildIntegrationConfig(site)
    }).updateConsentAsync(consented);
  }
}
