import isNil from 'lodash/isNil';
import { FC, ReactNode } from 'react';

import { Box, BoxProps } from '../Box';
import { FeedbackContent } from '../Feedback';
import { Group } from '../Group';
import { ArrowLeftIcon } from '../Icons/ArrowLeftIcon';
import { Stack } from '../Stack';
import { Text } from '../Text';
import { Title } from '../Title';
import classes from './ModalHeader.module.css';
import { ModalHeaderClose } from './ModalHeaderClose';
import { useModalState } from './ModalStateContext';
import { ModalSizeVariantProps, ModalStyler } from './ModalStyles';

interface Props extends ModalSizeVariantProps {
  /**
   * The title of the modal.
   */
  title?: ReactNode;
  /**
   * The optional subtitle of the modal.
   */
  subTitle?: string;
  /**
   *
   * An optional icon that is shown before the title.
   */
  icon?: ReactNode;

  iconMr?: BoxProps['mr'];
  /**
   * Indicates if saving/saved feedback should be shown.
   */
  showFeedback?: boolean;
  /**
   * A optional message banner that is shown at the bottom of the header.
   */
  banner?: ReactNode;
  /**
   * The header is fixed to the top of the modal.
   */
  fixed?: boolean;
  /**
   * Indicates if the back icon should be shown.
   */
  showBackIcon?: boolean;
  /**
   * Handles close event on the modal.
   */
  preCloseContent?: ReactNode;
  onClose?: () => void;
  dataHelpId?: string;
  disabled?: boolean;
}

export const ModalHeader: FC<Props> = ({
  title,
  subTitle,
  icon,
  banner,
  fixed,
  showBackIcon = false,
  showFeedback,
  preCloseContent,
  onClose,
  dataHelpId,
  disabled,
  sizeVariant,
  iconMr = '0.5rem'
}) => {
  const { size } = useModalState();

  const showFeedbackLabel = isNil(showFeedback)
    ? size === 'FullScreen' || size === 'XLarge'
    : showFeedback;

  const handleClosed = () => {
    if (disabled) return;
    return onClose?.();
  };

  const titleOnly = !showFeedbackLabel && !preCloseContent && !onClose;
  const dto = ModalStyler.sizeData(sizeVariant);
  return (
    <>
      <Group gap={0} data-fixed={fixed} className={classes.header}>
        <Stack
          gap="xs"
          flex={'1 1 auto'}
          pr={titleOnly ? undefined : '0'}
          py={dto.paddingY}
          pl={dto.paddingXOutside}
        >
          <Title
            order={3}
            display={'flex'}
            style={{ alignItems: 'center' }}
            c={'var(--mantine-color-text)'}
            fz={'xl'}
            w="100%"
          >
            {showBackIcon && (
              <span
                style={{
                  flex: '0 0 auto',
                  cursor: 'pointer',
                  marginRight: '16px'
                }}
                onClick={handleClosed}
              >
                <ArrowLeftIcon size={'lg'} />
              </span>
            )}
            {icon ? (
              <Box mr={iconMr} className={classes.headerIcon}>
                {icon}
              </Box>
            ) : null}
            {title}
          </Title>
          {subTitle && (
            <Text c="dimmed" size="sm">
              {subTitle}
            </Text>
          )}
        </Stack>
        {showFeedbackLabel && <FeedbackContent />}
        {preCloseContent}
        {!!onClose && (
          <div data-help-id={dataHelpId}>
            <ModalHeaderClose disabled={disabled} onClick={onClose} />
          </div>
        )}
      </Group>
      {banner}
      {fixed && <Box h="4rem" />}
    </>
  );
};
