import { Drawer } from '@komo-tech/ui/Drawer';
import { useFlag } from '@komo-tech/ui/hooks/useFlag';
import { FC } from 'react';

import { FrontBadgeView } from '@/front/models/FrontBadgeView';

import { useFrontSite } from '../../../Store';
import { EditProfileContent } from './_EditProfileContent';
import { ProfileContent } from './_ProfileContent';
import { ProfileHeader } from './_ProfileDrawerHeader';

interface Props {
  open: boolean;
  onClose: () => void;
  badges: FrontBadgeView[];
}

export const ProfileDrawer: FC<Props> = ({ open, onClose, badges }) => {
  const showEditContent = useFlag(false);
  const properties = useFrontSite((x) => x.site.properties);

  const { drawerColor, textColor } = properties.getProfileDrawerModel();
  return (
    <Drawer
      __vars={{
        '--profile-drawer-c': textColor
      }}
      position="right"
      size="md"
      padding={'lg'}
      withCloseButton={!showEditContent.value}
      title={
        <ProfileHeader
          isEditTab={showEditContent.value}
          onClose={showEditContent.setFalse}
        />
      }
      opened={open}
      onClose={onClose}
      closeButtonProps={
        {
          size: 'lg',
          c: textColor,
          variant: 'transparent' as any
        } as any
      }
      styles={{
        header: {
          position: 'unset',
          backgroundColor: drawerColor
          // svg: { color: textColor }
        },
        content: {
          color: textColor,
          backgroundColor: drawerColor
        }
      }}
    >
      {!showEditContent.value && (
        <ProfileContent
          onEditProfile={showEditContent.setTrue}
          badges={badges}
        />
      )}
      {showEditContent.value && (
        <EditProfileContent
          onBack={showEditContent.setFalse}
          drawerBackgroundColor={drawerColor}
          drawerTextColor={textColor}
        />
      )}
    </Drawer>
  );
};
