import { createStore, useStore } from 'zustand';

export interface IIdleTimerStore {
  isIdle: boolean;
}

export const IdleTimerStore = createStore<IIdleTimerStore>(() => ({
  isIdle: false
}));

export function useIdleTimerStore<T = unknown>(
  selector: (state: IIdleTimerStore) => T
) {
  return useStore(IdleTimerStore, selector);
}
