import { createContext, useContext } from 'react';

import { BaseColor } from '../../theme/types';

interface ListContextProps {
  itemsHaveShadow?: boolean;
  itemsSelectable?: boolean;
  itemsTextColorVariant?: BaseColor | string;
  itemsHaveBackgroundColor?: boolean;
  itemsBorder?: string;
  itemsPadding?: string;
}

export const ListContext = createContext<ListContextProps>({
  itemsHaveShadow: false,
  itemsSelectable: false
});

export const useListContext = () => useContext(ListContext);
