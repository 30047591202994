import { IANA_TIMEZONES } from '@komo-tech/core/data/timeZones';
import { OptionModel } from '@komo-tech/core/models/OptionModel';
import { ForwardedRef, forwardRef } from 'react';

import { useUncontrolled } from '../../../hooks/useUncontrolled';
import { GlobeIcon } from '../../Icons/GlobeIcon';
import { LocalTime } from '../../LocalTime';
import { FormInputDescription } from '../InputDescription';
import { FormSelect, FormSelectProps } from '../Select';

export interface FormTimeZonePickerProps
  extends Omit<FormSelectProps, 'data' | 'searchable'> {
  withLocalTimeInfo?: boolean;
  withIcon?: boolean;
}

export const FormTimeZonePicker = forwardRef<
  HTMLInputElement,
  FormTimeZonePickerProps
>(
  (
    {
      defaultValue,
      value: valueProp,
      onChange,
      clearable = false,
      inputContainer: inputContainerProp,
      withLocalTimeInfo,
      leftSection,
      withIcon = true,
      ...rest
    },
    forwardedRef: ForwardedRef<HTMLInputElement>
  ) => {
    const [value, handleChange] = useUncontrolled<string, OptionModel>({
      value: valueProp,
      defaultValue,
      finalValue: '',
      onChange: (x) => onChange?.(x?.value, x)
    });

    const hasValue = IANA_TIMEZONES.some((x) => x.value === value);

    const inputContainer = resolveInputContainer({
      inputContainer: inputContainerProp,
      value: hasValue ? value : undefined,
      withLocalTimeInfo
    });

    return (
      <FormSelect
        searchable
        ref={forwardedRef}
        clearable={clearable}
        data={IANA_TIMEZONES}
        onChange={(_, x) => handleChange(x)}
        value={value}
        inputContainer={inputContainer}
        leftSection={leftSection || (withIcon ? <GlobeIcon /> : undefined)}
        {...rest}
      />
    );
  }
);

const resolveInputContainer = ({
  inputContainer,
  withLocalTimeInfo,
  value
}: Pick<
  FormTimeZonePickerProps,
  'inputContainer' | 'withLocalTimeInfo' | 'value'
>): FormTimeZonePickerProps['inputContainer'] => {
  if (!value || !withLocalTimeInfo) {
    return inputContainer;
  }

  const hasInputContainer = typeof inputContainer === 'function';

  return (input) => {
    return (
      <>
        {hasInputContainer ? inputContainer(input) : input}
        <FormInputDescription mt="0.2rem">
          <LocalTime timeZone={value} variant="unstyled" />
        </FormInputDescription>
      </>
    );
  };
};
