import cx from 'clsx';
import { forwardRef, ReactNode } from 'react';

import { UiSize } from '../../theme/types';
import { useTheme } from '../../theme/useTheme';
import { ActionButton } from '../ActionButton';
import { Button, ButtonProps } from '../Button';
import { Group } from '../Group';
import { MoreVerticalIcon } from '../Icons/MoreVerticalIcon';
import { Popover } from '../Popover';
import classes from './SplitButton.module.css';

export interface PopoverSplitButtonProps
  extends Omit<ButtonProps, 'component' | 'size'> {
  size?: UiSize;
  popoverContent: ReactNode;
}

export const PopoverSplitButton = forwardRef<
  HTMLButtonElement,
  PopoverSplitButtonProps
>(
  (
    {
      color,
      children,
      variant = 'filled',
      disabled,
      size = 'sm',
      classNames = {},
      popoverContent,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    const buttonSize = getIconSizeFromButtonSize(size);
    return (
      <Group align="center" gap={0}>
        <Button
          ref={ref}
          size={size}
          color={color}
          variant={variant}
          disabled={disabled}
          classNames={{
            ...classNames,
            root: cx(classes.root, (classNames as unknown as any)?.root)
          }}
          {...rest}
        >
          {children}
        </Button>
        <Popover
          transitionProps={{ transition: 'pop-top-left' }}
          disabled={disabled}
          withinPortal
          zIndex={1100}
          target={({ opened, setOpened }) => (
            <ActionButton
              px={8}
              variant="default"
              onClick={() => setOpened(!opened)}
              color={color || theme.primaryColor}
              size={buttonSize}
              disabled={disabled}
              className={classes.control}
            >
              <MoreVerticalIcon
                size={buttonSize <= 36 ? '0.8rem' : undefined}
              />
            </ActionButton>
          )}
          position="bottom-start"
          shadow="md"
        >
          {popoverContent}
        </Popover>
      </Group>
    );
  }
);

const getIconSizeFromButtonSize = (size: UiSize) => {
  switch (size) {
    case 'xs':
      return 30;

    case 'sm':
      return 36;

    case 'md':
      return 42;

    case 'lg':
      return 50;

    case 'xl':
      return 60;
  }
};
