import {
  BoxProps as MantineBoxProps,
  Factory as MantineFactory,
  factory as mantineFactory
} from '@mantine/core';
import { useRef } from 'react';
import ReactModal from 'react-modal';

import { useEvent } from '../../hooks/useEvent';
import { FeedbackProvider } from '../Feedback';
import { ModalContainer } from './ModalContainer';
import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalSidebarLayout } from './ModalSidebar';
import { ModalSizes, ModalSizesEnum } from './ModalSizes';
import { ModalStateProvider } from './ModalStateProvider';

export interface ModalContainerStyles extends MantineBoxProps {
  backgroundColor?: string;
}

export interface ModalProps extends Omit<ReactModal.Props, 'isOpen'> {
  size?: ModalSizes;
  modalStyles?: ModalContainerStyles;
  overlayStyles?: { backgroundColor?: string };
  isOpen?: boolean;
  dataHelpId?: string;
  circleWipeProps?: {
    enabled: boolean;
    color?: string;
  };
}

type ModalFactory = MantineFactory<{
  props: ModalProps;
  staticComponents: {
    Header: typeof ModalHeader;
    Content: typeof ModalContent;
    Footer: typeof ModalFooter;
    SidebarLayout: typeof ModalSidebarLayout;
  };
}>;

export const Modal = mantineFactory<ModalFactory>(
  (
    {
      isOpen = true,
      size,
      modalStyles,
      overlayStyles,
      children,
      dataHelpId,
      circleWipeProps,
      ...rest
    }: ModalProps,
    _
  ) => {
    const overlayRef = useRef<HTMLDivElement>();
    const contentRef = useRef<HTMLDivElement>();

    const registerContent = useEvent((e: HTMLDivElement) => {
      if (!contentRef.current) {
        contentRef.current = e;
      }
    });

    const registerOverlay = useEvent((e: HTMLDivElement) => {
      if (!overlayRef.current) {
        overlayRef.current = e;
      }
    });

    return (
      <ReactModal
        overlayRef={registerOverlay}
        contentRef={registerContent}
        style={{
          content: {
            position: 'relative',
            inset: 'initial',
            background: 'none',
            outline: 'none',
            border: 0,
            borderRadius: 0,
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex: 1101,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            padding: size !== ModalSizesEnum.FullScreen ? '0 0.5rem' : '0'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            zIndex: 1100,
            ...overlayStyles
          }
        }}
        isOpen={isOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        {...rest}
      >
        <ModalStateProvider
          contentRef={contentRef}
          overlayRef={overlayRef}
          size={size}
        >
          <FeedbackProvider>
            <ModalContainer
              modalSize={size}
              circleWipeProps={circleWipeProps}
              {...modalStyles}
              dataHelpId={dataHelpId}
            >
              {children}
            </ModalContainer>
          </FeedbackProvider>
        </ModalStateProvider>
      </ReactModal>
    );
  }
);

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;
Modal.SidebarLayout = ModalSidebarLayout;
