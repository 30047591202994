import { FC, MouseEvent, ReactNode } from 'react';

import { ActionButton, ActionButtonProps } from '../ActionButton';
import { DeleteIcon } from '../Icons/DeleteIcon';
import { List, ListItemStyle, ListProps } from './List';
import { ListItem } from './ListItem';
import { ListItemAction } from './ListItemAction';
import { ListItemIcon } from './ListItemIcon';
import { ListItemText } from './ListItemText';

export interface BaseListRendererItemProps {
  id: string;
  icon?: any;
  text?: string;
  content?: ReactNode;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (e: MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLElement>) => void;
  active?: boolean;
}

export interface ListRendererItemProps extends BaseListRendererItemProps {
  action?: {
    onClick?: (item: ListRendererItemProps, e: MouseEvent<HTMLElement>) => void;
    variant?: ActionButtonProps['variant'];
    color?: ActionButtonProps['color'];
    tooltip?: ActionButtonProps['tooltip'];
    content: ReactNode;
  };
}

export interface ListRendererProps extends ListProps {
  itemStyles?: ListItemStyle;
  items: ListRendererItemProps[];
}

export const ListRenderer: FC<ListRendererProps> = ({
  items,
  itemStyles,
  ...rest
}) => {
  return (
    <List itemStyles={itemStyles} {...rest}>
      {items.map((item) => (
        <ListItem
          selectable={item.onClick ? true : false}
          onMouseEnter={item.onMouseEnter}
          onMouseLeave={item.onMouseLeave}
          onClick={item.onClick}
          key={item.id}
          active={item.active}
        >
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          {item.text && <ListItemText>{item.text}</ListItemText>}
          {item.action && (
            <ListItemAction>
              <ActionButton
                variant={item.action.variant}
                color={item.action.color}
                tooltip={item.action.tooltip}
                onClick={(e) => item.action.onClick(item, e)}
              >
                {item.action.content}
              </ActionButton>
            </ListItemAction>
          )}
          {item.content}
        </ListItem>
      ))}
    </List>
  );
};

export interface ListDeleteRendererProps {
  itemStyles?: ListItemStyle;
  items: BaseListRendererItemProps[];
  onDelete: (item: BaseListRendererItemProps) => void;
  deleteButtonColor?: ActionButtonProps['color'];
  deleteButtonVariant?: ActionButtonProps['variant'];
  deleteButtonTooltip?: ActionButtonProps['tooltip'];
}

export const ListDeleteRenderer: FC<ListDeleteRendererProps> = ({
  items,
  onDelete,
  deleteButtonVariant = 'light',
  deleteButtonColor = 'red',
  deleteButtonTooltip = { label: 'Delete' },
  ...rest
}) => {
  return (
    <ListRenderer
      {...rest}
      items={items.map<ListRendererItemProps>((i) => ({
        ...i,
        action: {
          onClick: onDelete,
          variant: deleteButtonVariant,
          color: deleteButtonColor,
          tooltip: deleteButtonTooltip,
          content: <DeleteIcon />
        }
      }))}
    />
  );
};
