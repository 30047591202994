import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray, tryAddToArray } from '@komo-tech/core/utils/array';
import { nanoid } from '@komo-tech/core/utils/nanoid';
import {
  createContext,
  useContextSelector
} from '@komo-tech/ui/hooks/useContextSelector';
import { HubConnection } from '@microsoft/signalr';
import { FCC } from 'fcc';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';

import { GroupName, HubConnectionMethodHandler } from './types';
import { useConnectionGroups } from './useConnectionGroups';
import { useConnectionHandlers } from './useConnectionHandlers';
import { useHubConnection } from './useHubConnection';

type CleanupFunction = () => void;

interface SignalRContextState {
  connection?: HubConnection;
  subscribe: (
    methodName: string,
    handler: (...args: any[]) => void
  ) => CleanupFunction;
  reconnectedKey: number;
  registerUserId: (userId: Guid) => void;
  unregisterUserId: (userId: Guid) => void;
}

const SignalRContext = createContext<SignalRContextState | undefined>(
  undefined
);

interface Props {
  signalRHubName: string;
  signalRGroupNames: GroupName[];
  disabled: boolean;
  onSetUserIds: Dispatch<SetStateAction<Guid[]>>;
}

export const SignalRProvider: FCC<Props> = ({
  signalRHubName,
  signalRGroupNames,
  disabled,
  onSetUserIds,
  children
}) => {
  const [initConnection, setInitConnection] = useState(false);
  const [reconnectedKey, setReconnectedKey] = useState(0);
  const connection = useHubConnection({
    url: signalRHubName,
    init: initConnection,
    onReconnected: () => setReconnectedKey((k) => k + 1)
  });
  const [handlers, setHandlers] = useState<HubConnectionMethodHandler[]>([]);

  useConnectionGroups({
    groupNames: signalRGroupNames,
    connection,
    refreshKey: reconnectedKey
  });

  useConnectionHandlers({ handlers, connection });

  // lazy-init the connection in case we don't need any signalR functionality
  useEffect(() => {
    if (initConnection || disabled) {
      return;
    }

    if (handlers.length > 0) {
      setInitConnection(true);
    }
  }, [handlers, disabled]);

  const unsubscribe = (id: string) => {
    setHandlers((p) => p.filter((i) => i.id !== id));
  };

  const subscribe = (methodName: string, handler: (...args: any[]) => void) => {
    const id = nanoid();
    setHandlers((p) => [...p, { id, methodName, handler }]);
    return () => unsubscribe(id);
  };

  const value = useMemo<SignalRContextState>(() => {
    return {
      connection,
      subscribe,
      registerUserId: (id) => {
        onSetUserIds((p) => {
          const newIds = mapArray(p, (x) => new Guid(x));
          tryAddToArray(newIds, id, (x) => id.equals(x));
          return newIds;
        });
      },
      unregisterUserId: (id) => {
        onSetUserIds((p) => p.filter((x) => !id.equals(x)));
      },
      reconnectedKey
    };
  }, [connection, reconnectedKey]);
  return (
    <SignalRContext.Provider value={value}>{children}</SignalRContext.Provider>
  );
};

export function useSignalRContextSelector<T>(
  selector: (value: SignalRContextState) => T
): T {
  return useContextSelector(SignalRContext, (state) => {
    if (state === undefined) {
      throw new Error(
        'useSignalRContextSelector must be used within a SignalRProvider'
      );
    }
    return selector(state);
  });
}

/**
 * @deprecated Prefer using the strongly typed methods in useSiteHubSubscribe
 * @see useSiteHubSubscribe
 */
export const useSignalRContextSubscribe = () =>
  useSignalRContextSelector((s) => s.subscribe);

/**
 * Get the reconnected key which is incremented every time SignalR reconnects.
 */
export const useSignalRContextReconnectedKey = () =>
  useSignalRContextSelector((s) => s.reconnectedKey);

export const useSignalRUserRegister = (userId: Guid) => {
  const register = useSignalRContextSelector((x) => x.registerUserId);
  const unRegister = useSignalRContextSelector((x) => x.unregisterUserId);
  const userIdString = userId.toString();

  useEffect(() => {
    register(userId);
    return () => {
      unRegister(userId);
    };
  }, [userIdString]);
};
