import { FieldValues, useController, useFormContext } from 'react-hook-form';

import { FormHiddenField, FormHiddenFieldProps } from './FormHiddenField';

interface Props<TFieldValues extends FieldValues = FieldValues>
  extends Omit<FormHiddenFieldProps<TFieldValues>, 'value'> {}

export function FormHookHiddenField<
  TFieldValues extends FieldValues = FieldValues
>(props: Props<TFieldValues>) {
  const { name, ...rest } = props;
  const { control } = useFormContext<TFieldValues>();
  const {
    field: { value }
  } = useController<TFieldValues>({
    name: name as any,
    control: control
  });

  return <FormHiddenField name={name} value={value} {...rest} />;
}
