import {
  Popover as MantinePopover,
  PopoverDropdownProps as MantinePopoverDropdownProps,
  PopoverProps as MantinePopoverProps
} from '@mantine/core';
import isNil from 'lodash/isNil';

import { FCC } from '../../fcc';
import { useEvent } from '../../hooks/useEvent';
import { useUncontrolled } from '../../hooks/useUncontrolled';

/**
 * @see https://mantine.dev/core/popover/
 */
export interface PopoverTargetProps {
  setOpened: (value: boolean) => void;
  opened: boolean;
}

export interface PopoverProps extends MantinePopoverProps {
  /**Target must have a forward ref */
  target: (props: PopoverTargetProps) => React.ReactNode;
  dropdownProps?: MantinePopoverDropdownProps;
}

export const Popover: FCC<PopoverProps> = ({
  children,
  target,
  defaultOpened,
  opened: openedProp,
  onChange,
  dropdownProps,
  onClose,
  onOpen,
  ...rest
}) => {
  const [opened, setOpened] = useUncontrolled({
    defaultValue: defaultOpened,
    value: openedProp,
    resolveValue: (v) => v,
    onChange: onChange
  });

  if (isNil(rest.withinPortal)) {
    rest.withinPortal = true;
  }

  if (rest.withinPortal && isNil(rest.zIndex)) {
    //fix for showing popovers above modals
    rest.zIndex = 1100;
  }

  const handleOpen = useEvent(onOpen);
  const handleClose = useEvent(onClose);

  return (
    <MantinePopover
      opened={opened}
      onChange={setOpened}
      onOpen={handleOpen}
      onClose={handleClose}
      {...rest}
    >
      <MantinePopover.Target>
        {target({
          opened,
          setOpened
        })}
      </MantinePopover.Target>
      <MantinePopover.Dropdown {...(dropdownProps || {})}>
        {children}
      </MantinePopover.Dropdown>
    </MantinePopover>
  );
};
