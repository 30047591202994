/* Constant equivalents to the css vars in app.css */
export const ThemeConstants = {
  /* Surface */
  KomoCardShellBoxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.25)',
  KomoCardShellBg: '#FFFFFF',
  KomoCardShellC: '#000000',
  KomoCardShellBd: '#DEE2E6',

  /* Nav */
  KomoNavC: '#FFFFFF',
  KomoNavBg: '#000000',
  KomoNavActiveC: '#f1e52c'
};
