import { BrowserMultiFormatReader, Result } from '@zxing/library';
import { useEffect } from 'react';

export type QrReadResult = Result;

export type QrReadData = {
  /**
   * The QR values extracted by Zxing
   */
  result?: QrReadResult | undefined | null;

  /**
   * The QR text extracted by Zxing
   */
  resultText?: string | undefined | null;

  /**
   * The name of the exceptions thrown while reading the QR
   */
  error?: Error | undefined | null;
  /**
   * The instance of the QR browser reader
   */
  reader?: BrowserMultiFormatReader;
};
export type useQrReaderOptions = {
  /**
   * Media constraints object, to specify which camera and capabilities to use
   */
  constraints?: MediaTrackConstraints;
  /**
   * Callback for retrieving the result
   */
  onResultAsync?: (data: QrReadData) => Promise<unknown>;
  /**
   * Property that represents the scan period
   */
  scanDelay?: number;
  /**
   * Property that represents the ID of the video element
   */
  videoId: string;

  debug?: boolean;
};

export const useQrReader = ({
  scanDelay,
  constraints: video,
  onResultAsync,
  debug = false,
  videoId
}: useQrReaderOptions) => {
  useEffect(() => {
    const reader = new BrowserMultiFormatReader(null, scanDelay);

    if (!isMediaDevicesSupported()) {
      const message =
        'MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"';

      onResultAsync?.({
        result: null,
        resultText: null,
        error: new Error(message),
        reader: reader
      });
    }

    reader
      .decodeFromConstraints({ video }, videoId, (result, error) => {
        const data: QrReadData = {
          result,
          resultText: result?.getText?.(),
          error,
          reader
        };

        if (debug) {
          console.debug('QR Read', data);
        }

        return !onResultAsync ? Promise.resolve() : onResultAsync(data);
      })
      .catch((error: Error) => {
        if (debug) {
          console.debug('QR Read', { error, reader });
        }
        onResultAsync?.({
          result: null,
          resultText: null,
          error,
          reader
        });
      });

    return () => {
      console.debug('Resetting QR reader');
      reader.reset();
    };
  }, []);
};

const isMediaDevicesSupported = () => {
  const isMediaDevicesSupported =
    typeof navigator !== 'undefined' && !!navigator.mediaDevices;

  if (!isMediaDevicesSupported) {
    console.warn(
      `[ReactQrReader]: MediaDevices API has no support for your browser. You can fix this by running "npm i webrtc-adapter"`
    );
  }

  return isMediaDevicesSupported;
};
