import { Guid } from '@komo-tech/core/models/Guid';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';

import { CardStatuses } from '@/common/models/CardStatuses';
import { CardSubTypes } from '@/common/models/CardSubTypes';
import { CardTypes } from '@/common/models/CardTypes';

export class PageCard {
  id: Guid;
  type: CardTypes;
  subType: CardSubTypes;
  status: CardStatuses;
  coverImage: ImageDataModel;
  colors: PageCardColors;

  constructor(props?: Partial<PageCard>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.coverImage = ImageDataModel.fromJsonOrUrl(props.coverImage);
    this.colors = { ...props.colors };
  }
}

export interface PageCardColors {
  shellBg?: string;
  shellC?: string;
  shellBd?: string;
}
