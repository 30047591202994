import { IAmFormHookField } from '@komo-tech/ui/Form/HookField';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { FieldValues } from 'react-hook-form';

import { FormImageEditorV2, FormImageEditorV2Props } from './FormImageEditorV2';

export interface FormHookImageEditorV2Props<
  TFieldValues extends FieldValues = any
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormImageEditorV2Props,
      'required' | 'name' | 'value' | 'error' | 'disabled' | 'onChange'
    > {
  onChange?: FormImageEditorV2Props['onChange'];
}

export function FormHookImageEditorV2<TFieldValues extends FieldValues = any>(
  props: FormHookImageEditorV2Props<TFieldValues>
) {
  const { hook, onChange, ...rest } = props;

  const handleChange = useEvent<FormHookImageEditorV2Props['onChange']>((v) => {
    hook.setFieldValue(v as any, {
      shouldValidate: true,
      shouldDirty: true
    });
    setTimeout(() => {}, 10);
    onChange?.(v);
  });

  return (
    <FormImageEditorV2
      {...rest}
      required={hook.required}
      disabled={hook.disabled}
      value={hook.field.value}
      error={hook.showError ? hook.errorMessage : undefined}
      onChange={handleChange}
    />
  );
}
