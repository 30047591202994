'use client';
import {
  Accordion as MantineAccordion,
  AccordionControlProps as MantineAccordionControlProps,
  AccordionProps as MantineAccordionProps
} from '@mantine/core';

/**
 * @see https://mantine.dev/core/accordion/
 */
export const Accordion = MantineAccordion;
export type AccordionProps = MantineAccordionProps;
export type AccordionControlProps = MantineAccordionControlProps;
