import cx from 'clsx';
import { CSSProperties, ReactNode } from 'react';

import { useConstant } from '../../../hooks/useConstant';
import { useId } from '../../../hooks/useId';
import { Box, BoxProps } from '../../Box';
import classes from './QrCodeReader.module.css';
import { useQrReader, useQrReaderOptions } from './useQrReader';

export interface QrCodeReaderVideoProps
  extends Omit<useQrReaderOptions, 'videoId'> {
  videoId?: string;
  containerProps?: BoxProps;
  /**
   * Property that represents a style for the video
   */
  videoStyle?: CSSProperties;
  withBracketsBorder?: boolean;
  bracketsBorderColor?: string;
  backgroundElement?: ReactNode;
}

export const QrCodeReaderVideo: React.FC<QrCodeReaderVideoProps> = ({
  videoStyle,
  constraints,
  scanDelay = 200,
  withBracketsBorder = true,
  containerProps,
  onResultAsync,
  bracketsBorderColor = 'var(--mantine-color-text)',
  videoId: videoIdProp,
  backgroundElement
}) => {
  const fallbackId = useId();
  const videoId = useConstant(() => videoIdProp ?? fallbackId);

  useQrReader({
    constraints,
    scanDelay,
    onResultAsync,
    videoId
  });

  const { className, ...containerRest } = containerProps || {};

  return (
    <Box
      __vars={{
        '--qr-border-c': bracketsBorderColor
      }}
      className={cx(className, classes.videoContainer)}
      {...containerRest}
    >
      <video
        muted
        playsInline
        autoPlay
        className={classes.video}
        data-with-brackets={withBracketsBorder}
        id={videoId}
        data-facing-mode={constraints?.facingMode ?? 'unknown'}
        style={videoStyle}
      />
      {backgroundElement && (
        <div className={classes.videoBackground}>{backgroundElement}</div>
      )}
    </Box>
  );
};
