import { FormHookSelect } from '@komo-tech/ui/Form/Select';
import { FieldValues } from 'react-hook-form';

import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  SelectDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormSelectField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => (
        <FormHookSelect
          hook={h}
          {...(field.data.options as SelectDynamicFieldOptions)}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
