import { Guid } from '@komo-tech/core/models/Guid';
import { HeightWidth } from '@komo-tech/core/models/HeightWidth';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { FrontCardErrored } from '@/common/components/Card/shared/Front/FrontCardErrored';
import { CardSkeleton } from '@/common/components/Card/shared/Skeletons';
import {
  LoadingCardDescriptorContext,
  LoadingContextCardSkeleton
} from '@/common/components/Card/shared/Skeletons/LoadingContextCardSkeleton';
import { useCardDescriptorSignalRUpdateSync } from '@/common/data/signal-r/SiteHub';
import { FrontAnyCard } from '@/common/models/AnyCard';
import { CardDescriptor } from '@/common/models/CardDescriptor';
import { CardTypes } from '@/common/models/CardTypes';
import { PublicCardsService } from '@/front/data/Cards';

import { useFrontSite } from './SiteHost/Store';

const SpinToWinCard = dynamic(
  () => import('./spin-to-win-card/SpinToWinCard'),
  {
    loading: () => <LoadingContextCardSkeleton />,
    ssr: false
  }
);

const QuizCard = dynamic(() => import('./quiz-card/QuizCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});
const FactCard = dynamic(() => import('./fact-card/FactCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});
const ImageCard = dynamic(() => import('./image-card/ImageCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});
const TriviaCard = dynamic(() => import('./trivia-card/TriviaCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});
const VideoCard = dynamic(() => import('./video-card/VideoCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});
const ChecklistCard = dynamic(() => import('./checklist-card/ChecklistCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});
const EmbedCard = dynamic(() => import('./embed-card/EmbedCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});
const NumberGuessCard = dynamic(
  () => import('./number-guess-card/NumberGuessCard'),
  { loading: () => <LoadingContextCardSkeleton />, ssr: false }
);

const CountdownClockCard = dynamic(
  () => import('./countdown-clock-card/CountdownClockCard'),
  { loading: () => <LoadingContextCardSkeleton />, ssr: false }
);

const LegacyTriviaLeaderboardCard = dynamic(
  () => import('./legacy-trivia-leaderboard-card/LegacyTriviaLeaderboardCard'),
  { loading: () => <LoadingContextCardSkeleton />, ssr: false }
);

const PredictiveCard = dynamic(
  () => import('./predictive-card/PredictiveCard'),
  { loading: () => <LoadingContextCardSkeleton />, ssr: false }
);

const CanvasCard = dynamic(() => import('./canvas-card/CanvasCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});

const ScratchAndWinCard = dynamic(
  () => import('./scratch-and-win-card/ScratchAndWinCard'),
  { loading: () => <LoadingContextCardSkeleton />, ssr: false }
);

const UploadCard = dynamic(() => import('./upload-card/UploadCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});

const CarouselCard = dynamic(() => import('./carousel-card/CarouselCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});

const InstantWinCard = dynamic(
  () => import('./instant-win-card/InstantWinCard'),
  { loading: () => <LoadingContextCardSkeleton />, ssr: false }
);

const PickerCard = dynamic(() => import('./picker-card/PickerCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});

const SwiperCard = dynamic(() => import('./swiper-card/SwiperCard'), {
  loading: () => <LoadingContextCardSkeleton />,
  ssr: false
});

const MemoryChallengeCard = dynamic(
  () => import('./memory-challenge-card/MemoryChallengeCard'),
  { loading: () => <LoadingContextCardSkeleton />, ssr: false }
);

const LiveSurveyCard = dynamic(
  () => import('./live-survey-card/LiveSurveyCard'),
  { loading: () => <LoadingContextCardSkeleton />, ssr: false }
);

const TreasureHuntCard = dynamic(
  () => import('./treasure-hunt-card/TreasureHuntCard'),
  {
    loading: () => <LoadingContextCardSkeleton />,
    ssr: false
  }
);

interface Props {
  cardId: Guid;
  cardType: CardTypes;
  imageAspectRatio?: HeightWidth;
  shellBg?: string;
  shellBd?: string;
}

export const FrontCardItem: FC<Props> = ({
  cardId,
  cardType,
  imageAspectRatio,
  shellBg,
  shellBd
}) => {
  const hasRealTimeUpdates = useFrontSite(
    (x) => x.site.properties.RealtimeContentUpdatesEnabled
  );

  const { cardDescriptor, typedCard, isLoading, hasError } =
    PublicCardsService.hooks.useViewModel(cardId, cardType);

  useCardDescriptorSignalRUpdateSync({
    cardId,
    disabled: cardType !== CardTypes.Predictive && !hasRealTimeUpdates,
    fetchOnReconnect: true
  });

  if (hasError && !isLoading) {
    return <FrontCardErrored />;
  }

  if (isLoading || !cardDescriptor || !typedCard) {
    return (
      <CardSkeleton
        type={cardType}
        imageAspectRatio={imageAspectRatio}
        backgroundColor={shellBg}
        borderColor={shellBd}
      />
    );
  }

  return (
    <LoadingCardDescriptorContext.Provider
      value={{ type: cardType, imageAspectRatio, shellBd, shellBg }}
    >
      <_Card cardDescriptor={cardDescriptor} typedCard={typedCard} />
    </LoadingCardDescriptorContext.Provider>
  );
};

interface InnerProps {
  cardDescriptor: CardDescriptor;
  typedCard: FrontAnyCard;
}
const _Card: FC<InnerProps> = ({ cardDescriptor, typedCard }) => {
  switch (cardDescriptor.type) {
    case CardTypes.Quiz:
      return (
        <QuizCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Fact:
      return (
        <FactCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Image:
      return (
        <ImageCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Trivia:
      return (
        <TriviaCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Video:
      return (
        <VideoCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Checklist:
      return (
        <ChecklistCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.NumberGuess:
      return (
        <NumberGuessCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Embed:
      return (
        <EmbedCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Countdown:
      return (
        <CountdownClockCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Leaderboard:
      return (
        <LegacyTriviaLeaderboardCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.Predictive:
      return (
        <PredictiveCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.Canvas:
      return (
        <CanvasCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.Upload:
      return (
        <UploadCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.ScratchAndWin:
      return (
        <ScratchAndWinCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.Carousel:
      return (
        <CarouselCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.InstantWin:
      return (
        <InstantWinCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.Picker:
      return (
        <PickerCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.Swiper:
      return (
        <SwiperCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );

    case CardTypes.MemoryChallenge:
      return (
        <MemoryChallengeCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.SpinToWin:
      return (
        <SpinToWinCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.LiveSurvey:
      return (
        <LiveSurveyCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    case CardTypes.TreasureHunt:
      return (
        <TreasureHuntCard
          key={cardDescriptor.idAsString}
          cardDescriptor={cardDescriptor}
          typedCard={typedCard}
        />
      );
    default:
      const unsupported: never = cardDescriptor.type;
      console.error('Unsupported type  ', unsupported);
      return (
        <div key={cardDescriptor.idAsString}>
          Card type &quot;{cardDescriptor.type}&quot; not implemented yet
        </div>
      );
  }
};
