import { FC } from 'react';

import { IconProps } from '../IconProps';
import { IconSvg } from '../IconSvg';

export const FacebookIcon: FC<IconProps> = ({ color, ...rest }) => {
  return (
    <IconSvg {...rest}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0a12 12 0 100 24 12 12 0 000-24zM8.33 3.13a9.6 9.6 0 115.13 18.36v-6.63h2.8l.44-2.84h-3.24v-1.55c0-1.18.39-2.22 1.5-2.22h1.76V5.77h-.02c-.32-.05-.97-.14-2.2-.14-2.6 0-4.12 1.38-4.12 4.5v1.9H7.71v2.83h2.67v6.6A9.6 9.6 0 018.33 3.13z"
          fill={color || 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={color || 'currentColor'} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconSvg>
  );
};
