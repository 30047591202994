import {
  AllCountryIso2Options,
  AllCountryNameOptions,
  CountryCodeOption
} from '@komo-tech/core/data/countries';
import { FC, ForwardedRef, forwardRef, lazy, Suspense, useMemo } from 'react';

import { FlagIconProps } from '../../Icons/FlagIcon/FlagIcon.types';
import { FormSelect, FormSelectProps } from '../Select/FormSelect';

const FlagIcon = lazy(() =>
  import('../../Icons/FlagIcon/FlagIcon').then((x) => ({
    default: x.FlagIcon
  }))
);

export interface FormCountrySelectProps
  extends Omit<
    FormSelectProps,
    | 'leftSection'
    | 'valueIcon'
    | 'itemComponent'
    | 'data'
    | 'searchable'
    | 'limit'
  > {
  dataVariant?: 'default' | 'iso2';
  withFlags?: boolean;
  allowedCountries?: string[];
}

export const FormCountrySelect = forwardRef<
  HTMLInputElement,
  FormCountrySelectProps
>(
  (
    { dataVariant = 'default', allowedCountries, withFlags = true, ...rest },
    forwardedRef: ForwardedRef<HTMLInputElement>
  ) => {
    const data = useMemo(() => {
      const countries =
        dataVariant === 'default'
          ? AllCountryNameOptions
          : AllCountryIso2Options;
      return allowedCountries?.length
        ? countries.filter((x) => allowedCountries.includes(x.data.iso2))
        : countries;
    }, [dataVariant, allowedCountries]);

    return (
      <FormSelect<CountryCodeOption>
        searchable
        limit={999999}
        ref={forwardedRef}
        data={data}
        valueIcon={
          withFlags
            ? (v) => <LazyFlagIcon size={24} code={v.data.iso2} />
            : undefined
        }
        itemComponent={
          withFlags
            ? {
                icon: (v) => <LazyFlagIcon code={v.data.iso2} />
              }
            : undefined
        }
        {...rest}
      />
    );
  }
);

const LazyFlagIcon: FC<FlagIconProps> = ({ size = 24, ...rest }) => {
  return (
    <Suspense fallback={null}>
      <FlagIcon size={size} {...rest} />
    </Suspense>
  );
};
