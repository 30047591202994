export interface ModalSizeVariantProps {
  sizeVariant?: ModalSizeVariant;
}

interface SizeDto {
  paddingY: string;

  /**
   * X Padding for outside edges.
   */
  paddingXOutside: string;
  /**
   * X Padding for inside edges
   */
  paddingXInside: string;
}

export type ModalSizeVariant = 'Small' | 'Medium';
export class ModalStyler {
  static sizeData(sizeVariant?: ModalSizeVariant | 'sm' | 'md'): SizeDto {
    switch (sizeVariant) {
      case 'Small':
      case 'sm':
        return {
          paddingY: '1rem',
          paddingXInside: '1rem',
          paddingXOutside: '1.5rem'
        };
      case 'Medium':
      case 'md':
      default:
        return {
          paddingY: '1.5rem',
          paddingXInside: '1.5rem',
          paddingXOutside: '2rem'
        };
    }
  }
}
