import emailSpellChecker from '@zootools/email-spell-checker';
import React, { useState } from 'react';

import { useEvent } from '../../../hooks/useEvent';
import { useUncontrolled } from '../../../hooks/useUncontrolled';
import { EmailIcon } from '../../Icons/EmailIcon';
import { FormTextInput, FormTextInputProps } from '../TextInput/FormTextInput';
import classes from './FormEmailInput.module.css';

export interface FormEmailInputProps
  extends Omit<
    FormTextInputProps,
    | 'inputMode'
    | 'autoComplete'
    | 'type'
    | 'inputContainer'
    | 'sanitiseValue'
    | 'icon'
  > {
  withIcon?: boolean;
  withSuggestions?: boolean;
}

const toLower = (value: string) => (value || '').toLowerCase();

export const FormEmailInput = React.forwardRef<
  HTMLInputElement,
  FormEmailInputProps
>(
  (
    {
      withIcon,
      leftSection,
      error,
      onBlur,
      value: valueProp,
      defaultValue,
      onChange,
      withSuggestions = true,
      readOnly,
      ...rest
    },
    ref
  ) => {
    const [suggestionLookup, setSuggestionLookup] = useState<
      Record<string, string>
    >({});

    const [value, handleChange] = useUncontrolled<
      string,
      { event: React.ChangeEvent<HTMLInputElement>; value: string }
    >({
      value: valueProp,
      defaultValue,
      finalValue: null,
      resolveValue: (x) => x.value,
      sanitiseValue: toLower,
      onChange: (x) => onChange?.(x.event, x.value)
    });

    const suggestion = suggestionLookup[value];
    const handleSuggestions = useEvent((value: string) => {
      const trimmedEmail = (value || '').trim();
      if (!trimmedEmail) {
        return;
      }

      if (!suggestionLookup[trimmedEmail]) {
        const suggestion = emailSpellChecker.run({
          email: trimmedEmail
        });
        if (suggestion) {
          setSuggestionLookup((l) => {
            const updated = { ...l };
            updated[trimmedEmail] = suggestion.full;
            return updated;
          });
        }
      }
    });

    return (
      <FormTextInput
        ref={ref}
        {...rest}
        error={error}
        value={value}
        sanitiseValue={toLower}
        onBlur={onBlur}
        onChange={(e, v) => {
          handleChange({ event: e, value: v });
          if (withSuggestions) {
            handleSuggestions(v);
          }
        }}
        inputContainer={(i) => (
          <>
            {i}
            {withSuggestions && !!suggestion && !error && (
              <div
                className={classes.emailSuggestionContainer}
                role="button"
                onClick={(e) => {
                  handleChange({ event: e as any, value: suggestion });
                  onBlur?.(e as any, suggestion);
                }}
              >
                <span className={classes.emailSuggestion}>Did you mean </span>
                <span
                  title={suggestion}
                  className={classes.emailSuggestion}
                  data-email-value
                >
                  {suggestion}?
                </span>
              </div>
            )}
          </>
        )}
        type="email"
        autoComplete={readOnly ? 'off' : 'email'}
        leftSection={(leftSection ?? withIcon) ? <EmailIcon /> : undefined}
        inputMode="email"
        readOnly={readOnly}
      />
    );
  }
);
