import { Guid } from '@komo-tech/core/models/Guid';
import { FormHookEmailInput } from '@komo-tech/ui/Form/EmailInput';
import { FormHook } from '@komo-tech/ui/Form/Hook';
import { FormHookField } from '@komo-tech/ui/Form/HookField';
import { FormHookPasswordInput } from '@komo-tech/ui/Form/PasswordInput';
import { emailValidator } from '@komo-tech/ui/Form/Validators';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { UiSize } from '@komo-tech/ui/theme/types';
import { useToaster } from '@komo-tech/ui/Toast/useToaster';
import { useMutation } from '@tanstack/react-query';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { SiteUser } from '@/common/models/SiteUser';
import { DataDogServiceHelper } from '@/common/utils/DataDogService';
import { PublicAuthService, UserSignInResult } from '@/front/data/Auth';

import { AuthButton, AuthButtonGroup, AuthButtonProps } from '../Shared';

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface Props {
  siteId: Guid;
  usePassword: boolean;
  initialValues?: Partial<FormValues>;
  emailDisabled?: boolean;
  submitButton: Partial<AuthButtonProps>;
  greetingMessage: string;
  onSuccess: (user: SiteUser) => void;
  onConfirmEmail: (email: string) => void;
  extraButtons?: AuthButtonProps[];
  email?: string;
  size?: UiSize;
  children?: ReactNode;
}

export const SignInForm: FC<Props> = ({
  siteId,
  emailDisabled,
  usePassword,
  initialValues = {},
  submitButton,
  onSuccess,
  size,
  onConfirmEmail,
  extraButtons,
  greetingMessage,
  email = '',
  children
}) => {
  const formRef = useRef<UseFormReturn<FormValues>>();

  const [error, setError] = useState<string>(undefined);
  const toaster = useToaster();

  const { mutate: handleSignInAsync, isLoading: signInLoading } = useMutation(
    async (values: FormValues) =>
      PublicAuthService.actions.signInAsync({
        siteId: siteId.toString(),
        email: values.email,
        password: values.password
      }),
    {
      onSuccess: ({ success, errorMessage, data: user, result }) => {
        if (!success) {
          if (result === UserSignInResult.EmailError) {
            setError(errorMessage || 'Incorrect email or password');
          }
          return;
        }
        toaster.success(user.getGreetingMessage(greetingMessage));
        DataDogServiceHelper.trySetUserInformation(user);
        onSuccess(user);
      },
      onError: (err) => {
        console.error(err);
        toaster.error('An error occurred signing in, please try again');
      }
    }
  );

  const { mutate: handleConfirmEmailAsync, isLoading: confirmEmailLoading } =
    useMutation(
      async (values: FormValues) => {
        await PublicAuthService.actions.signInConfirmEmailAsync({
          siteId: siteId.toString(),
          email: values.email
        });
        return values.email;
      },
      {
        onSuccess: onConfirmEmail,
        onError: (err) => {
          console.error(err);
          toaster.error('An error occurred signing in, please try again');
        }
      }
    );

  const isLoading = signInLoading || confirmEmailLoading;

  useEffect(() => {
    if (error) {
      formRef.current.trigger(emailDisabled ? 'password' : 'email', {
        shouldFocus: true
      });
    }
  }, [error, emailDisabled]);

  //Clear the email error if any
  const handleInputChange = useEvent(() => {
    if (error) {
      setError(undefined);
    }
  });

  return (
    <FormHook<FormValues>
      defaultValues={{
        email,
        password: '',
        rememberMe: true,
        ...initialValues
      }}
      onInit={(f) => (formRef.current = f)}
      onSubmit={usePassword ? handleSignInAsync : handleConfirmEmailAsync}
    >
      <FormHookField<FormValues>
        name="email"
        required={{ enabled: true }}
        disabled={emailDisabled || isLoading}
        contextData={error}
        validators={[
          emailValidator(),
          () => (!emailDisabled ? error : undefined)
        ]}
      >
        {(h) => (
          <FormHookEmailInput
            hook={h}
            withIcon
            size={size}
            label="Email"
            onChange={handleInputChange}
          />
        )}
      </FormHookField>
      {usePassword && (
        <FormHookField<FormValues>
          name="password"
          disabled={isLoading}
          required={{ enabled: true }}
          contextData={error}
          validators={[() => (emailDisabled ? error : undefined)]}
        >
          {(h) => (
            <FormHookPasswordInput
              hook={h}
              withIcon
              size={size}
              label="Password"
              autoComplete="current-password"
              onChange={handleInputChange}
            />
          )}
        </FormHookField>
      )}
      <AuthButton
        {...(submitButton as AuthButtonProps)}
        busy={isLoading}
        type="submit"
        label="Sign in"
        size={size}
      />
      <AuthButtonGroup
        buttons={extraButtons}
        disabled={isLoading}
        size={size}
      />
      {children}
    </FormHook>
  );
};
