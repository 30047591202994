import isNil from 'lodash/isNil';
import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';

import { useForkRef } from '../../../hooks/useForkRef';
import { IAmFormHookField } from '../HookField';
import {
  FormValueListEditor,
  FormValueListEditorProps
} from './FormValueListEditor';

export interface FormHookValueListEditorProps<
  TFieldValues extends FieldValues = any
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormValueListEditorProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {
  onInit?: (element: HTMLInputElement) => void;
}

export function FormHookValueListEditor<TFieldValues extends FieldValues = any>(
  props: FormHookValueListEditorProps<TFieldValues>
) {
  const { hook, onChange, onInit, ...rest } = props;

  const handleInit = useCallback((element: HTMLInputElement) => {
    onInit?.(element);
  }, []);

  const ref = useForkRef(handleInit, hook.field.ref);
  return (
    <FormValueListEditor
      {...rest}
      ref={ref}
      required={hook.required}
      disabled={hook.disabled}
      onChange={(v) => {
        hook.field.onChange(v);
        onChange?.(v);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : []}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
