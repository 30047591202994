import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { InitialsIcon } from '@komo-tech/ui/Icons/InitialsIcon';
import { UserIcon } from '@komo-tech/ui/Icons/UserIcon';
import { FC } from 'react';

import { SiteAuthState } from '@/common/components/Card/shared/Front/Routing/AuthSiteUrlFunctions';
import { Site } from '@/common/models/site/Site';
import { SiteUser } from '@/common/models/SiteUser';

interface Props {
  site: Site;
  disabled?: boolean;
  onStateChange: (state: SiteAuthState) => void;
  user: SiteUser;
}

export const HeaderAuthButton: FC<Props> = ({
  user,
  onStateChange,
  site,
  ...rest
}) => {
  const loggedIn = !!user.profileId;
  const initials = user.tryGetInitials();
  const hasInitials = !!initials;

  const { AuthSsoOAuthEnabled, AuthEnabled } = site.properties;

  const handleClick = useEvent(() => {
    onStateChange(
      loggedIn
        ? SiteAuthState.Profile
        : AuthEnabled || AuthSsoOAuthEnabled
          ? SiteAuthState.SignIn
          : SiteAuthState.Verify
    );
  });

  if (!loggedIn && user.hasVerifiedEmailV2) {
    return <></>;
  }

  return (
    <InitialsIcon
      c="var(--komo-nav-c)"
      bd="1px solid var(--komo-nav-c)"
      bg="var(--komo-nav-bg)"
      __vars={{
        '--avatar-color': 'var(--komo-nav-c)'
      }}
      onClick={handleClick}
      rounded
      {...rest}
    >
      {hasInitials && loggedIn ? initials : <UserIcon c="var(--komo-nav-c)" />}
    </InitialsIcon>
  );
};
