'use client';
import type { AvatarProps as MantineAvatarProps } from '@mantine/core';
import {
  Avatar as MantineAvatar,
  AvatarFactory as MantineAvatarFactory,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';

export type AvatarProps = MantineAvatarProps;
type AvatarReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantineAvatarFactory>
>;

/**
 * @see https://mantine.dev/core/avatar/
 */
export const Avatar = MantineAvatar as AvatarReturnType;
