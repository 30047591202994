import { FloatingPosition } from '@mantine/core';
import {
  CSSProperties,
  forwardRef,
  LabelHTMLAttributes,
  MutableRefObject,
  ReactNode
} from 'react';

import { InfoDecorator } from '../InfoDecorator';
import { Text } from '../Text';
import classes from './Label.module.css';
import { LabelSizeVariant, LabelStyler } from './LabelStyler';

export interface LabelInfoTooltipProps {
  title: ReactNode;
  placement?: FloatingPosition;
}

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  required?: boolean;
  ref?: any;
  dataHelpId?: string;
  infoTooltip?: LabelInfoTooltipProps;
  disabled?: boolean;
  cursor?: string;
  sizeVariant?: LabelSizeVariant;
  style?: CSSProperties;
  mb?: number | string;
}

/**
 * @deprecated use FormInputLabel instead
 */
export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  (props, ref: MutableRefObject<HTMLLabelElement>) => {
    const {
      infoTooltip,
      children,
      dataHelpId,
      required,
      style,
      sizeVariant,
      cursor,
      mb = LabelStyler.marginBottom,
      ...rest
    } = props;

    return (
      <InfoDecorator
        label={infoTooltip?.title}
        position={infoTooltip?.placement}
      >
        <label
          className={classes.root}
          ref={ref}
          data-help-id={dataHelpId}
          style={{
            fontSize: LabelStyler.fontSize(sizeVariant),
            marginBottom: mb,
            cursor,
            ...style
          }}
          {...rest}
        >
          {children}
          {required && (
            <Text span c="red.7">
              &nbsp;*
            </Text>
          )}
        </label>
      </InfoDecorator>
    );
  }
);
