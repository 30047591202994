import { UrlBuilder } from '@komo-tech/core/utils/UrlBuilder';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { SiteAuthState } from '@/common/components/Card/shared/Front/Routing/AuthSiteUrlFunctions';
import { usePreviousNextQueryParams } from '@/common/hooks/usePreviousNextQueryParams';

export const SiteAuthStateParameterName = 'auth';
export const SiteAuthReturnUrlParameterName = 'return';
export const SiteAuthEmailParameterName = 'email';

interface Props {
  onStateChange: (
    state: SiteAuthState,
    params: { code?: string; returnUrl?: string; email?: string }
  ) => void;
}

interface Params {
  [SiteAuthStateParameterName]?: string;
  [SiteAuthReturnUrlParameterName]?: string;
  [SiteAuthEmailParameterName]?: string;
}

export const useSiteAuthRouting = ({ onStateChange }: Props) => {
  const nextRoute = useRouter();
  const { push, replace } = nextRoute;
  const queryParamHistory = usePreviousNextQueryParams();

  const resolveAuthUrlParamsForSiteIndex = (state: SiteAuthState) => {
    const builder = new UrlBuilder()
      .withBaseUrlFromNext(nextRoute)
      .withCurrentQueryParamsFromUrl();

    if (!state || state === SiteAuthState.None) {
      builder.withoutQueryKeys([
        SiteAuthStateParameterName,
        SiteAuthEmailParameterName,
        SiteAuthReturnUrlParameterName
      ]);
      return builder.build();
    }

    return builder.withQuery(SiteAuthStateParameterName, state).build();
  };

  const router = {
    push: (state: SiteAuthState) =>
      push(resolveAuthUrlParamsForSiteIndex(state), undefined, {
        scroll: false,
        shallow: true
      }),
    replace: (state: SiteAuthState) =>
      replace(resolveAuthUrlParamsForSiteIndex(state), undefined, {
        scroll: false,
        shallow: true
      })
  };

  useEffect(() => {
    if (queryParamHistory.length) {
      const state = queryParamHistory[0][SiteAuthStateParameterName] as string;
      const returnUrl = queryParamHistory[0][
        SiteAuthReturnUrlParameterName
      ] as string;
      const email = queryParamHistory[0][SiteAuthEmailParameterName] as string;

      const stateOld = queryParamHistory[1]?.[
        SiteAuthStateParameterName
      ] as string;
      const returnUrlOld = queryParamHistory[1]?.[
        SiteAuthReturnUrlParameterName
      ] as string;
      const emailOld = queryParamHistory[1]?.[
        SiteAuthEmailParameterName
      ] as string;

      if (
        state !== stateOld ||
        returnUrl !== returnUrlOld ||
        email !== emailOld
      ) {
        if (!state) {
          onStateChange(SiteAuthState.None, {});
        } else {
          onStateChange(state as SiteAuthState, { returnUrl, email });
        }
      }
    }
  }, [queryParamHistory]);

  return router;
};
