import { Box } from '@komo-tech/ui/Box';
import { Group } from '@komo-tech/ui/Group';
import { KomoLoader, Loader } from '@komo-tech/ui/Loader';
import {
  OptionalThemeProvider,
  ThemeProviderProps
} from '@komo-tech/ui/theme/Provider';
import { FC } from 'react';

export interface LoadingPageProps
  extends Pick<ThemeProviderProps, 'forceColorScheme'> {
  backgroundImage?: {
    url: string;
    opacity?: number;
  };
  variant?: 'spinner' | 'komo';
  withThemeProvider?: boolean;
}
export const LoadingPage: FC<LoadingPageProps> = ({
  backgroundImage,
  variant = 'spinner',
  withThemeProvider = false,
  forceColorScheme
}) => {
  return (
    <OptionalThemeProvider
      forceColorScheme={forceColorScheme}
      withTheme={withThemeProvider}
    >
      {backgroundImage && (
        <Box
          bg={`url(${backgroundImage.url})`}
          opacity={backgroundImage.opacity}
          w={'100vw'}
          h={'100vh'}
          pos={'absolute'}
          style={{ zIndex: -1 }}
        />
      )}
      <Group h={'100vh'} align="center" justify="center" gap={0}>
        {variant === 'komo' ? <KomoLoader /> : <Loader size={'3rem'} />}
      </Group>
    </OptionalThemeProvider>
  );
};
