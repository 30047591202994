import { KeyOf } from '@komo-tech/core/models/path';

export type DynamicFormAddress = {
  placeId: string;
  text: string;
  streetAddress: string;
  subpremise?: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
};

export const resolveFieldName = (
  fieldName: string,
  key: KeyOf<DynamicFormAddress>
) => `${fieldName}_${key}`;
