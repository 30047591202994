import { createStore, useStore } from 'zustand';

import { IFrontSiteStore } from './_types';

export const FrontSiteStore = createStore<IFrontSiteStore>(() => ({
  site: null,
  clientRequestInfo: null
}));

export function useFrontSite<T = unknown>(
  selector: (state: IFrontSiteStore) => T
) {
  return useStore(FrontSiteStore, selector);
}
