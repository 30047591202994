import { ReactNode } from 'react';
import AnimateHeight from 'react-animate-height';

import { FCC } from '../../fcc';
import { ListItem } from './ListItem';
import { ListItemAction } from './ListItemAction';
import { ListItemIcon } from './ListItemIcon';
import classes from './ListItemParent.module.css';
import { ListItemText } from './ListItemText';

export interface Props {
  text?: string | ReactNode;
  icon?: ReactNode;
  action?: ReactNode;
  expanded: boolean;
  active?: boolean;
  onToggleExpanded: () => void;
  disabled?: boolean;
}

export const ListItemParent: FCC<Props> = ({
  text,
  icon,
  action,
  expanded,
  onToggleExpanded,
  active,
  disabled,
  children,
  ...rest
}) => {
  return (
    <>
      <ListItem
        active={active}
        expanded={expanded}
        onClick={onToggleExpanded}
        disabled={disabled}
        {...rest}
      >
        {icon && <ListItemIcon mr={'0.5rem'}>{icon}</ListItemIcon>}
        {text && (
          <ListItemText style={{ fontWeight: '700', fontSize: '12px' }}>
            {text}
          </ListItemText>
        )}

        {action ? (
          <ListItemAction onClick={(e) => e.stopPropagation()} right={'2rem'}>
            {action}
          </ListItemAction>
        ) : null}
      </ListItem>
      {children ? (
        <AnimateHeight
          className={classes.animateHeight}
          duration={500}
          height={expanded ? 'auto' : 0}
        >
          {children}
        </AnimateHeight>
      ) : null}
    </>
  );
};
