import { IApiError } from '@komo-tech/core/models/IApiError';
import { isString } from '@komo-tech/core/utils/type';
import { NotFoundException } from '@zxing/library';
import cx from 'clsx';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useActionHandler } from '../../../hooks/useActionHandler';
import { useEvent } from '../../../hooks/useEvent';
import { useFlag } from '../../../hooks/useFlag';
import { useMeasure } from '../../../hooks/useMeasure';
import { useOnMount } from '../../../hooks/useOnMount';
import { Box } from '../../Box';
import { Center } from '../../Center';
import { LottieSuccess } from '../../LottiePlayer/LottieSuccess';
import { Spinner } from '../../Spinner';
import { Text } from '../../Text';
import { useToaster } from '../../Toast';
import { QrCodeReaderError } from './QrCodeReader.Error';
import classes from './QrCodeReader.module.css';
import { QrCodeReaderProps, QrCodeReaderStatuses } from './QrCodeReader.types';
import { QrCodeReaderVideo } from './QrCodeReader.Video';

export const QrCodeReader: FC<QrCodeReaderProps> = ({
  onCheckAsync,
  onError,
  onSuccess,
  onIsValidCallback,
  defaultText,
  containerProps: containerPropsProp,
  errorProps,
  infoContent,
  qrBorderColor = 'var(--mantine-color-text)',
  scanDelay = 200,
  isValid
}) => {
  const [handleAsync, { isHandling }] = useActionHandler();
  const isCheckingRef = useRef(false);
  const toaster = useToaster();
  const cameraError = useFlag(false);
  const [error, setError] = useState<string>(null);
  const container = useMeasure();

  const handleIsValidCallback = useEvent(onIsValidCallback);

  const handleCheckAsync = useEvent((text: string) =>
    handleAsync(() => onCheckAsync(text), {
      onSuccess: (x) => {
        isCheckingRef.current = false;
        if (!x?.success) {
          setError(x?.errorMessage || 'Invalid QR code');
        } else {
          onSuccess?.(text);
        }
      },
      noToastOnError: true,
      noLogOnError: true,
      onError: (e) => {
        if ((e as IApiError)?.isUnhandledException?.()) {
          console.error(e);
        }
        isCheckingRef.current = false;
        const message = tryGetErrorMessage(e);
        setError(message);
        onError?.(message);
      }
    })
  );

  const status = useMemo<QrCodeReaderStatuses>(() => {
    if (cameraError.value) {
      return 'error';
    }

    if (container.data.bounds.height <= 0) {
      return 'initializing';
    }

    if (isHandling) {
      return 'loading';
    }

    if (isValid) {
      return 'complete';
    }

    if (container.data.bounds.height > 0) {
      return 'camera';
    }

    return 'error';
  }, [isValid, cameraError.value, container.data.bounds.height, isHandling]);

  useOnMount(() => {
    if (!defaultText) {
      return;
    }

    handleCheckAsync(defaultText);
  });

  useEffect(() => {
    if (error) {
      toaster.error(error);
      // don't spam the user with toasts if they are hovering over the QR code
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const isComplete = status === 'complete';
  useEffect(() => {
    if (isComplete) {
      setTimeout(() => {
        handleIsValidCallback();
      }, 1250);
    }
  }, [isComplete]);

  const containerProps = containerPropsProp || {};

  const { className: containerClassName, ...containerRest } = containerProps;

  const hasInfoContent = !!infoContent;

  const iconWidth = container.data.bounds.width / 3 || '3rem';
  return (
    <Box
      {...containerRest}
      __vars={{
        '--qr-border-c': qrBorderColor
      }}
      className={cx(containerClassName, classes.readerRoot)}
      ref={container.register}
    >
      {status === 'error' && <QrCodeReaderError {...errorProps} />}
      {(status === 'camera' || status === 'loading') && (
        <>
          <QrCodeReaderVideo
            constraints={{
              aspectRatio: 1,
              facingMode: 'environment'
            }}
            scanDelay={scanDelay}
            onResultAsync={({ error, resultText }) => {
              if (isCheckingRef.current) {
                return Promise.resolve();
              }

              if (resultText) {
                isCheckingRef.current = true;
                return handleCheckAsync(resultText);
              }

              //Ignore not found as this spams every time a qr code was not found
              if (
                error?.name === 'NotFoundException' ||
                error instanceof NotFoundException
              ) {
                return Promise.resolve();
              }

              if (error) {
                console.error(error.message);
                cameraError.setTrue();
              }

              return Promise.resolve();
            }}
            backgroundElement={
              <QrCodeReaderError {...errorProps} withBracketsBorder={false} />
            }
          />
          {hasInfoContent && (
            <Text ta="center" mt="xl">
              {infoContent}
            </Text>
          )}
        </>
      )}
      {status === 'initializing' && (
        <Center h="100%" w="100%">
          <Spinner size={iconWidth} />
        </Center>
      )}
      {status === 'complete' && (
        <Center h="100%" w="100%">
          <LottieSuccess size={iconWidth} />
        </Center>
      )}
    </Box>
  );
};

const tryGetErrorMessage = (
  error?: unknown,
  fallback: string = 'An error occurred'
) => {
  if (isString(error)) {
    return error;
  }
  return (error as IApiError)?.errorMessage || fallback;
};
