import {
  __InputWrapperProps as MantineInputWrapperBaseProps,
  InputWrapper as MantineInputWrapper,
  InputWrapperProps as MantineInputWrapperProps,
  MantineSize
} from '@mantine/core';
import React, { ForwardedRef, forwardRef } from 'react';

import { useUncontrolled } from '../../../hooks/useUncontrolled';
import { FormInputWrapper, IAmFormInputRenderProps } from '../InputWrapper';
import { ValueListEditor, ValueListEditorProps } from './ValueListEditor';

export interface FormValueListEditorProps<TValue = string>
  extends Omit<MantineInputWrapperBaseProps, 'labelProps' | 'descriptionProps'>,
    Omit<
      IAmFormInputRenderProps<HTMLDivElement>,
      'onFocus' | 'selectAllTextOnFocus' | 'autoFocus'
    >,
    Omit<ValueListEditorProps<TValue>, 'items' | 'onChange'>,
    Pick<
      MantineInputWrapperProps,
      'm' | 'mt' | 'mb' | 'mx' | 'my' | 'id' | 'w' | 'maw'
    > {
  defaultValue?: TValue[];
  value?: TValue[];
  onChange?: (values: TValue[]) => void;
  disabled?: boolean;
  size?: MantineSize;
}

function _FormValueListEditor<TValue = string>(
  {
    error,
    label,
    showSkeleton,
    labelProps,
    defaultValue,
    hideErrorMessage,
    value: valueProp,
    description,
    descriptionProps,
    inputContainer,
    size,
    required,
    onChange,
    disabled,
    bulkAddPlaceholder,
    addPlaceholder,
    emptyLabel,
    itemName,
    hideEmptyList,
    resolveValue,
    actionsFn,
    withSearch,
    valueKey,
    popoverWithinPortal,
    ...rest
  }: FormValueListEditorProps<TValue>,
  forwardedRef: ForwardedRef<HTMLDivElement>
) {
  const [value, handleChange] = useUncontrolled({
    defaultValue: defaultValue ?? [],
    value: valueProp,
    onChange
  });

  return (
    <FormInputWrapper<HTMLDivElement>
      ref={forwardedRef}
      size={size}
      inputContainer={inputContainer}
      labelProps={labelProps}
      descriptionProps={descriptionProps}
      showSkeleton={showSkeleton}
      error={error}
      hideErrorMessage={hideErrorMessage}
    >
      {(p) => (
        <MantineInputWrapper
          withAsterisk={required}
          label={label}
          description={description}
          {...p}
          {...rest}
        >
          <ValueListEditor<TValue>
            items={value}
            onChange={handleChange}
            disabled={disabled}
            bulkAddPlaceholder={bulkAddPlaceholder}
            addPlaceholder={addPlaceholder}
            emptyLabel={emptyLabel}
            itemName={itemName}
            hideEmptyList={hideEmptyList}
            resolveValue={resolveValue}
            actionsFn={actionsFn}
            withSearch={withSearch}
            valueKey={valueKey}
            popoverWithinPortal={popoverWithinPortal}
          />
        </MantineInputWrapper>
      )}
    </FormInputWrapper>
  );
}

export const FormValueListEditor = forwardRef(_FormValueListEditor) as <
  TValue = string
>(
  props: FormValueListEditorProps<TValue> & {
    ref?: ForwardedRef<HTMLDivElement>;
  }
) => ReturnType<typeof _FormValueListEditor>;
