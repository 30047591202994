import {
  FormHookFileInputV2,
  resolveFormFileInputValue
} from '@komo-tech/ui/Form/FileInput';
import { FieldValidator } from '@komo-tech/ui/Form/Validators';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import {
  DynamicFormContextData,
  FileDynamicFieldOptions,
  IAmDynamicFormFieldProps
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormFileField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  const { required } = field;

  const { fileLimit, ...restOptions } = field.data
    .options as FileDynamicFieldOptions;

  const extraValidators = useMemo(() => {
    const validators: FieldValidator<TFieldValues, TContextData>[] = [];
    if (required) {
      validators.push((v: string) => {
        if (!v) return undefined; //Handled by the default required validator
        if (!resolveFormFileInputValue(v)?.length) {
          return 'Field is required';
        }
        return undefined;
      });
    }

    if (fileLimit > 0) {
      validators.push((v: string) => {
        if (resolveFormFileInputValue(v)?.length > fileLimit) {
          return `Only ${fileLimit.toLocaleString()} file(s) can be uploaded`;
        }
        return undefined;
      });
    }

    return validators;
  }, [required, fileLimit]);

  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
      extraValidators={extraValidators}
    >
      {(h) => (
        <FormHookFileInputV2
          hook={h}
          {...(field.data.options as FileDynamicFieldOptions)}
          {...rest}
          fileLimit={fileLimit}
          {...restOptions}
        />
      )}
    </DynamicFormHookField>
  );
}
