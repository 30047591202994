import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { FC } from 'react';

import { useLocalTime } from '../../hooks/useLocalTime';
import { Box } from '../Box';

interface Props {
  timeZone: string;
  variant?: 'default' | 'unstyled';
}
export const LocalTime: FC<Props> = ({ timeZone, variant = 'default' }) => {
  const [date, formattedDate] = useLocalTime(timeZone);
  const text = `Local time for ${timeZone} is ${formattedDate} ${formatInTimeZone(
    date,
    timeZone,
    'h:mm a'
  )}`;

  if (variant === 'unstyled') {
    return <span>{text}</span>;
  }

  return <Box c="dimmed">{text}</Box>;
};
