let windowObjectReference = null;
let previousUrl = null;

const receiveMessage = (
  event,
  eventListener: (event: MessageEvent<any>) => void
) => {
  // Do we trust the sender of this message? (might be
  // different from what we originally opened, for example).
  if (event.origin !== window.location.origin) {
    return;
  }
  eventListener(event);
};

//removing events doesn't work because they never have a stable reference
//this is used to ensure only one event is added for each window
const addedEvents = [];

//https://blog.leavemealone.app/how-to-oauth-popup/
export const openNewWindow = (
  url: string,
  target: string,
  eventListener?: (event: MessageEvent<any>) => void,
  signal?: AbortSignal
) => {
  // window features
  const strWindowFeatures =
    'toolbar=no, menubar=no, width=600, height=800, top=100, left=100, popup=yes';

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, target, strWindowFeatures);
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, target, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
    windowObjectReference.focus();
  }

  // add the listener for receiving a message from the popup
  if (eventListener && !addedEvents.includes(target)) {
    addedEvents.push(target);
    window.addEventListener(
      'message',
      (event) => {
        const i = addedEvents.indexOf(target);
        if (i > -1) {
          addedEvents.splice(i, 1);
        }
        receiveMessage(event, eventListener);
      },
      { signal: signal }
    );
  }

  // assign the previous URL
  previousUrl = url;
};
