export enum ModalSizesEnum {
  Normal = 'Normal',
  Small = 'Small',
  Large = 'Large',
  XLarge = 'XLarge',
  FullScreen = 'FullScreen'
}

export type ModalSizes =
  | ModalSizesEnum
  | 'Normal'
  | 'Small'
  | 'Large'
  | 'XLarge'
  | 'FullScreen';
