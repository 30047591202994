import { FieldValues } from 'react-hook-form';

import { FormHookImageEditorV2 } from '../../FormImageEditor/FormHookImageEditorV2';
import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  ImageDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormImageField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  const { onSignAsync, forceElementSize, ...restOptions } = field.data
    .options as ImageDynamicFieldOptions;

  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => (
        <FormHookImageEditorV2
          hook={h}
          onSignAsync={onSignAsync}
          forceSize={forceElementSize}
          {...restOptions}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
