import {
  isInIFrame as isInIFrameFn,
  isInReactNativeWebview as isInReactNativeWebviewFn,
  ReactNativeWebViewName
} from '@komo-tech/core/utils/browser';
import {
  createContext,
  useContextSelector
} from '@komo-tech/ui/hooks/useContextSelector';
import { useEffectSkipInitial } from '@komo-tech/ui/hooks/useEffectSkipInitial';
import { useLatest } from '@komo-tech/ui/hooks/useLatest';
import { useModalsShown } from '@komo-tech/ui/Portal/DomPortal';
import { FCC } from 'fcc';
import isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';

import { isProductionEnvironment } from '@/common/utils/NextFunctions';
import {
  EmbedClientMessages,
  EmbeddedClientMessageTypes,
  EmbeddedHostMessageTypes
} from '@/front/components/embedded/EmbeddedMessageTypes';
import { useSiteCache } from '@/front/utils/SiteCache';

const isProduction = isProductionEnvironment();
type CleanupFunction = () => void;

export interface EmbedHostData {
  /**
   * The URL where the embedded app is hosted.
   */
  url: string;
  /**
   * The title of the page hosting the embedded app.
   */
  title: string;
  /**
   * The instance ID for this embedded app.
   * There could be multiple komo embeds on one page - this differentiates them.
   */
  embedId: string;
  /**
   * The URL to redirect to when a user clicks on a share link.
   */
  shareClickUrl?: string;
}

interface EmbeddedHostContextState {
  subscribe: (
    messageType: string,
    handler: (...args: any[]) => void
  ) => CleanupFunction;
  isInKomoIframe: boolean;
  isInReactNativeWebview: boolean;
  sendMessage: (data: EmbedClientMessages) => void;
  embedHostData?: EmbedHostData;
}

const EmbeddedHostContext = createContext<EmbeddedHostContextState | undefined>(
  undefined
);

interface Props {
  debug?: boolean;
}

export const EmbeddedHostProvider: FCC<Props> = ({
  debug = false,
  children
}) => {
  const isInIframe = useMemo(() => isInIFrameFn(), []);
  const isInReactNativeWebview = useMemo(() => isInReactNativeWebviewFn(), []);
  const [embedHostData, setEmbedHostData] = useState<EmbedHostData>(undefined);
  const setEmbeddedHostUrl = useSiteCache((x) => x.setEmbeddedHostUrl);
  const isInitialized = !!embedHostData;
  const modalsShown = useModalsShown();

  const debugLog = (...data: any[]) => {
    if (!isProduction || debug) {
      console.debug(`Embedded Site: ${data[0]}`, ...data.slice(1));
    }
  };

  useEffect(() => {
    debugLog('is in iframe?', isInIframe);
    debugLog('is in RN WebView?', isInReactNativeWebview);

    let unsub = () => {};

    if (isInIframe || isInReactNativeWebview) {
      // request init from parent komo script, on page load
      unsub = subscribe(EmbeddedHostMessageTypes.Init, (m) => {
        setEmbedHostData((prev) => {
          if (prev === undefined && !!m) {
            if (!!m.url) {
              setEmbeddedHostUrl(m.url);
            }
            return m;
          }
          return prev;
        });
      });
    } else {
      setEmbeddedHostUrl(undefined);
    }

    sendMessageRef.current({ message: EmbeddedClientMessageTypes.RequestInit });

    // This fixes a bug where tapping outside the iframe (when embedded)
    // will result in tap events not being registered correctly
    document.addEventListener('touchend', () => {
      window.focus();
    });
    // potential fix for another bug where iframe loses input on iOS
    // https://stackoverflow.com/a/53445848/3321428
    document.addEventListener('touchstart', () => {});

    return unsub;
  }, [isInIframe, isInReactNativeWebview]);

  /**
   * Subscribe to messages from the parent script.
   * @param messageType The type of the message to handle
   * @param handler The handler function
   */
  const subscribe = (
    messageType: string,
    handler: (...args: any[]) => void
  ) => {
    if ((!isInIframe || !window) && !isInReactNativeWebview) {
      return () => {};
    }

    function handleMessageInner(e) {
      if (isNil(e.data)) return;

      let data = {} as any;
      // events sent from RN webview are string
      if (typeof e.data === 'string') {
        try {
          data = JSON.parse(e.data);
        } catch (e) {
          debugLog(e);
        }
      } else {
        data = e.data;
      }

      const message = data.message || '';
      if (!message.startsWith('komo:')) {
        debugLog(`unsupported message ${message}`, data);
        return;
      }

      if (message === messageType) {
        debugLog(message, data);
        handler(data);
      }
    }

    window.addEventListener('message', handleMessageInner, false);

    return () =>
      window.removeEventListener('message', handleMessageInner, false);
  };

  const embedId = embedHostData?.embedId;
  const sendMessageRef = useLatest((data: EmbedClientMessages) => {
    if ((!isInIframe || !window?.parent) && !isInReactNativeWebview) {
      return;
    }

    // Prevent sending of messages if we are not yet initialized from a parent Komo embed script.
    // E.g. if we're in a random iframe, we don't want to pump messages up (except for request init).
    if (
      !isInitialized &&
      data.message !== EmbeddedClientMessageTypes.RequestInit
    ) {
      return;
    }

    // augment messages with embedId if we have it
    if (data.embedId === undefined && embedId !== undefined) {
      data.embedId = embedId;
    }

    debugLog('Embed - sending message', data);

    if (isInReactNativeWebview) {
      window[ReactNativeWebViewName]?.postMessage(JSON.stringify(data));
      return;
    }

    window.parent.postMessage(data, '*');
  });

  useEffectSkipInitial(() => {
    if (modalsShown <= 0) {
      sendMessageRef.current({
        message: EmbeddedClientMessageTypes.ShowHeader,
        showHeader: true
      });
    } else {
      sendMessageRef.current({
        message: EmbeddedClientMessageTypes.ShowHeader,
        showHeader: false
      });
    }
  }, [modalsShown]);

  const value = useMemo(() => {
    return {
      subscribe,
      isInKomoIframe: isInIframe && isInitialized,
      isInReactNativeWebview: isInReactNativeWebview,
      sendMessage: sendMessageRef.current,
      embedHostData
    };
  }, [isInIframe, embedHostData, isInReactNativeWebview]);

  return (
    <EmbeddedHostContext.Provider value={value}>
      {children}
    </EmbeddedHostContext.Provider>
  );
};

export function useUnsafeEmbeddedHostContextSelector<T>(
  selector: (value?: EmbeddedHostContextState) => T
): T | undefined {
  return useContextSelector(EmbeddedHostContext, (state) => {
    if (state === undefined) {
      return undefined;
    }
    return selector(state);
  });
}

export const useUnsafeEmbeddedHostShareInfo = () => {
  const embeddedHostUrl = useUnsafeEmbeddedHostContextSelector(
    (s) => s.embedHostData?.url
  );
  const shareClickUrl = useUnsafeEmbeddedHostContextSelector(
    (s) => s.embedHostData?.shareClickUrl
  );

  return { embeddedHostUrl, shareClickUrl };
};

/**
 * Check if the app is currently contained in an initialized Komo embed SDK iframe.
 */
export const useUnsafeIsInKomoEmbed = (): boolean => {
  const isInKomoIframe = useUnsafeEmbeddedHostContextSelector(
    (s) => s.isInKomoIframe
  );
  const isInReactNativeWebview = useUnsafeEmbeddedHostContextSelector(
    (s) => s.isInReactNativeWebview
  );
  return isInKomoIframe || isInReactNativeWebview;
};

export const useUnsafeSendMessage = () => {
  const isInEmbed = useUnsafeIsInKomoEmbed();
  const sendMessage = useUnsafeEmbeddedHostContextSelector(
    (s) => s.sendMessage
  );
  const sendMessageRef = useLatest<EmbeddedHostContextState['sendMessage']>(
    (message) => {
      if (!isInEmbed || !sendMessage) {
        if (!isProduction) {
          console.debug(
            `Embed message (${message.message}) triggered (not sent as not in embed)`,
            message
          );
        }
        return;
      }

      sendMessage(message);
    }
  );

  return sendMessageRef.current;
};
