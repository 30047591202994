import { KeyOf } from '@komo-tech/core/models/path';
import { FormFileAllowedType } from '@komo-tech/core/utils/file';
import { nanoid } from '@komo-tech/core/utils/nanoid';
import React, {
  FC,
  forwardRef,
  ReactNode,
  Ref,
  useMemo,
  useState
} from 'react';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import { Theme, UiSize } from '../../../theme/types';
import { useTheme } from '../../../theme/useTheme';
import { BoxProps } from '../../Box';
import { UploadIcon } from '../../Icons/UploadIcon';
import { Label } from '../../Label';
import { Stack, StackProps } from '../../Stack';
import { Text } from '../../Text';
import { useToaster } from '../../Toast';
import { FormGroup } from '../Group';
import { FormInputError } from '../InputError';
import { sanitiseDropEvent, validateMime } from './_functions';
import classes from './FormFileDropUpload.module.css';

type IconUploadProps = {
  size: UiSize;
  color: string;
};
export interface FormFileDropUploadProps<TFormValues extends object = any> {
  children?: any;
  w?: BoxProps['w'];
  mb?: BoxProps['mb'];
  maw?: BoxProps['maw'];
  mah?: BoxProps['mah'];
  h?: BoxProps['h'];
  name: KeyOf<TFormValues>;
  validate?: (file: File, event?: DropEvent) => string;
  onUpload: (file: File, type: FormFileAllowedType) => void;
  required?: boolean;
  disabled?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  label?: ReactNode;
  caption?: ReactNode;
  maxSize?: number;
  allowedFileTypes?: FormFileAllowedType[];
  hasError?: boolean;
  errorMessage?: string;
  hideContent?: boolean;
  bg?: string;
  uploadTextC?: string;
  uploadIcon?: (props: IconUploadProps) => ReactNode;
  /**
   * Set the react-dropzone accept property based on the allowedFileTypes. Limits the file types in the file picker.
   */
  withAccept?: boolean;
  innerProps?: StackProps;
}

const toReactDropzoneAccept = (
  allowedFileTypes: FormFileAllowedType[] = []
) => {
  if (allowedFileTypes.length === 0) {
    return undefined;
  }

  const accept: Accept = {};
  allowedFileTypes.forEach((allowedFileType) => {
    switch (allowedFileType) {
      case 'image':
        accept['image/*'] = [];
        return;
      case 'pdf':
        accept['application/pdf'] = ['.pdf'];
        return;
      default:
        // ignore other types
        return;
    }
  });
  return accept;
};

const FormFileDropUploadElement: FC<FormFileDropUploadProps> = ({
  children,
  name,
  errorMessage,
  required,
  hasError,
  label,
  caption,
  validate,
  onUpload,
  allowedFileTypes,
  disabled,
  mb,
  w,
  maw,
  mah,
  h,
  hideContent,
  uploadIcon,
  withAccept,
  bg,
  innerProps,
  uploadTextC = 'inherit',
  ...rest
}) => {
  const toaster = useToaster();
  const theme = useTheme();
  const id = useMemo(() => `${nanoid()}-${name as string}`, [name]);
  const [dragErrorText, setDragErrorText] = useState<string | null>(null);

  const handleDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => {
    if (disabled) return;
    const result = sanitiseDropEvent(
      acceptedFiles,
      fileRejections,
      event,
      allowedFileTypes,
      validate
    );

    if (result.errors.length) {
      toaster.error(result.errors.join(', '));
    }

    if (result.file) {
      onUpload(result.file, result.type);
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLElement>) => {
    if (
      !e.dataTransfer.items ||
      !e.dataTransfer.items.length ||
      !allowedFileTypes ||
      !allowedFileTypes.length
    )
      return;

    let errors: number = 0;
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      if (validateMime(e.dataTransfer.items[i].type, allowedFileTypes)) {
        errors++;
      }
    }

    setDragErrorText(
      !errors
        ? null
        : `${errors} file/s must be of type: ${allowedFileTypes.join(' ')}`
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onDragEnter: handleDragEnter,
    onDragLeave: () => setDragErrorText(null),
    accept: withAccept ? toReactDropzoneAccept(allowedFileTypes) : undefined,
    ...rest
  });

  const hasDragError = !!dragErrorText;
  const uploadIconProps: IconUploadProps = {
    size: 'xl',
    color: resolveColor(theme, isDragActive, hasDragError, disabled)
  };
  return (
    <FormGroup mb={mb} w={w} maw={maw} mah={mah} h={h}>
      {label && (
        <Label htmlFor={id} required={required}>
          {label}
        </Label>
      )}
      <Stack
        gap={0}
        bg={bg}
        {...(innerProps || {})}
        {...getRootProps()}
        className={classes.container}
        __vars={{
          '--form-file-drop-upload-outline-color': resolveColor(
            theme,
            isDragActive,
            hasDragError,
            disabled
          )
        }}
        data-dragging={isDragActive}
        data-drag-error={hasDragError}
        data-disabled={disabled}
      >
        <input {...getInputProps()} disabled={disabled} />
        {!hideContent && (
          <>
            {!!uploadIcon ? (
              uploadIcon(uploadIconProps)
            ) : (
              <UploadIcon {...uploadIconProps} />
            )}

            {!caption ? (
              <Text c={uploadTextC} fz={'sm'} mt={5}>
                <strong>Choose a File</strong> or drag it here
              </Text>
            ) : (
              <>{caption}</>
            )}
          </>
        )}

        {children}
      </Stack>
      {hasError && errorMessage && (
        <FormInputError>{errorMessage}</FormInputError>
      )}
    </FormGroup>
  );
};
export const FormFileDropUpload = forwardRef<
  HTMLInputElement,
  FormFileDropUploadProps
>((props, ref) => <FormFileDropUploadElement inputRef={ref} {...props} />);
FormFileDropUpload.displayName = 'FormFileDropUpload';

const resolveColor = (
  theme: Theme,
  dragging: boolean,
  dragError: boolean,
  disabled?: boolean
) => {
  let color = theme.colors.blue[8];

  if (!disabled && dragging) {
    color = dragError ? theme.colors.red[9] : theme.colors.green[9];
  }

  return color;
};
