import { BoxProps as MantineBoxProps } from '@mantine/core';
import cx from 'clsx';
import { CSSProperties } from 'react';

import { FCC } from '../../fcc';
import { Box } from '../Box';
import classes from './ListItemText.module.css';

export interface ListItemTextProps extends MantineBoxProps {
  style?: CSSProperties;
}
export const ListItemText: FCC<ListItemTextProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <Box className={cx(classes.root, className)} {...rest}>
      {children}
    </Box>
  );
};
ListItemText.displayName = 'ListItemText';
