import { CSSProperties, forwardRef, useMemo } from 'react';

import { Box, BoxProps } from '../Box';
import { KomoLoader, Loader } from '../Loader';
import classes from './BlockLoader.module.css';

export interface BlockLoaderProps {
  isLoading: boolean;
  containerProps?: Omit<BoxProps, 'pos'>;
  outerStyle?: any;
  variant?: 'default' | 'komo' | 'custom';
  size?: string | number;
  customLoader?: React.ReactNode;
  opacity?: number;
  children: React.ReactNode;
}

export const BlockLoader = forwardRef<HTMLDivElement, BlockLoaderProps>(
  (
    {
      isLoading,
      containerProps,
      variant = 'default',
      customLoader,
      opacity = 0.5,
      size,
      children
    },
    ref
  ) => {
    const overlayStyle = useMemo<CSSProperties>(() => ({ opacity }), [opacity]);
    return (
      <Box
        pos={'relative'}
        ref={ref}
        data-block-loader
        {...(containerProps || {})}
      >
        {children}
        {isLoading && (
          <div className={classes.container}>
            <div className={classes.overlay} style={overlayStyle} />

            <div className={classes.messageWrapper}>
              {variant === 'default' && <Loader size={size} />}
              {variant === 'komo' && <KomoLoader />}
              {variant === 'custom' && customLoader}
            </div>
          </div>
        )}
      </Box>
    );
  }
);
