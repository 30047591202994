import { FC, ReactNode } from 'react';

import { ActionButton } from '../ActionButton';
import { Box } from '../Box';
import { StyledButtonVariantProps } from '../Button/Button.styles';
import { XIcon } from '../Icons/XIcon';
import classes from './Drawer.module.css';

interface Props {
  title: ReactNode;
  noBottomBorder?: boolean;
  onClose: () => void;
  closeButtonStyle?: StyledButtonVariantProps;
}

export const DrawerHeader: FC<Props> = ({
  title,
  onClose,
  noBottomBorder,
  closeButtonStyle
}) => {
  return (
    <Box
      className={classes.header}
      data-border-bottom={noBottomBorder ? 'false' : 'true'}
    >
      {title}
      <ActionButton
        size="xl"
        variant="styled"
        tabIndex={-1}
        styledVariantProps={closeButtonStyle}
        onClick={onClose}
        className={classes.headerCloseButton}
      >
        <XIcon size={'xl'} />
      </ActionButton>
    </Box>
  );
};
