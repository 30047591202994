import type {
  CardFactory as MantineCardFactory,
  CardProps as MantineCardProps
} from '@mantine/core';
import {
  Card as MantineCard,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';

type DisplayCardReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantineCardFactory>
>;

export const DisplayCard = MantineCard as DisplayCardReturnType;
export type DisplayCardProps = MantineCardProps;
