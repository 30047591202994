import { forwardRef } from 'react';

import { IconProps, tablerIconClasses } from '../Icons/IconProps';
import { IconSvg } from '../Icons/IconSvg';

export const ClockIcon = forwardRef<HTMLSpanElement, IconProps>(
  ({ className, ...rest }, ref) => {
    return (
      <IconSvg ref={ref} className={tablerIconClasses(className)} {...rest}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
        <path d="M12 12l2 3" />
        <path d="M12 7v5" />
      </IconSvg>
    );
  }
);
