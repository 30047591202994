import {
  Combobox as MantineCombobox,
  ComboboxChevron as MantineComboboxChevron,
  ComboboxChevronProps as MantineComboboxChevronProps,
  ComboboxItem as MantineComboboxItem,
  ComboboxProps as MantineComboboxProps,
  MantineComponent
} from '@mantine/core';

export const Combobox = MantineCombobox;
export type ComboboxProps = MantineComboboxProps;
export type ComboboxItem = MantineComboboxItem;
export type ComboboxChevronProps = MantineComboboxChevronProps;
export const ComboboxChevron = MantineComboboxChevron as MantineComponent<{
  props: ComboboxChevronProps;
  ref: SVGSVGElement;
  stylesNames: 'chevron';
  vars: {
    chevron: '--combobox-chevron-size';
  };
}>;
