import cx from 'clsx';
import { ReactNode } from 'react';

import { FCC } from '../../fcc';
import { Group, GroupProps } from '../Group';
import classes from './ModalSidebar.module.css';

interface Props extends GroupProps {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  stackOnSmallerScreens?: boolean;
}

export const ModalSidebarLayout: FCC<Props> = ({
  leftContent,
  children,
  rightContent,
  stackOnSmallerScreens,
  className,
  ...rest
}) => {
  return (
    <Group
      className={cx(className, classes.root)}
      data-stack-on-smaller-screens={stackOnSmallerScreens}
      {...rest}
    >
      {leftContent}
      {children}
      {rightContent}
    </Group>
  );
};
