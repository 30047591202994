'use client';
import cx from 'clsx';
import { CSSProperties, FC } from 'react';

import classes from './Icon.module.css';
import komoIconClasses from './KomoLogoIcon.module.css';

interface Props {
  className?: string;
  dotColor?: string;
  style?: CSSProperties;
}

export const KomoLogoIcon: FC<Props> = ({ dotColor, className, style }) => {
  return (
    <svg
      width="49"
      height="14"
      viewBox="0 0 49 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(classes.svg, className, komoIconClasses.komo)}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62642 8.87741L0.113281 8.87769V0H3.62642V8.87741Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7942 4.25391H7.66611L3.62891 8.90352L7.66611 13.5533H11.7942L7.66611 8.90352L11.7942 4.25391Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3646 10.7165C15.41 10.7165 14.6332 9.94028 14.6332 8.98478C14.6332 8.03072 15.41 7.25446 16.3646 7.25446C17.3222 7.25446 18.0975 8.03072 18.0975 8.98478C18.0975 9.94028 17.3222 10.7165 16.3646 10.7165ZM20.8631 6.36546C20.3973 5.59787 19.7725 5.0052 18.9841 4.59033C18.1973 4.17546 17.3265 3.96875 16.3748 3.96875C15.3593 3.96875 14.4582 4.1957 13.6627 4.64526C12.87 5.09627 12.2553 5.7034 11.8213 6.46664C11.3888 7.22989 11.1719 8.0683 11.1719 8.98478C11.1719 9.96052 11.4062 10.8336 11.8763 11.6027C12.3464 12.3717 12.9756 12.9643 13.7639 13.3778C14.5522 13.7926 15.4215 13.9994 16.3748 13.9994C17.3887 13.9994 18.2913 13.771 19.0854 13.3127C19.8795 12.8559 20.4885 12.2488 20.9181 11.4913C21.3448 10.7353 21.5588 9.8998 21.5588 8.98478C21.5588 8.00904 21.3274 7.13448 20.8631 6.36546Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.0123 10.7161C42.058 10.7161 41.2818 9.94042 41.2818 8.98405C41.2818 8.03014 42.058 7.25446 43.0123 7.25446C43.9695 7.25446 44.7456 8.03014 44.7456 8.98405C44.7456 9.94042 43.9695 10.7161 43.0123 10.7161ZM47.5104 6.3656C47.045 5.59715 46.4209 5.00462 45.6326 4.59033C44.8444 4.1759 43.9742 3.96875 43.0222 3.96875C42.0072 3.96875 41.1058 4.19512 40.3104 4.64555C39.5173 5.09613 38.9025 5.70311 38.4688 6.46679C38.0373 7.23047 37.8203 8.06874 37.8203 8.98405C37.8203 9.95979 38.0541 10.8342 38.5242 11.6026C38.9943 12.3711 39.6233 12.9636 40.4116 13.3781C41.1998 13.7924 42.07 13.9995 43.0222 13.9995C44.0369 13.9995 44.9384 13.7707 45.7338 13.313C46.5268 12.8554 47.1367 12.2484 47.5658 11.4918C47.9925 10.7355 48.2071 9.89951 48.2071 8.98405C48.2071 8.0086 47.9755 7.13405 47.5104 6.3656Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2545 4.9682C35.6951 4.30267 34.8751 3.96875 33.7944 3.96875C33.1356 3.96875 32.5525 4.10362 32.0477 4.37365C31.5427 4.64382 31.1588 5.02038 30.8959 5.49871C30.6305 5.00607 30.2701 4.62951 29.8151 4.36425C29.3576 4.10131 28.8243 3.96875 28.2105 3.96875C27.5873 3.96875 27.0421 4.09191 26.5823 4.33823C26.1177 4.58455 25.7671 4.93509 25.5275 5.39217L25.4921 4.25771H22.3398V13.5447H25.5633V8.63235C25.5633 8.05443 25.6769 7.61383 25.9068 7.30838C26.1343 7.0028 26.4638 6.84885 26.8951 6.84885C27.3051 6.84885 27.6085 6.98386 27.8053 7.25389C28.0043 7.52391 28.1038 7.94081 28.1038 8.50442V13.5447H31.3271V8.63235C31.3271 8.05443 31.4409 7.61383 31.6684 7.30838C31.8983 7.0028 32.2207 6.84885 32.6426 6.84885C33.0619 6.84885 33.3725 6.98386 33.5692 7.25389C33.7682 7.52391 33.8679 7.94081 33.8679 8.50442V13.5447H37.0912V7.89339C37.0912 6.60961 36.8114 5.63372 36.2545 4.9682Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={komoIconClasses.dot}
        d="M3.7761 12.0932C3.7761 13.1347 2.93109 13.9808 1.88718 13.9808C0.846607 13.9808 0 13.1347 0 12.0932C0 11.0531 0.846607 10.207 1.88718 10.207C2.93109 10.207 3.7761 11.0531 3.7761 12.0932Z"
        fill={dotColor}
      />
    </svg>
  );
};
