import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { useState } from 'react';

import { useInterval } from './useInterval';

export const useLocalTime = (timeZone: string) => {
  const [dateTime, setDateTime] = useState(new Date());

  useInterval(() => setDateTime(new Date()), 1000, true);
  const formatted = formatInTimeZone(dateTime, timeZone, 'dd/MM/yyyy');
  return [dateTime, formatted] as [Date, string];
};
