import { Box } from '@komo-tech/ui/Box';
import { FC } from 'react';

import { FrontBadgeView } from '@/front/models/FrontBadgeView';

import classes from './BadgeGrid.module.css';
import { BadgeTile } from './BadgeTile';

interface Props {
  data: FrontBadgeView[];
}

export const BadgeGrid: FC<Props> = ({ data }) => {
  return (
    <Box className={classes.grid}>
      {data.map((b, i) => {
        const { earned, achievementId } = b.isEarned();
        return (
          <BadgeTile key={earned ? achievementId.toString() : i} badge={b} />
        );
      })}
    </Box>
  );
};
