import React, { FC } from 'react';

import { IconProps } from './IconProps';
import { IconSvg } from './IconSvg';

export const PadlockIcon: FC<IconProps> = ({ color, ...rest }) => (
  <IconSvg {...rest}>
    <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.5a4 4 0 118 0v3H8v-3zm-2 3v-3a6 6 0 1112 0v3h1a3 3 0 013 3v7a3 3 0 01-3 3H5a3 3 0 01-3-3v-7a3 3 0 013-3h1zm11 2H5a1 1 0 00-1 1v7a1 1 0 001 1h14a1 1 0 001-1v-7a1 1 0 00-1-1h-2z"
        fill={color || 'currentColor'}
      />
    </svg>
  </IconSvg>
);
