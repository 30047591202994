import React, { FC, MutableRefObject, useMemo } from 'react';

import { ModalSizes } from './ModalSizes';
import { ModalState, ModalStateContext } from './ModalStateContext';

/**
 * Modal Provider Props
 */
interface Props {
  /**
   * The size of the modal.
   */
  size: ModalSizes;

  contentRef: MutableRefObject<HTMLDivElement>;
  overlayRef: MutableRefObject<HTMLDivElement>;

  /**
   * The model header, content and footer.
   */
  children: React.ReactNode;
}

/**
 * The component for providing the current modal state.
 */
export const ModalStateProvider: FC<Props> = ({
  size,
  contentRef,
  overlayRef,
  children
}) => {
  const value = useMemo<ModalState>(
    () => ({
      contentRef,
      overlayRef,
      size
    }),
    [size]
  );

  return (
    <ModalStateContext.Provider value={value}>
      {children}
    </ModalStateContext.Provider>
  );
};
ModalStateProvider.displayName = 'ModalStateProvider';
