import { ColorRGBA } from '@komo-tech/core/models/ColorRGBA';
import { Guid } from '@komo-tech/core/models/Guid';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { Box } from '@komo-tech/ui/Box';
import { FormHookField, IAmFormHookField } from '@komo-tech/ui/Form/HookField';
import { FormInputWrapperElement } from '@komo-tech/ui/Form/InputWrapper/Element';
import { useConstant } from '@komo-tech/ui/hooks/useConstant';
import { Stack } from '@komo-tech/ui/Stack';
import { useTheme } from '@komo-tech/ui/theme/useTheme';
import cx from 'clsx';
import { FC } from 'react';

import { IAmDynamicFormFieldProps } from '@/common/components/Form/DynamicForm';
import { FormField } from '@/common/models/form/FormField';

import classes from './_Option.module.css';
import formClasses from './DynamicFormRenderer.module.css';
import optionWrapperClasses from './OptionWrapper.module.css';

interface Props extends IAmDynamicFormFieldProps {
  formField: FormField;
  onChange?: (value: Guid) => void;
}

export const DynamicFieldOption: FC<Props> = ({
  field,
  size,
  disabled,
  formField,
  onChange
}) => {
  return (
    <FormHookField
      name={field.name}
      disabled={disabled || field.disabled}
      validators={field.validators}
      required={{ enabled: field.required }}
    >
      {(h) => (
        <FieldElement
          hook={h}
          formField={formField}
          size={size}
          onChange={onChange}
        />
      )}
    </FormHookField>
  );
};

interface FieldElementProps
  extends Pick<Props, 'formField' | 'size' | 'onChange'>,
    IAmFormHookField {}

const FieldElement: FC<FieldElementProps> = ({
  hook,
  formField,
  size,
  onChange
}) => {
  const theme = useTheme();
  const hideLabel = useConstant(() => {
    if (formField.getNullableConfigProperty('HideLabel') !== undefined) {
      return formField.getNullableConfigProperty('HideLabel');
    }
    if (formField.properties.HideLabel !== undefined) {
      return formField.properties.HideLabel;
    }
    return false;
  });

  if (formField.displayOptions.length === 0) {
    return null;
  }

  const selectedColor = new ColorRGBA(
    theme?.other?.site?.accentBgColor || '#000000'
  );

  return (
    <FormInputWrapperElement
      label={formField.displayLabel}
      withAsterisk={hook.required}
      size={size}
      error={hook.showError ? hook.errorMessage : undefined}
    >
      <div
        data-is-grid={true}
        className={cx(classes.gridWrapper, formClasses.optionWrapper)}
      >
        {formField.displayOptions.sort(orderByAscending).map((option) => (
          <Box
            __vars={{
              '--dynamic-form-option-wrapper-border-color-selected':
                selectedColor.hexa,
              '--shadow-pulse-r': selectedColor.r?.toString(),
              '--shadow-pulse-g': selectedColor.g?.toString(),
              '--shadow-pulse-b': selectedColor.b?.toString(),
              '--shadow-pulse-a': selectedColor.a?.toString()
            }}
            key={option.order}
            className={cx(classes.optionWrapper, optionWrapperClasses.root)}
            onClick={() => {
              if (!hook.disabled) {
                hook.field.onChange(option.id.toString());
                onChange?.(option.id);
              }
            }}
            data-selected={Guid.equals(hook.field.value, option.id)}
            data-is-grid={true}
          >
            <Stack gap={0} align="center" w={'100%'}>
              <div
                data-is-grid={'true'}
                className={cx(classes.wrapper, formClasses.optionImage)}
              >
                <img
                  src={option.Image?.url}
                  className={classes.image}
                  alt={option.Image?.alt}
                />
              </div>
              {!hideLabel && (
                <div className={formClasses.textWrapper} data-is-grid={true}>
                  <div data-is-grid={true} className={formClasses.optionText}>
                    {option.label}
                  </div>
                </div>
              )}
            </Stack>
          </Box>
        ))}
      </div>
    </FormInputWrapperElement>
  );
};
