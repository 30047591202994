// Keep these types in sync with the Embed project & Embed native types
/**
 * Messages that come from the host page to us.
 */
export enum EmbeddedHostMessageTypes {
  Init = 'komo:init'
}

/**
 * Messages that we send up to the host page.
 */
export enum EmbeddedClientMessageTypes {
  RequestInit = 'komo:requestInit',
  CloseModal = 'komo:closeModal',
  ExperienceStateChange = 'komo:experienceStateChange',
  HeightUpdated = 'komo:heightUpdated',
  ShowHeader = 'komo:showHeader'
}

export type EmbedExperienceStates = 'loading' | 'ready' | 'complete';

export type EmbedClientMessages =
  | { message: EmbeddedClientMessageTypes.RequestInit; embedId?: string }
  | { message: EmbeddedClientMessageTypes.CloseModal; embedId?: string }
  | {
      message: EmbeddedClientMessageTypes.ExperienceStateChange;
      value: EmbedExperienceStates;
      embedId?: string;
    }
  | {
      message: EmbeddedClientMessageTypes.HeightUpdated;
      height: number;
      embedId?: string;
    }
  | {
      message: EmbeddedClientMessageTypes.ShowHeader;
      showHeader: boolean;
      embedId?: string;
    };
