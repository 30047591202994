import { AllCountriesLookup } from '@komo-tech/core/data/countries';
import { OptionModel } from '@komo-tech/core/models/OptionModel';
import isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';

import { loadGooglePlacesApiFront } from '@/common/utils/GoogleLocationsApi';

import { DynamicFormAddress } from './DynamicFieldAddress.types';

type UseAddressSuggestionsOptions = {
  disabled?: boolean;
  search: string;
  allowedCountries?: string[];
};

export const useAddressSuggestions = ({
  search: searchProp,
  disabled = false,
  allowedCountries = []
}: UseAddressSuggestionsOptions) => {
  const [suggestionsLookup, setSuggestionLookup] = useState<
    Record<string, OptionModel[]>
  >({});

  const [lastSuccessful, setLastSuccessful] = useState<string>('');

  const autoCompleteRef = useRef<google.maps.places.AutocompleteService>();
  const loadedApi = useRef(false);

  useEffect(() => {
    if (disabled || loadedApi.current) return;
    loadGooglePlacesApiFront(() => {
      autoCompleteRef.current = new google.maps.places.AutocompleteService();
    });
    loadedApi.current = true;
  }, [disabled]);

  const search = (searchProp || '').trim();

  useEffect(() => {
    if (disabled || !search) {
      return;
    }

    if (!isNil(suggestionsLookup[search])) {
      return;
    }

    searchLocationsAsync(autoCompleteRef.current, {
      search,
      allowedCountries,
      onSuccess: (d) => {
        setLastSuccessful(search);
        setSuggestionLookup((l) => {
          const updated = { ...l };
          updated[search] = d;
          return updated;
        });
      }
    });
  }, [search, disabled, allowedCountries]);

  return [suggestionsLookup, lastSuccessful] as const;
};

type SearchLocationsOptions = {
  search: string;
  allowedCountries?: string[];
  onSuccess: (data: OptionModel[]) => void;
};

const searchLocationsAsync = (
  service: google.maps.places.AutocompleteService,
  { search, allowedCountries = [], onSuccess }: SearchLocationsOptions
) => {
  if (!service?.getPlacePredictions) {
    return Promise.resolve();
  }

  return service.getPlacePredictions(
    {
      input: search,
      types: ['address'],
      componentRestrictions: {
        country: allowedCountries
      },
      //https://developers.google.com/maps/documentation/javascript/reference/places-service#LocationBias
      locationBias: 'IP_BIAS'
    },
    (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const newAddresses: OptionModel[] = [];
        for (let i = 0; i < predictions.length; i++) {
          const p = predictions[i];
          if (p.place_id) {
            newAddresses.push({ label: p.description, value: p.place_id });
          }
        }

        onSuccess(newAddresses);
      }
    }
  );
};

export const resolveAddressFromPlace = (
  placeId: string,
  callback: (address: Partial<DynamicFormAddress>) => void
) => {
  const elem = document.createElement('div');
  const service = new google.maps.places.PlacesService(elem);
  service.getDetails(
    {
      placeId: placeId,
      fields: ['formatted_address', 'address_components', 'place_id']
    },
    (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const address: Partial<DynamicFormAddress> = {
          placeId: place.place_id,
          text: place.formatted_address
        };

        if (place.address_components) {
          place.address_components.forEach((c) => {
            if (c.types.includes('country')) {
              const country = AllCountriesLookup[c.short_name];
              address.country = !!country ? country.name : c.long_name;
              return;
            }
            if (c.types.includes('postal_code')) {
              address.postal_code = c.long_name;
              return;
            }
            if (c.types.includes('administrative_area_level_1')) {
              address.state = c.long_name;
              return;
            }
            if (c.types.includes('locality')) {
              address.city = c.long_name;
              return;
            } else if (c.types.includes('sublocality') && !address.city) {
              address.city = c.long_name;
              return;
            } else if (c.types.includes('postal_town') && !address.city) {
              address.city = c.long_name;
              return;
            }
            if (c.types.includes('street_number')) {
              address.streetAddress = `${c.long_name} ${
                address.streetAddress || ''
              }`;
              return;
            }
            if (c.types.includes('route')) {
              address.streetAddress =
                (address.streetAddress || '') + c.long_name;
              return;
            }
            if (c.types.includes('subpremise')) {
              address.subpremise = c.long_name;
              return;
            }
          });
        }

        callback(address);
      }
    }
  );
};
