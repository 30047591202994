'use client';
import type { UnstyledButtonProps as MantineUnstyledButtonProps } from '@mantine/core';
import {
  polymorphicFactory as mantinePolymorphicFactory,
  UnstyledButton as MantineUnstyledButton,
  UnstyledButtonFactory as MantineUnstyledButtonFactory
} from '@mantine/core';

export type UnstyledButtonProps = MantineUnstyledButtonProps;
type UnstyledButtonReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantineUnstyledButtonFactory>
>;
/**
 * @see https://mantine.dev/core/unstyled-button/
 */
export const UnstyledButton = MantineUnstyledButton as UnstyledButtonReturnType;
