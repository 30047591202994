import { KeyOf } from '@komo-tech/core/models/path';
import {
  getLocaleDateString,
  parseStringDate
} from '@komo-tech/core/utils/date';
import { asHtmlWithExternalLinks } from '@komo-tech/core/utils/html';
import { isStringEqual } from '@komo-tech/core/utils/string';
import { FieldValidators, regexValidator } from '@komo-tech/ui/Form/Validators';
import { HtmlRenderer } from '@komo-tech/ui/HtmlRenderer';
import { Label } from '@komo-tech/ui/Label';
import { QrCodeReadResult } from '@komo-tech/ui/QrCode/Reader';
import { UiSize } from '@komo-tech/ui/theme/types';
import differenceInYears from 'date-fns/differenceInYears';
import isNil from 'lodash/isNil';
import { FC, ReactNode } from 'react';

import {
  BaseDynamicFieldOptions,
  DynamicFormFieldProps,
  NumberInputDynamicFieldOptions,
  TextDynamicFieldOptions
} from '@/common/components/Form/DynamicForm';
import { DynamicFormField } from '@/common/components/Form/DynamicForm/DynamicFormField';
import { ReceiptUploaderFieldName } from '@/common/components/ReceiptValidation/ReceiptUploaderFieldName';
import { FormField } from '@/common/models/form/FormField';
import {
  DropdownFieldConfig,
  NumberFieldConfig,
  QrCodeFieldConfig,
  TextFieldConfig
} from '@/common/models/form/FormFieldConfig';
import { FieldTypes } from '@/common/models/form/FormFieldTypes';
import { PublicFormService } from '@/front/data/PublicFormService';

import { DynamicFieldAddress } from './_AddressV2';
import { DynamicFormAddress } from './_AddressV2/DynamicFieldAddress.types';
import { DynamicFieldOption } from './_Option';

type Props = {
  formField: FormField;
  size?: UiSize;
  disabled?: boolean;
  descriptionFn?: (field: FormField) => ReactNode;
  onOptionSelect?: () => void;
};

export const DynamicFormRendererField: FC<Props> = ({
  formField,
  onOptionSelect,
  descriptionFn,
  ...rest
}) => {
  const field = formFieldToDynamicField({
    formField,
    onOptionSelect,
    descriptionFn
  });

  return (
    <DynamicFormField field={field} {...rest} id={formField.id.toString()} />
  );
};

export const formFieldToDynamicField = ({
  formField,
  onOptionSelect,
  descriptionFn
}: Pick<
  Props,
  'formField' | 'onOptionSelect' | 'descriptionFn'
>): DynamicFormFieldProps => {
  const sharedField: Pick<
    DynamicFormFieldProps,
    'name' | 'required' | 'validators' | 'disabled'
  > = {
    name: formField.name,
    required: formField.isRequired,
    disabled: formField.properties.IsReadOnly,
    validators: resolveValidators(formField)
  };

  if (formField.isHiddenField) {
    return {
      ...sharedField,
      data: {
        type: 'Hidden'
      }
    };
  }

  const description = descriptionFn?.(formField);
  const baseOptions: BaseDynamicFieldOptions = {
    label: <HtmlRenderer display="inline" html={formField.displayLabel} />,
    description
  };

  const textOptions: TextDynamicFieldOptions = {
    ...baseOptions,
    placeholder: formField.displayPlaceholder,
    inputMode: formField.getConfigProperty<TextFieldConfig>('InputMode')
  };

  const minNumberConfig = formField.getConfigProperty<NumberFieldConfig>('Min');
  const maxNumberConfig = formField.getConfigProperty<NumberFieldConfig>('Max');

  const numberOptions: NumberInputDynamicFieldOptions = {
    ...textOptions,
    min: isNil(minNumberConfig) ? formField.minNumber : minNumberConfig,
    max: isNil(maxNumberConfig) ? formField.maxNumber : maxNumberConfig,
    hideControls: formField.getConfigProperty<NumberFieldConfig>('HideControls')
  };

  const allowedCountries: string[] =
    formField.getConfigProperty('AllowedCountries') ?? [];

  switch (formField.type) {
    case FieldTypes.Address:
      return {
        ...sharedField,
        data: {
          type: 'Custom',
          options: {
            render: ({ disabled, field }) => (
              <DynamicFieldAddress
                field={field}
                formField={formField}
                size={'sm'}
                disabled={disabled}
              />
            )
          }
        }
      };
    case FieldTypes.BirthYear:
      return {
        ...sharedField,
        data: {
          type: 'BirthYear',
          options: textOptions
        }
      };
    case FieldTypes.Date:
    case FieldTypes.Birthday:
      return {
        ...sharedField,
        data: {
          type: 'DateMask',
          options: textOptions
        }
      };
    case FieldTypes.Country:
      return {
        ...sharedField,
        data: {
          type: 'Country',
          options: {
            ...baseOptions,
            allowedCountries,
            placeholder: formField.displayPlaceholder
          }
        }
      };
    case FieldTypes.Currency:
      return {
        ...sharedField,
        data: {
          type: 'Currency',
          options: numberOptions
        }
      };
    case FieldTypes.Dropdown:
      const defaultsTo =
        formField.getConfigProperty<DropdownFieldConfig>('DefaultsTo');
      const isPlaceholder =
        defaultsTo === 'Placeholder' ||
        (defaultsTo !== 'Option' && !formField.defaultValue);

      const displayOptions = formField.getSanitisedDisplayOptions();

      return {
        ...sharedField,
        data: {
          type: 'Select',
          options: {
            ...baseOptions,
            placeholder: isPlaceholder
              ? formField.displayPlaceholder
              : undefined,
            data: formField.getSanitisedDisplayOptions(),
            searchable: displayOptions.length > 5
          }
        }
      };
    case FieldTypes.Email:
      return {
        ...sharedField,
        data: {
          type: 'Email',
          options: textOptions
        }
      };
    case FieldTypes.File:
      return {
        ...sharedField,
        data: {
          type: 'File',
          options: {
            ...baseOptions,
            onUploadAsync: (files) =>
              formField.name === ReceiptUploaderFieldName
                ? PublicFormService.uploadReceiptFiles(formField, files)
                : PublicFormService.uploadFiles(formField, files),
            fileLimit: formField.properties.FileLimit,
            accept: formField.getFormFileAllowedTypes().join('/*,') + '/*',
            allowedFileTypes: formField.getFormFileAllowedTypes()
          }
        }
      };
    case FieldTypes.Hidden:
    case FieldTypes.DateTime: //Currently do not handle
    case FieldTypes.MultipleSelect: //Currently do not handle
      return {
        ...sharedField,
        data: {
          type: 'Hidden'
        }
      };
    case FieldTypes.Html:
      return {
        ...sharedField,
        data: {
          type: 'Custom',
          options: {
            render: () => (
              <Label
                sizeVariant="Normal"
                style={{
                  fontWeight: 400,
                  lineHeight: '1.5rem',
                  fontSize: '1rem'
                }}
              >
                <HtmlRenderer
                  html={formField.getConfigProperty('Html') || formField.label}
                  linkColor="revert"
                />
              </Label>
            )
          }
        }
      };
    case FieldTypes.Number:
      return {
        ...sharedField,
        data: {
          type: 'Number',
          options: numberOptions
        }
      };
    case FieldTypes.Optin:
      return {
        ...sharedField,
        data: {
          type: 'Checkbox',
          options: {
            label: asHtmlWithExternalLinks(formField.displayLabel),
            description,
            withAnimation: true
          }
        }
      };
    case FieldTypes.Option:
      return {
        ...sharedField,
        data: {
          type: 'Custom',
          options: {
            render: ({ size, disabled, field }) => (
              <DynamicFieldOption
                field={field}
                onChange={onOptionSelect}
                formField={formField}
                size={size}
                disabled={disabled}
              />
            )
          }
        }
      };
    case FieldTypes.Password:
      return {
        ...sharedField,
        data: {
          type: 'Password',
          options: textOptions
        }
      };
    case FieldTypes.Percentage:
      return {
        ...sharedField,
        data: {
          type: 'Percentage',
          options: numberOptions
        }
      };

    case FieldTypes.Phone:
      return {
        ...sharedField,
        data: {
          type: 'Mobile',
          options: {
            ...textOptions,
            allowedCountries
          }
        }
      };

    case FieldTypes.QrCode:
      return {
        ...sharedField,
        data: {
          type: 'QrCode',
          options: {
            label: formField.displayLabel,
            onCheckAsync: (v) => {
              const [valid, code] = (
                formField.getTypedFieldConfig() as QrCodeFieldConfig
              ).containsValidCode(v);

              return Promise.resolve(
                valid
                  ? QrCodeReadResult.forSuccess(code)
                  : QrCodeReadResult.forError('Invalid code')
              );
            }
          }
        }
      };
    case FieldTypes.TextArea:
      return {
        ...sharedField,
        data: {
          type: 'TextArea',
          options: textOptions
        }
      };
    case FieldTypes.Code:
    case FieldTypes.Text:
    case FieldTypes.UniqueCode:
    default:
      return {
        ...sharedField,
        data: {
          type: 'Text',
          options: textOptions
        }
      };
  }
};

const stringDateValidator = (v: string) => {
  if (!v) return undefined; //Required is handled in another validator
  let isValid = true;
  if (v.length < 10) {
    isValid = false;
  }
  if (isValid) {
    const date = parseStringDate(v);
    isValid = !!date;
  }

  return isValid
    ? undefined
    : `Invalid date format ${getLocaleDateString().toUpperCase()}`;
};

const resolveValidators = (field: FormField) => {
  const validators: DynamicFormFieldProps['validators'] = [];

  switch (field.type) {
    case FieldTypes.Text:
    case FieldTypes.TextArea:
      if (field.maxLength > 0) {
        validators.push(
          FieldValidators.characters({ variant: 'max', value: field.maxLength })
        );
      }
      if (field.minLength > 0) {
        validators.push(
          FieldValidators.characters({ variant: 'min', value: field.minLength })
        );
      }
      if (field.wordLimit > 0) {
        validators.push((v: string) => {
          if (!v) return undefined; //Required is handled in another validator
          const words = (v || '').split(/\s+/).filter((x) => x.length > 0);
          if (words.length > field.wordLimit) {
            return `Only ${field.wordLimit.toLocaleString()} word(s) can be entered`;
          }
        });
      }
      const rawRegex =
        field.getConfigProperty<TextFieldConfig>('ValidationRegex');
      if (!isNil(rawRegex)) {
        const regex = new RegExp(rawRegex);
        const errorMessage =
          field.getConfigProperty<TextFieldConfig>('RegexErrorMessage');
        validators.push(regexValidator(regex, errorMessage || 'Invalid value'));
      }
      break;
    case FieldTypes.Number:
    case FieldTypes.Currency:
      const maxNumber =
        field.getConfigProperty<NumberFieldConfig>('Max') || field.maxNumber;
      if (!isNil(maxNumber)) {
        validators.push(
          FieldValidators.lessThanOrEqualNumberValidator(maxNumber)
        );
      }
      const minNumber =
        field.getConfigProperty<NumberFieldConfig>('Min') || field.minNumber;
      if (!isNil(minNumber)) {
        validators.push(
          FieldValidators.greaterThanOrEqualNumberFieldValidator(minNumber)
        );
      }
      const numRawRegex =
        field.getConfigProperty<NumberFieldConfig>('ValidationRegex');
      if (!isNil(numRawRegex)) {
        const regex = new RegExp(numRawRegex);
        const errorMessage =
          field.getConfigProperty<NumberFieldConfig>('RegexErrorMessage');
        validators.push(regexValidator(regex, errorMessage || 'Invalid value'));
      }
      break;
    case FieldTypes.Code:
      const codes = field.properties.Code?.split(',') || [];
      validators.push((v: string) => {
        if (!codes.some((c) => isStringEqual(v, c.trim(), true))) {
          return 'Invalid code';
        }
        return undefined;
      });
      break;
    case FieldTypes.Date:
      validators.push(stringDateValidator);
      break;
    case FieldTypes.Birthday:
      validators.push(stringDateValidator);
      const minAge =
        field.getNullableConfigProperty('MinimumAge') ||
        field.properties.MinimumAge;
      if (minAge > 0) {
        validators.push((v: string) => {
          if (!v) return undefined; //Required is handled in another validator
          const date = parseStringDate(v);
          if (!date) return undefined; // valid date is handled in another validator
          const age = differenceInYears(new Date(), date);
          if (age < minAge) {
            return `You must be ${minAge} years or older`;
          }
          return undefined;
        });
      }
      break;
    case FieldTypes.Address:
      if (field.isRequired) {
        validators.push((v: string) => {
          const requiredFields: KeyOf<DynamicFormAddress>[] = [
            'country',
            'city',
            'streetAddress',
            'state',
            'postal_code'
          ];
          const addressFields = Object.keys(toJsonOrEmpty(v));
          if (!requiredFields.every((f) => addressFields.includes(f))) {
            return 'Field is required';
          }
          return undefined;
        });
      }
      break;
    case FieldTypes.Password: //DynamicField validates password rules
    case FieldTypes.Phone:
    case FieldTypes.Country:
    case FieldTypes.Html:
    case FieldTypes.Email: //DynamicField validates email regex
    case FieldTypes.File: //DynamicField validates required + file limit
    default:
      break;
  }
  return validators;
};

const toJsonOrEmpty = (value?: string) => {
  try {
    return value ? JSON.parse(value) : {};
  } catch {
    return {};
  }
};
