import { MutateAction } from '@komo-tech/core/models/Updates';
import { produce } from 'immer';
import { FC, useEffect } from 'react';
import { createWithEqualityFn } from 'zustand/traditional';

import { FCC } from '../../fcc';
import { useConstant } from '../../hooks/useConstant';
import { PortalComponent } from './DomPortal.types';
import { PortalRoot } from './PortalRoot';

type IStore = {
  modals: Record<string, PortalComponent>;
  hiddenComponents: Record<string, PortalComponent>;
};

const useStore = createWithEqualityFn<IStore>(
  () => ({
    modals: {},
    hiddenComponents: {}
  }),
  Object.is
);

export const DomPortalProvider: FCC = ({ children }) => {
  return (
    <>
      <>
        <HideHubSpotPopover />
        {children}
        <ModalsPortalRoot />
        <HiddenPortalRoot />
      </>
    </>
  );
};

type OptionalDomPortalProviderProps = {
  hide?: boolean;
};
export const OptionalDomPortalProvider: FCC<OptionalDomPortalProviderProps> = ({
  hide,
  children
}) => {
  if (hide) {
    return <>{children}</>;
  }

  return <DomPortalProvider>{children}</DomPortalProvider>;
};

export const useModalsShown = () => {
  const modalsShown = useStore((x) => Object.keys(x.modals).length);
  return modalsShown;
};

const HideHubSpotPopover: FC = () => {
  const modalsShown = useModalsShown();

  useEffect(() => {
    //Hack to hide the chat bot behind the modal
    try {
      const hubSpotContainer = document.getElementById(
        'hubspot-messages-iframe-container'
      );
      if (hubSpotContainer) {
        if (modalsShown > 0) {
          hubSpotContainer.style.zIndex = '1';
        } else {
          hubSpotContainer.style.zIndex = '';
        }
      }
    } catch {}
  }, [modalsShown]);

  return null;
};

const ModalsPortalRoot: FC = () => {
  const modals = useStore((x) => x.modals);
  return <PortalRoot components={modals} />;
};

const HiddenPortalRoot: FC = () => {
  const hiddenComponents = useStore((x) => x.hiddenComponents);
  return (
    <PortalRoot
      wrapper={HiddenComponentWrapper}
      components={hiddenComponents}
    />
  );
};

export const usePortalComponentActions = (
  componentSelector: (store: IStore) => Record<string, PortalComponent>
) => {
  const handleMutate = (action: MutateAction<IStore>) =>
    useStore.setState(produce((s) => action(s)));

  return useConstant(() => ({
    show: (key: string, component: PortalComponent) => {
      handleMutate((store) => {
        const components = componentSelector(store);
        components[key] = component;
      });
    },
    hide: (key: string) => {
      handleMutate((store) => {
        const components = componentSelector(store);

        if (!components[key]) {
          return;
        }

        delete components[key];
      });
    }
  }));
};

const HiddenComponentWrapper: FCC = ({ children }) => {
  return (
    <div
      data-hidden-component-wrapper
      style={{
        position: 'fixed',
        opacity: 0,
        zIndex: -1,
        top: '-999999px',
        left: '-999999px'
      }}
    >
      {children}
    </div>
  );
};
