import { Stack } from '@komo-tech/ui/Stack';
import { Text } from '@komo-tech/ui/Text';
import { UiSize } from '@komo-tech/ui/theme/types';
import { useMutation } from '@tanstack/react-query';
import { FC, useState } from 'react';

import { Site } from '@/common/models/site/Site';
import { ForgotPasswordForm } from '@/front/components/auth/ForgotPassword';
import {
  AuthButton,
  resolveAuthButtonProps
} from '@/front/components/auth/Shared';
import { PublicAuthService } from '@/front/data/Auth';

interface Props {
  site: Site;
  returnUrl?: string;
  onForgotPasswordSuccess: () => void;
  onCancel: () => void;
  size?: UiSize;
}

export const AuthDrawerForgotPassword: FC<Props> = ({
  site,
  returnUrl,
  onForgotPasswordSuccess,
  onCancel,
  size
}) => {
  const [recoveryEmail, setRecoveryEmail] = useState(null);

  const { mutateAsync: handleResendAsync, isLoading } = useMutation(() =>
    PublicAuthService.actions.forgotPasswordAsync({
      email: recoveryEmail,
      siteId: site.id.toString(),
      returnUrl: returnUrl
    })
  );

  return (
    <>
      {!recoveryEmail && (
        <Stack>
          <Text my={0}>{site.properties.AuthRecoveryFormText}</Text>
          <ForgotPasswordForm
            siteId={site.id}
            size={size}
            submitButton={resolveAuthButtonProps(site, 'primary', {
              label: 'Get reset link'
            })}
            extraButtons={[
              resolveAuthButtonProps(site, 'secondary', {
                label: 'Back to sign in',
                onClick: onCancel
              })
            ]}
            onSuccess={setRecoveryEmail}
          />
        </Stack>
      )}
      {recoveryEmail && (
        <Stack>
          <Stack gap="0.5rem">
            <Text my={0}>{recoveryEmail}</Text>
            <Text my={0}>
              {site.properties.AuthRecoveryConformationSubText}
            </Text>
          </Stack>
          <Stack>
            <AuthButton
              {...resolveAuthButtonProps(site, 'primary')}
              label="Sign in"
              w="100%"
              size={size}
              onClick={onForgotPasswordSuccess}
              disabled={isLoading}
            />
            <AuthButton
              {...resolveAuthButtonProps(site, 'secondary')}
              w="100%"
              size={size}
              onClick={() => handleResendAsync()}
              label="Re-send email"
              busy={isLoading}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};
