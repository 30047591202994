import { SegmentedControlItem } from '@mantine/core';
import { FieldValues } from 'react-hook-form';

import { IAmFormHookField } from '../HookField';
import {
  FormSegmentedControl,
  FormSegmentedControlProps
} from './FormSegmentedControl';

export interface FormHookFormSegmentedControlProps<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormSegmentedControlProps,
      'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export function FormHookSegmentedControl<
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookFormSegmentedControlProps<TFieldValues>) {
  const { hook, onBlur, onChange, data, ...rest } = props;
  return (
    <FormSegmentedControl
      {...rest}
      data={data}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(v) => {
        hook.setValue(hook.field.name, v as any);
        onChange?.(v);
      }}
      value={
        hook.field.value ||
        (data?.[0] as SegmentedControlItem)?.value ||
        (data?.[0] as string)
      }
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
