import React, { MutableRefObject, useContext } from 'react';

import { ModalSizes } from './ModalSizes';

export interface ModalState {
  size: ModalSizes;
  contentRef: MutableRefObject<HTMLDivElement>;
  overlayRef: MutableRefObject<HTMLDivElement>;
}

export const ModalStateContext = React.createContext<ModalState>(null);

export const useModalState = (): ModalState => {
  const context = useContext(ModalStateContext);
  if (!context) {
    throw new Error('Cant use outside context');
  }
  return context;
};
