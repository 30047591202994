'use client';
import {
  Flex as MantineFlex,
  FlexFactory as MantineFlexFactory,
  FlexProps as MantineFlexProps,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';

export type FlexProps = MantineFlexProps;
type FlexReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantineFlexFactory>
>;
export const Flex = MantineFlex as FlexReturnType;
