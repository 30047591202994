import { Guid } from '@komo-tech/core/models/Guid';

import { Page } from '@/common/models/pages/Page';
import { PublicLiveApi } from '@/front/data/PublicApi';

export const PublicPageActions = {
  getHomepageAsync: async (siteId: Guid) =>
    new Page(await PublicLiveApi.getAsync(`sites/${siteId}/pages/home`)),
  getBySlugAsync: async (slug: string, siteId: Guid) =>
    new Page(await PublicLiveApi.getAsync(`sites/${siteId}/pages/slug/${slug}`))
};
