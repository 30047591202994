export type LabelSizeVariant = 'Normal' | 'Small' | 'Large';

/**
 * @deprecated use FormInputLabel instead
 */
export class LabelStyler {
  /**
   * @deprecated use FormInputLabel instead
   */
  static fontSize(sizeVariant?: LabelSizeVariant) {
    switch (sizeVariant) {
      case 'Small':
        return '12px';
      case 'Large':
        return '16px';
      case 'Normal':
      default:
        return '14px';
    }
  }

  static marginBottom = '4px';
}
