import { nanoid } from '@komo-tech/core/utils/nanoid';
import { SessionStorageHelper } from '@komo-tech/core/utils/sessionStorage';

/**
 * Identifies the tab in the browser.
 */
const tabIdKey = 'komo:tid';

export const SessionV2SessionStorageHelper = {
  getTabId: () => SessionStorageHelper.getOrCreateItem(tabIdKey, () => nanoid())
};
