import { Guid } from '@komo-tech/core/models/Guid';
import { orderByAscending } from '@komo-tech/core/models/IHasOrder';
import { nameOf } from '@komo-tech/core/utils/type';
import { FormHookCheckbox } from '@komo-tech/ui/Form/Checkbox';
import { FormHookEmailInput } from '@komo-tech/ui/Form/EmailInput';
import { FormHook } from '@komo-tech/ui/Form/Hook';
import { FormHookField } from '@komo-tech/ui/Form/HookField';
import { FormHookPasswordInput } from '@komo-tech/ui/Form/PasswordInput';
import {
  emailValidator,
  matchFieldValidator,
  maxCharLengthValidator,
  minCharLengthValidator,
  passwordValidator
} from '@komo-tech/ui/Form/Validators';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { useOnMount } from '@komo-tech/ui/hooks/useOnMount';
import { UiSize } from '@komo-tech/ui/theme/types';
import { useToaster } from '@komo-tech/ui/Toast/useToaster';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

import {
  DynamicFormRendererField,
  DynamicFormRendererHeader
} from '@/common/components/Form/DynamicForm/Renderer';
import { Form } from '@/common/models/form/Form';
import { FormField } from '@/common/models/form/FormField';
import { Site } from '@/common/models/site/Site';
import { PublicAuthService, UserPreSignUpResult } from '@/front/data/Auth';

import { UserAccountsSignUpTransientData } from '../../shared/Competition/CompetitionForm/_UserAccounts/_SignUp';
import { AuthButton, AuthButtonGroup, AuthButtonProps } from '../Shared';
import { useSignUpFormHelper } from './SignUpFormHelper';

interface FormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  termsAndConditions?: boolean;
}

interface Props {
  site: Site;
  triggerFormId?: Guid;
  form: Form;
  onOptinClick?: () => void;
  onSuccess: () => void;
  size?: UiSize;
  submitButton: AuthButtonProps;
  extraButtons?: AuthButtonProps[];
  title?: string;
  subTitle?: string;
  formValues?: any;
  hidePassedInValues?: boolean;
  passedInFieldsToShow?: string[];
  setTransientData?: (values: UserAccountsSignUpTransientData) => void;
  withIcons?: boolean;
}

export const SignUpForm: FC<Props> = ({
  form,
  title,
  subTitle,
  site,
  triggerFormId,
  onSuccess,
  submitButton,
  size,
  extraButtons,
  formValues,
  hidePassedInValues,
  passedInFieldsToShow = [],
  setTransientData,
  withIcons = false
}) => {
  const toaster = useToaster();
  const formRef = useRef<UseFormReturn<FormValues>>();

  //Register hidden fields if any
  useOnMount(() => {
    if (hidePassedInValues && formValues) {
      const keysToIgnore = [
        nameOf<FormValues>('email'),
        nameOf<FormValues>('password'),
        nameOf<FormValues>('passwordConfirm'),
        nameOf<FormValues>('termsAndConditions'),
        ...(passedInFieldsToShow || [])
      ];
      Object.keys(formValues).forEach((key) => {
        if (!keysToIgnore.some((x) => x === key)) {
          methods.register(key as any, {});
        }
      });
    }
  });

  const [emailError, setEmailError] = useState(undefined);

  const {
    mutateAsync: handlePreSignUpAsync,
    isLoading,
    data
  } = useMutation(
    async (values: FormValues) => {
      return PublicAuthService.actions.preSignUpAsync({
        email: values.email,
        siteId: site.id,
        triggerFormId,
        fields: helper.sanitiseValuesForSubmit(values)
      });
    },
    {
      onSuccess: ({ success, result, errorMessage, formFieldErrors }) => {
        if (!success) {
          if (
            result !== UserPreSignUpResult.EmailInvalid &&
            result !== UserPreSignUpResult.UserExists
          ) {
            toaster.error(
              errorMessage || 'An error occurred signing up, please try again'
            );

            if (formFieldErrors?.length) {
              for (let i = 0; i < formFieldErrors.length; i++) {
                const formFieldError = formFieldErrors[i];
                if (formFieldError.fieldName === 'email') {
                  setEmailError(formFieldError.errors.join('. '));
                } else {
                  (formFieldError.errors || []).forEach((error) =>
                    methods.setError(`root.${formFieldError.fieldName}`, {
                      type: 'temp',
                      message: error
                    })
                  );
                }
              }
            }
          } else if (result === UserPreSignUpResult.UserExists) {
            setEmailError(
              errorMessage || 'User with that email already exists'
            );
          } else {
            setEmailError(errorMessage || 'Email is not valid');
          }
          return;
        }

        onSuccess();
      },
      onError: (err) => {
        console.error(err);
        toaster.error('An error occurred signing up, please try again');
      }
    }
  );

  const helper = useSignUpFormHelper(
    form.fields,
    formValues,
    hidePassedInValues,
    passedInFieldsToShow
  );

  const [fields, defaultValues] = useMemo(() => {
    const fields = helper.resolveFields();
    const initial: any = {};
    fields.forEach((f) => {
      const defaultVal = f.getDefaultValue();
      if (!!defaultVal) initial[f.name] = defaultVal;
    });
    return [fields, initial];
  }, [helper]);

  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      ...defaultValues,
      email: '',
      password: '',
      passwordConfirm: '',
      termsAndConditions: false,
      ...formValues
    }
  });

  const handleSubmitAsync = useEvent((values: FormValues) => {
    const submitValues = { ...values };
    const email = submitValues.email;
    const password = submitValues.password;
    delete submitValues.email;
    delete submitValues.password;
    delete submitValues.passwordConfirm;
    setTransientData({
      email: email,
      password: password,
      fields: helper.sanitiseValuesForSubmit(submitValues)
    });
    handlePreSignUpAsync({ email, ...submitValues });
  });

  const privacyLabel = site.properties.AuthPrivacyLabel;
  const hasPrivacyCheckbox = !!privacyLabel;

  const usePasswords = !site.properties.AuthMagicLinkEnabled;

  useEffect(() => {
    if (emailError) {
      formRef.current?.trigger?.('email', { shouldFocus: true });
    }
  }, [emailError]);

  return (
    <FormHook<FormValues>
      defaultValues={{
        ...defaultValues,
        email: '',
        password: '',
        passwordConfirm: '',
        termsAndConditions: false,
        ...formValues
      }}
      onSubmit={handleSubmitAsync}
      onInit={(f) => (formRef.current = f)}
    >
      <DynamicFormRendererHeader title={title} subTitle={subTitle} />
      <FormHookField<FormValues>
        name="email"
        contextData={emailError}
        required={{ enabled: true }}
        disabled={helper.shouldEmailBeDisabled() || isLoading}
        validators={[
          () => emailError,
          emailValidator(),
          maxCharLengthValidator(200)
        ]}
      >
        {(h) => (
          <FormHookEmailInput
            hook={h}
            label="Email"
            size={size}
            withIcon={withIcons}
            onChange={() => {
              if (emailError) {
                setEmailError(undefined);
              }
            }}
          />
        )}
      </FormHookField>
      {fields.sort(orderByAscending).map((field) => (
        <DynamicFormRendererField
          disabled={isLoading}
          key={field.id.toString()}
          size={size}
          formField={new FormField(field)}
        />
      ))}
      {usePasswords && (
        <>
          <FormHookField<FormValues>
            name="password"
            disabled={isLoading}
            required={{ enabled: true }}
            validators={[minCharLengthValidator(8), passwordValidator()]}
          >
            {(h) => (
              <FormHookPasswordInput
                autoComplete="new-password"
                label="Password"
                withIcon={withIcons}
                size={size}
                hook={h}
              />
            )}
          </FormHookField>

          <FormHookField<FormValues>
            name="passwordConfirm"
            disabled={isLoading}
            required={{ enabled: true }}
            validators={[
              matchFieldValidator<FormValues>({ matchField: 'password' })
            ]}
          >
            {(h) => (
              <FormHookPasswordInput
                autoComplete="new-password"
                label="Confirm password"
                withIcon={withIcons}
                size={size}
                hook={h}
              />
            )}
          </FormHookField>
        </>
      )}

      {hasPrivacyCheckbox && (
        <FormHookField<FormValues>
          name="termsAndConditions"
          disabled={isLoading}
          required={{ enabled: true, variant: 'boolean' }}
        >
          {(h) => (
            <FormHookCheckbox
              label={{ type: 'Html', html: privacyLabel }}
              size={size}
              withAnimation
              hook={h}
            />
          )}
        </FormHookField>
      )}
      <AuthButton
        {...submitButton}
        busy={isLoading}
        type="submit"
        label="Sign up"
        size={size}
      />
      <AuthButtonGroup
        buttons={extraButtons}
        disabled={isLoading}
        size={size}
      />
    </FormHook>
  );
};
