import { ReactNode } from 'react';

import { useUncontrolled } from '../../hooks/useUncontrolled';
import { Tabs, TabsProps } from './Tabs';

export interface TabRendererDefinition<TValue extends string = string> {
  label: ReactNode;
  value: TValue;
}

export interface TabsRendererProps<TValue extends string = string>
  extends Omit<TabsProps<TValue>, 'children'> {
  value?: TValue;
  grow?: boolean;
  tabs: TabRendererDefinition<TValue>[];
  children?: (active: TValue) => ReactNode;
}

export function TabsRenderer<TValue extends string = string>({
  tabs = [],
  defaultValue,
  value: valueProp,
  children,
  onChange,
  grow,
  ...rest
}: TabsRendererProps<TValue>) {
  const [value, handleChange] = useUncontrolled<TValue>({
    value: valueProp,
    defaultValue,
    onChange
  });

  return (
    <Tabs<TValue> value={value} onChange={handleChange} {...rest}>
      <Tabs.List grow={grow}>
        {tabs.map((tab) => (
          <Tabs.Tab key={tab.value.toString()} value={tab.value.toString()}>
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {children?.(value)}
    </Tabs>
  );
}
