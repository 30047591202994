import { FormHookTextArea } from '@komo-tech/ui/Form/TextArea';
import { FieldValues } from 'react-hook-form';

import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  TextAreaDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormTextAreaField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  contextData,
  disabled,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => (
        <FormHookTextArea
          hook={h}
          {...((field.data.options as TextAreaDynamicFieldOptions) || {})}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
