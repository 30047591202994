import { isLocalhost, isServer } from '@komo-tech/core/utils/browser';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';

import { SignalRLogger } from '@/common/data/signal-r/SignalRLogger';

import { InfiniteReconnectPolicy } from './InfiniteReconnectPolicy';

export interface HubFactoryOptions {
  url: string;
  onClose?: (error: Error | undefined) => void;
  onReconnecting?: (error: Error | undefined) => void;
  onReconnected?: (connectionId: string | undefined) => void;
}

const connections = new Map();

const buildOptions = (options: string | HubFactoryOptions) => {
  const opts = typeof options === 'string' ? { url: options } : options;

  // (localhost only): nextjs rewrites dont handle websocket scheme, so we specify full hub URL here
  if (!isServer() && isLocalhost() && !opts.url.startsWith('http')) {
    return { ...opts, url: `http://localhost:5002${opts.url}` };
  }

  return opts;
};

export const createHubConnectionAsync = async (
  options: string | HubFactoryOptions
): Promise<HubConnection> => {
  const opts = buildOptions(options);

  if (connections.has(opts.url)) return connections.get(opts.url);

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(opts.url)
    .configureLogging(new SignalRLogger({ minLevel: signalR.LogLevel.Warning }))
    .withAutomaticReconnect(new InfiniteReconnectPolicy())
    .build();

  if (opts.onClose) connection.onclose(opts.onClose);
  if (opts.onReconnecting) connection.onreconnecting(opts.onReconnecting);
  if (opts.onReconnected) connection.onreconnected(opts.onReconnected);

  connections.set(opts.url, connection);

  await connection.start().catch(console.error);

  return connection;
};
