import { tryGetZonedDate } from '@komo-tech/core/utils/date';
import {
  DateTimePicker as MantineDateTimePicker,
  DateTimePickerProps as MantineDateTimePickerProps
} from '@mantine/dates';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import isNil from 'lodash/isNil';
import { forwardRef, useMemo } from 'react';

import { useUncontrolled } from '../../../hooks/useUncontrolled';
import { ClockIcon } from '../../Icons/ClockIcon';
import { FormInputWrapper, IAmFormInputRenderProps } from '../InputWrapper';

export interface FormDateTimePickerV2Props
  extends Omit<
      MantineDateTimePickerProps,
      'labelProps' | 'descriptionProps' | 'withAsterisk' | 'getDayProps'
    >,
    Omit<IAmFormInputRenderProps<HTMLButtonElement>, 'size'> {
  withinPortal?: boolean;
  /**
   * Provide a time zone ID to use.
   * This will cause the saved value to be in UTC, but the displayed value will be in the given time zone.
   */
  timeZoneId?: string;
  withIcon?: boolean;
}

export const FormDateTimePicker = forwardRef<
  HTMLButtonElement,
  FormDateTimePickerV2Props
>(
  (
    {
      value: valueProp,
      defaultValue,
      required,
      onFocus,
      selectAllTextOnFocus,
      autoFocus,
      descriptionProps,
      inputContainer,
      showSkeleton,
      labelProps,
      error,
      hideErrorMessage,
      size,
      withinPortal: withinPortalProp,
      onChange,
      timeZoneId,
      withIcon = false,
      leftSection,
      ...rest
    },
    ref
  ) => {
    const withinPortal = !isNil(withinPortalProp) ? withinPortalProp : true;

    const popoverProps = withinPortal
      ? { withinPortal: true, zIndex: 1100, ...rest.popoverProps }
      : { withinPortal: false, ...rest.popoverProps };

    const [value, handleChange] = useUncontrolled({
      value: valueProp,
      defaultValue,
      finalValue: null,
      onChange
    });

    const pickerValue = useMemo(() => {
      if (!timeZoneId) {
        return value;
      }

      return tryGetZonedDate(value, timeZoneId);
    }, [value, timeZoneId]);

    const handlePickerChange = (v: Date) => {
      if (!v) {
        return handleChange(null);
      }

      // zero out seconds if the picker isn't showing them, otherwise Mantine will give us random seconds.
      if (!rest.withSeconds) {
        v.setSeconds(0);
      }

      // always zero out ms, Mantine doesn't do it in any scenario.
      v.setMilliseconds(0);

      if (!timeZoneId) {
        return handleChange(v);
      }

      return handleChange(zonedTimeToUtc(v, timeZoneId));
    };

    return (
      <FormInputWrapper<HTMLButtonElement>
        ref={ref}
        autoFocus={autoFocus}
        onFocus={onFocus}
        selectAllTextOnFocus={selectAllTextOnFocus}
        error={error}
        hideErrorMessage={hideErrorMessage}
        labelProps={labelProps}
        descriptionProps={descriptionProps}
        inputContainer={inputContainer}
        showSkeleton={showSkeleton}
        size={size}
      >
        {({ size: _, ...iProps }, ref) => (
          <MantineDateTimePicker
            ref={ref}
            value={pickerValue}
            onChange={handlePickerChange}
            withAsterisk={required}
            {...rest}
            {...iProps}
            size={size}
            popoverProps={popoverProps}
            leftSection={leftSection || (withIcon ? <ClockIcon /> : undefined)}
          />
        )}
      </FormInputWrapper>
    );
  }
);
