import { FormHookSegmentedControl } from '@komo-tech/ui/Form/SegmentedControl';
import { FieldValues } from 'react-hook-form';

import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  SegmentedControlDynamicFieldOptions
} from '@/common/components/Form/DynamicForm';
import { DynamicFormHookField } from '@/common/components/Form/DynamicForm/_Fields/__DynamicFormHookField';

export function DynamicFormSegmentedControlField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => (
        <FormHookSegmentedControl
          hook={h}
          {...(field.data.options as SegmentedControlDynamicFieldOptions)}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
