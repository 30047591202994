export enum SocialNetworks {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  Linkedin = 'Linkedin',
  Youtube = 'Youtube',
  Website = 'Website',
  TikTok = 'TikTok',
  Snapchat = 'Snapchat',
  Reddit = 'Reddit',
  Pinterest = 'Pinterest'
}
