import isNil from 'lodash/isNil';
import { FC } from 'react';

import { FormHookFieldProps } from '../HookField';
import {
  FormTimeZonePicker,
  FormTimeZonePickerProps
} from './FormTimeZonePicker';

export interface FormHookTimeZonePickerProps
  extends Omit<
    FormTimeZonePickerProps,
    'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
  > {
  hook: FormHookFieldProps<any>;
}

export const FormHookTimeZonePicker: FC<FormHookTimeZonePickerProps> = ({
  hook,
  onBlur,
  onChange,
  ...rest
}) => {
  return (
    <FormTimeZonePicker
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e, o) => {
        hook.field.onChange(e);
        onChange?.(e, o);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
};
