import { Title, TitleProps } from '@komo-tech/ui/Title';
import cx from 'clsx';
import { forwardRef, ReactNode } from 'react';

import classes from './CardHeader.module.css';

export type CardHeaderVariant = 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
interface Props extends TitleProps {
  variant?: CardHeaderVariant;
  children?: ReactNode;
}

export const CardHeader = forwardRef<HTMLDivElement, Props>(
  ({ children, variant, style, className, c, ...rest }, ref) => {
    const resolveOrder = () => {
      switch (variant) {
        case 'h2':
          return 2;
        case 'h4':
          return 4;
        case 'h5':
          return 5;
        case 'h6':
          return 6;
        case 'h3':
        default:
          return 3;
      }
    };
    return (
      <Title
        ref={ref}
        order={resolveOrder()}
        style={{ whiteSpace: 'pre-line', ...style }}
        className={cx(classes.root, className)}
        data-variant={variant}
        c={c === 'dimmed' ? 'var(--komo-color-dimmed)' : c}
        {...rest}
      >
        {children}
      </Title>
    );
  }
);
