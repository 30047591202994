import { Stack } from '@komo-tech/ui/Stack';
import { Text } from '@komo-tech/ui/Text';
import { FC } from 'react';

import { Image } from '@/common/components/Image';
import { FrontBadgeView } from '@/front/models/FrontBadgeView';

import { useFrontSite } from '../../../Store';
import classes from './BadgeTile.module.css';

interface Props {
  badge: FrontBadgeView;
}

export const BadgeTile: FC<Props> = ({ badge }) => {
  const site = useFrontSite((x) => x.site);
  const { earned, styling } = badge.isEarned();
  const [BadgeBorderColor, BadgeTextColor] = site.resolveBadgeColor();
  const [LockedBadgeBorderColor, LockedBadgeTextColor] =
    site.resolveLockedBadgeColor();
  return (
    <Stack
      data-active={earned || badge.disableGrayscale}
      __vars={{
        '--badge-tile-border-color': earned
          ? BadgeBorderColor
          : LockedBadgeBorderColor,
        '--badge-tile-color': earned ? BadgeTextColor : LockedBadgeTextColor
      }}
      style={
        earned
          ? {
              borderWidth: 2
            }
          : undefined
      }
      className={classes.root}
    >
      <Image
        style={{
          maxHeight: 75
        }}
        objectFit="contain"
        image={styling.image}
      />
      <Text ta="center" fz={'lg'} fw={700}>
        {styling.title}
      </Text>
      <Text ta="center" fz={'sm'} fw={400}>
        {styling.description}
      </Text>
    </Stack>
  );
};
