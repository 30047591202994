import { Center } from '../../Center';
import { CameraIcon } from '../../Icons/CameraIcon';
import { Stack } from '../../Stack';
import { Text } from '../../Text';
import classes from './QrCodeReader.module.css';

export interface QrCodeReaderErrorProps {
  message?: string;
  withBracketsBorder?: boolean;
}

export const QrCodeReaderError = ({
  message,
  withBracketsBorder = true
}: QrCodeReaderErrorProps) => {
  return (
    <Center data-with-brackets={withBracketsBorder} className={classes.error}>
      <Stack align="center">
        <CameraIcon size="xl" />
        <Text ta="center">
          {message || 'Open your camera and scan the QR code to proceed.'}
        </Text>
      </Stack>
    </Center>
  );
};
