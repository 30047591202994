import isNil from 'lodash/isNil';
import { FieldValues } from 'react-hook-form';

import { IAmFormHookField } from '../HookField/FormHookField';
import { FormPinInput, FormPinInputProps } from './FormPinInput';

export interface FormHookPinInputProps<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<FormPinInputProps, 'labelProps'> {}

export function FormHookPinInput<
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookPinInputProps<TFieldValues>) {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormPinInput
      {...rest}
      disabled={hook.disabled}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(v) => {
        hook.setValue(hook.field.name, v as any, {
          shouldValidate: true,
          shouldDirty: true
        });
        onChange?.(v);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError}
    />
  );
}
