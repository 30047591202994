import type {
  ListItemProps as MantineListItemProps,
  ListProps as MantineListProps
} from '@mantine/core';
import {
  List as MantineList,
  ListItem as MantineListItem
} from '@mantine/core';

export const ListV2 = MantineList;
export type ListV2Props = MantineListProps;
export const ListV2Item = MantineListItem;
export type ListV2ItemProps = MantineListItemProps;
