import {
  Drawer as MantineDrawer,
  DrawerProps as MantineDrawerProps
} from '@mantine/core';

/**
 * @see https://mantine.dev/core/drawer/
 */
export const Drawer = MantineDrawer;
export type DrawerProps = MantineDrawerProps;
