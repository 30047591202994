import Head from 'next/head';
import { FC, memo } from 'react';

import { useFrontSite } from './Store';
import Script from 'next/script';

type ScriptStrategy = 'afterInteractive' | 'lazyOnload' | 'beforeInteractive';

export type ScriptLocations = 'Head' | 'Body';

interface Props {
  location: ScriptLocations;
}

export const SiteScript: FC<Props> = memo(({ location }) => {
  const bodyScript = useFrontSite((x) => x.site.properties.ScriptBody);
  const headScript = useFrontSite((x) => x.site.properties.ScriptHead);

  const script = location === 'Head' ? headScript : bodyScript;

  if (!script) {
    return null;
  }

  if (location == 'Head') {
    // inline scripts using the Script component cannot use the 'beforeInteractive'
    // strategy, so we need to manually inject an inline script into the Head.
    // see https://nextjs.org/docs/basic-features/script#inline-scripts
    return (
      <Head>
        <script
          id={getScriptId(location)}
          dangerouslySetInnerHTML={{
            __html: script
          }}
        />
      </Head>
    );
  }

  return (
    <Script
      id={getScriptId(location)}
      strategy={getScriptStrategy(location)}
      dangerouslySetInnerHTML={{
        __html: script
      }}
    />
  );
});

const getScriptId = (location: ScriptLocations) => {
  return location === 'Head' ? 'site-script-head' : 'site-script-body';
};

const getScriptStrategy = (location: ScriptLocations): ScriptStrategy => {
  return location === 'Head' ? undefined : 'afterInteractive';
};

if (process.env.NODE_ENV !== 'production') {
  SiteScript.displayName = 'SiteScript';
}
