import type {
  MenuFactory as MantineMenuFactory,
  MenuItemProps as MantineMenuItemProps,
  MenuProps as MantineMenuProps,
  PolymorphicFactory as PolymorphicMenuItemFactory
} from '@mantine/core';
import {
  factory as mantineFactory,
  Menu as MantineMenu,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';
import isNil from 'lodash/isNil';

/**
 * @see https://mantine.dev/core/menu/
 */
export type MenuProps = MantineMenuProps;

type MenuReturnType = ReturnType<
  typeof mantineFactory<
    MantineMenuFactory & {
      staticComponents: {
        Divider: typeof MantineMenu.Divider;
        Item: typeof MantineMenu.Item;
        Label: typeof MantineMenu.Label;
        Dropdown: typeof MantineMenu.Dropdown;
        Target: typeof MantineMenu.Target;
      };
    }
  >
>;

export const Menu = (({ children, ...rest }: MenuProps) => {
  if (isNil(rest.withinPortal)) {
    rest.withinPortal = true;
  }
  if (rest.withinPortal && isNil(rest.zIndex)) {
    //fix for showing tooltip above modals
    rest.zIndex = 1100;
  }

  return <MantineMenu {...rest}>{children}</MantineMenu>;
}) as any as MenuReturnType;

Menu.Divider = MantineMenu.Divider;
Menu.Item = MantineMenu.Item;
Menu.Label = MantineMenu.Label;
Menu.Dropdown = MantineMenu.Dropdown;
Menu.Target = MantineMenu.Target;

type MenuItemReturnType = ReturnType<
  typeof mantinePolymorphicFactory<
    PolymorphicMenuItemFactory<{
      props: MenuItemProps;
      defaultRef: HTMLButtonElement;
      defaultComponent: 'button';
      stylesNames: 'item' | 'itemLabel' | 'itemSection';
      compound: true;
    }>
  >
>;

export const MenuItem = MantineMenu.Item as MenuItemReturnType;
export type MenuItemProps = MantineMenuItemProps;
export const MenuTarget = MantineMenu.Target;
export const MenuLabel = MantineMenu.Label;
export const MenuDivider = MantineMenu.Divider;
export const MenuDropdown = MantineMenu.Dropdown;
