import { useWindowSize } from '@komo-tech/ui/hooks/useWindowSize';
import { ThemeSpacing, UiSize } from '@komo-tech/ui/theme/types';

export const DynamicFormHeaderSpacing: string = '1rem';
export const DynamicFormFieldSize: UiSize = 'md';

export const useDynamicFormRendererSizes = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 750;

  const headerSpacing: ThemeSpacing = '0.5rem';
  const contentPadding = isMobile ? 'md' : '1.5rem';
  const fieldSize = DynamicFormFieldSize;
  const headerFieldSpacing = DynamicFormHeaderSpacing;

  return {
    isMobile,
    headerSpacing,
    contentPadding,
    fieldSize,
    headerFieldSpacing
  };
};
