const lottieUrl = (name: string) => {
  return `/assets/lottie-animations/${name}.json`;
};

export const LottieAnimations = {
  check: lottieUrl('check'),
  check2: lottieUrl('check2'),
  check3: lottieUrl('check3'),
  scan: lottieUrl('scan')
};
