import { PublicLiveApi } from '@/front/data/PublicApi';

export const getLocationLookupCacheKey = () => ['LocationLookup'];

export class LocationLookup {
  client: string;
  country: string;
  countryCode: string;
  region: string;
  city: string;
  timezone: string;
  proxy: boolean = false;
  mobile: boolean = false;

  constructor(props?: Partial<LocationLookup>) {
    props = props || {};
    Object.assign(this, props);
  }
}

export class PublicLocationService {
  public static lookupLocation = async () =>
    new LocationLookup(await PublicLiveApi.getAsync(`location/lookup`));
}
