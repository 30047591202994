import { HubConnection } from '@microsoft/signalr';
import { useEffect } from 'react';

import { HubConnectionMethodHandler } from '@/common/data/signal-r/types';

interface UseConnectionHandlersProps {
  handlers: HubConnectionMethodHandler[];
  connection?: HubConnection;
}

export const useConnectionHandlers = ({
  handlers,
  connection
}: UseConnectionHandlersProps) => {
  useEffect(() => {
    if (!connection) {
      return;
    }

    handlers.forEach((handler) =>
      connection.on(handler.methodName, handler.handler)
    );

    // Cleanup
    return () => {
      if (connection)
        handlers.forEach((handler) =>
          connection?.off(handler.methodName, handler.handler)
        );
    };
  }, [handlers.map((h) => h.id).join(','), connection]);
};
