export const stripHtml = (value: string) => value?.replace(/(<([^>]+)>)/gi, '');

export const asHtmlWithExternalLinks = (html: string) => {
  return html.replace(
    /<(a)([^>]+)>/gi,
    '<$1 target="_blank" rel="noopener noreferrer"$2>'
  );
};

//https://stackoverflow.com/questions/1912501/unescape-html-entities-in-javascript
export const htmlDecode = (input: string) => {
  if (!input) return input;
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

export const decodeURIComponentOrDefault = (
  encodedUriComponent: string | undefined
) =>
  encodedUriComponent !== undefined
    ? decodeURIComponent(encodedUriComponent)
    : undefined;

export const selfOrParentIsMatch = (
  element: HTMLElement,
  matchPredicate: (element: HTMLElement) => boolean
): boolean => {
  const isMatch = matchPredicate(element);
  if (!isMatch && element?.parentElement) {
    return selfOrParentIsMatch(element.parentElement, matchPredicate);
  }
  return isMatch;
};

export const isInViewport = (elParent: HTMLDivElement, el: HTMLDivElement) => {
  const parentOffsetTop = elParent.scrollTop;
  const offsetHeight = el.offsetTop + el.offsetHeight;
  return parentOffsetTop > offsetHeight;
};
