import { useSignalRContextSelector } from '../SignalRProvider';
import {
  sanitiseSiteHubEventPayload,
  SiteHubMethods,
  SiteHubMethodSignatures
} from './_types';

export const useSiteHubSubscribe = () => {
  const subscribe = useSignalRContextSelector((s) => s.subscribe);

  function typedSubscribe<T extends SiteHubMethods>(
    hubMethod: T,
    callback: SiteHubMethodSignatures[T]
  ) {
    return subscribe(hubMethod, (e) =>
      callback(sanitiseSiteHubEventPayload(hubMethod, e))
    );
  }

  return typedSubscribe;
};
