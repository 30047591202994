import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { Box } from '@komo-tech/ui/Box';
import { FC } from 'react';

import { Image } from '@/common/components/Image';

interface Props {
  image?: ImageDataModel;
}

export const DynamicFormRendererBanner: FC<Props> = ({ image }) => {
  if (!image?.url) {
    return null;
  }

  return (
    <Box mih="3.5rem" pos={'relative'}>
      <div>
        <Image display="block" image={image} />
      </div>
    </Box>
  );
};
