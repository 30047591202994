'use client';
import type { InputBaseProps as MantineInputBaseProps } from '@mantine/core';
import {
  InputBase as MantineInputBase,
  InputBaseFactory as MantineInputBaseFactory,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';

export type FormInputBaseProps = MantineInputBaseProps;
type _ReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantineInputBaseFactory>
>;
/**
 * @see https://mantine.dev/core/input-base/
 */
export const FormInputBase = MantineInputBase as _ReturnType;
