import React, { FC, memo } from 'react';

import { defaultSiteFont } from '@/common/constants/defaultSiteFont';

import { useFrontSite } from './Store';

export const SiteFontHeadStyle: FC = memo(() => {
  const font = useFrontSite((x) => x.site.properties.Font);
  if (!font) {
    return null;
  }

  return (
    <style global jsx>
      {`
        body {
          font-family: ${font}, ${defaultSiteFont};
        }
      `}
    </style>
  );
});
