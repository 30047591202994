'use client';
import { BoxProps as MantineBoxProps } from '@mantine/core';
import { forwardRef, ReactNode } from 'react';

import { FCC } from '../../../fcc';
import { Box, BoxProps } from '../../Box';
import classes from './FormGroup.module.css';

export interface FormGroupProps extends BoxProps, MantineBoxProps {
  inline?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  id?: string;
  children?: ReactNode;
}

export const FormGroup = forwardRef<HTMLDivElement, FormGroupProps>(
  ({ children, display, inline, fullWidth, style, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        display={display || 'flex'}
        style={{
          flexDirection: inline ? 'row' : 'column',
          boxSizing: 'border-box',
          ...style
        }}
        w={fullWidth ? '100%' : undefined}
        pos={'relative'}
        mb={'1rem'}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

export const StyledFormCheckGroup: FCC<FormGroupProps> = ({
  children,
  disabled
}) => {
  return (
    <FormGroup
      opacity={disabled ? 0.5 : 1}
      mb={'0.5rem'}
      className={classes.checkboxGroup}
    >
      {children}
    </FormGroup>
  );
};
