import isString from 'lodash/isString';
import { forwardRef } from 'react';

import { useUncontrolled } from '../../../hooks/useUncontrolled';
import { CloseIcon } from '../../Icons/CloseIcon';
import { SearchIcon } from '../../Icons/SearchIcon';
import { Spinner } from '../../Spinner';
import { FormTextInput, FormTextInputProps } from './FormTextInput';

export interface FormSearchInputProps
  extends Omit<
    FormTextInputProps,
    'icon' | 'inputMode' | 'autoComplete' | 'type'
  > {
  withIcon?: boolean;
  withClear?: boolean;
  isSearching?: boolean;
}

export const FormSearchInput = forwardRef<
  HTMLInputElement,
  FormSearchInputProps
>(
  (
    {
      defaultValue,
      value: valueProp,
      onChange,
      sanitiseValue,
      withIcon = true,
      leftSection,
      isSearching = false,
      rightSection,
      withClear = false,
      ...rest
    },
    ref
  ) => {
    const [value, handleChange] = useUncontrolled<
      string,
      React.ChangeEvent<HTMLInputElement>
    >({
      value: valueProp,
      defaultValue,
      finalValue: null,
      resolveValue: (e) => (isString(e) ? e : e.target.value),
      sanitiseValue,
      onChange
    });

    const hasRightSection =
      !!rightSection || isSearching || (withClear && !!value);

    return (
      <FormTextInput
        ref={ref}
        {...rest}
        value={value}
        onChange={handleChange}
        type="search"
        inputMode="search"
        leftSection={(leftSection ?? withIcon) ? <SearchIcon /> : undefined}
        rightSection={
          hasRightSection ? (
            <_RightSection
              onClear={() => handleChange('' as any)}
              isSearching={isSearching}
              rightSection={rightSection}
              value={value}
              withClear={withClear}
            />
          ) : undefined
        }
      />
    );
  }
);

interface RightSectionProps
  extends Pick<
    FormSearchInputProps,
    'rightSection' | 'isSearching' | 'withClear' | 'value'
  > {
  onClear: () => void;
}
const _RightSection = ({
  isSearching,
  rightSection,
  withClear,
  value,
  onClear
}: RightSectionProps) => {
  if (rightSection) {
    return rightSection;
  }

  if (isSearching) {
    return <Spinner size="1rem" />;
  }

  if (withClear && !!value) {
    return <CloseIcon onClick={onClear} />;
  }

  return null;
};
