import { createStore, useStore } from 'zustand';

import { IFrontPageStore } from './_types';

export const FrontPageStore = createStore<IFrontPageStore>(() => ({
  page: null,
  isSingleCardPage: false
}));

export function useFrontPage<T = unknown>(
  selector: (state: IFrontPageStore) => T
) {
  return useStore(FrontPageStore, selector);
}
