import { ColorRGBA } from '@komo-tech/core/models/ColorRGBA';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asNumber } from '@komo-tech/core/utils/number';

const defaults = {
  bgColor: '#EFEFF4',
  headerLogoHeight: 50
};

export class PageProperties {
  // Brand
  BrandImageUrl?: string;

  get BrandImage() {
    return ImageDataModel.fromJsonOrUrl(this.BrandImageUrl);
  }

  AccentTextColor?: string;
  AccentBackgroundColor?: string;

  //Cards
  CardShellBg?: string;
  CardShellBd?: string;
  CardShellC?: string;

  // Header
  HeaderEnabled: boolean;
  HeaderLogoEnabled: boolean;
  HeaderLogoHeight?: number;
  HeaderBackgroundColor?: string;
  HeaderTextColor?: string;
  HeaderActiveTextColor?: string;

  // Cover
  BillboardEnabled: boolean;
  BillboardImageUrl?: string;

  get BillboardImage() {
    return ImageDataModel.fromJsonOrUrl(this.BillboardImageUrl);
  }

  // Footer
  FooterEnabled: boolean;
  FooterLogoEnabled: boolean;
  FooterTextColor?: string;
  FooterLinkColor?: string;
  FooterBrandImageUrl?: string;

  get FooterBrandImage() {
    return ImageDataModel.fromJsonOrUrl(this.FooterBrandImageUrl);
  }

  FooterBackgroundColor?: string;
  FooterContent?: string;
  FooterContentEditorStateJson?: string;

  //Background
  BackgroundImageEnabled: boolean;
  BackgroundColor?: string;
  BackgroundImage?: string;

  get BackgroundImageModel() {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundImage);
  }

  BackgroundFilter?: ColorRGBA;
  getBackgroundFilterColor() {
    return this.BackgroundFilter?.hex;
  }

  //White labeling
  Whitelabeled: boolean;

  // Social
  SocialNetworkEnabled: boolean;
  FacebookUrl?: string;
  TwitterUrl?: string;
  InstagramUrl?: string;
  LinkedinUrl?: string;
  YoutubeUrl?: string;
  WebSiteUrl?: string;
  TikTokUrl?: string;
  SnapchatUrl?: string;
  RedditUrl?: string;
  PinterestUrl?: string;

  // SEO
  MetaTitle?: string;
  MetaDescription?: string;
  MetaImage?: string;

  get MetaImageModel() {
    return ImageDataModel.fromJsonOrUrl(this.MetaImage);
  }

  IsGoogleIndexed: boolean;

  constructor(props?: Partial<PageProperties>) {
    props = props || {};
    Object.assign(this, props);

    // Brand
    this.HeaderEnabled = asBoolean(props.HeaderEnabled);
    this.HeaderLogoEnabled = asBoolean(props.HeaderLogoEnabled);
    this.BillboardEnabled = asBoolean(props.BillboardEnabled);
    this.FooterEnabled = asBoolean(props.FooterEnabled);
    this.FooterLogoEnabled = asBoolean(props.FooterLogoEnabled);
    this.BackgroundImageEnabled = asBoolean(props.BackgroundImageEnabled);
    this.BackgroundColor ||= defaults.bgColor;
    this.HeaderLogoHeight = asNumber(
      props.HeaderLogoHeight,
      defaults.headerLogoHeight
    );
    if (props.BackgroundFilter)
      this.BackgroundFilter = ColorRGBA.valueOrUndefined(
        props.BackgroundFilter
      );

    this.Whitelabeled = asBoolean(props.Whitelabeled);
    this.SocialNetworkEnabled = asBoolean(props.SocialNetworkEnabled);

    // SEO
    this.IsGoogleIndexed = asBoolean(props.IsGoogleIndexed);
  }

  // Extract the properties that are used for styling, only the ones that have been set
  getStylingProperties(includeUndefined?: boolean): Partial<PageProperties> {
    const properties = {} as PageProperties;
    for (const key of stylingPropertiesKeys) {
      if (includeUndefined || this[key] !== undefined) {
        properties[key] = this[key];
      }
    }

    return properties;
  }
}

const stylingPropertiesKeys = [
  'BrandImageUrl',
  'AccentTextColor',
  'AccentBackgroundColor',
  'HeaderEnabled',
  'HeaderLogoEnabled',
  'HeaderLogoHeight',
  'HeaderBackgroundColor',
  'HeaderTextColor',
  'HeaderActiveTextColor',
  'BillboardEnabled',
  'BillboardImageUrl',
  'FooterEnabled',
  'FooterLogoEnabled',
  'FooterTextColor',
  'FooterLinkColor',
  'FooterBrandImageUrl',
  'FooterBackgroundColor',
  'FooterContent',
  'FooterContentEditorStateJson',
  'BackgroundImageEnabled',
  'BackgroundColor',
  'BackgroundImage',
  'BackgroundFilter',
  'Whitelabeled',
  'SocialNetworkEnabled',
  'FacebookUrl',
  'TwitterUrl',
  'InstagramUrl',
  'LinkedinUrl',
  'YoutubeUrl',
  'WebSiteUrl',
  'TikTokUrl',
  'SnapchatUrl',
  'RedditUrl',
  'PinterestUrl'
];
