'use client';
import type { InputWrapperProps as MantineInputWrapperProps } from '@mantine/core';
import {
  factory as mantineFactory,
  InputWrapper as MantineInputWrapper,
  InputWrapperFactory as MantineInputWrapperFactory
} from '@mantine/core';

export type FormInputWrapperElementProps = MantineInputWrapperProps;
type InputWrapperReturnType = ReturnType<
  typeof mantineFactory<MantineInputWrapperFactory>
>;
/**
 * @see https://mantine.dev/core/input-wrapper/
 */
export const FormInputWrapperElement =
  MantineInputWrapper as InputWrapperReturnType;
