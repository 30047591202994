import { Guid } from '@komo-tech/core/models/Guid';
import { BlockLoader } from '@komo-tech/ui/BlockLoader';
import { Center } from '@komo-tech/ui/Center';
import { useEvent } from '@komo-tech/ui/hooks/useEvent';
import { FC, useEffect, useState } from 'react';
import Turnstile, { useTurnstile } from 'react-turnstile';

import { CaptchaTypes } from '@/common/models/site/FrontSiteCaptcha';
import { Site } from '@/common/models/site/Site';

export interface OptionalTurnstileFieldProps {
  turnstileProps?: TurnstileFieldProps;
}

export interface TurnstileFieldProps {
  siteKey: string;
  onVerify: (isVerified: boolean, token?: string) => void;
  token: string;
  refreshKey: number;
  isVerified: boolean;
  triggerRefresh: () => void;
  /**
   * This can only contain up to 32 alphanumeric characters including _ and -.
   */
  action?: string;
}

export const OptionalTurnstileField: FC<OptionalTurnstileFieldProps> = ({
  turnstileProps
}) => {
  if (!turnstileProps?.siteKey || !turnstileProps?.onVerify) {
    return null;
  }

  const { onVerify, ...rest } = turnstileProps;
  return <_Field onVerify={onVerify} {...rest} />;
};

const _Field: FC<TurnstileFieldProps> = ({
  siteKey,
  onVerify,
  refreshKey,
  action
}) => {
  const turnstile = useTurnstile();

  useEffect(() => {
    if (refreshKey && turnstile) {
      turnstile.reset();
    }
  }, [refreshKey]);

  return (
    <Center m={'0 0 1rem'}>
      <BlockLoader isLoading={!turnstile}>
        <Turnstile
          sitekey={siteKey}
          onVerify={(token) => {
            onVerify(true, token);
          }}
          onError={(error) => {
            onVerify(false);
            console.error('Error during captcha challenge', error);
          }}
          onExpire={() => {
            onVerify(false);
          }}
          theme={'light'}
          size={'normal'}
          refreshExpired={'auto'}
          action={action}
        />
      </BlockLoader>
    </Center>
  );
};

interface Options {
  site: Site;
  cardId?: Guid | string;
  triviaGameId?: Guid | string;
  formId: Guid | string;
}

export const useTurnstileState = ({
  site,
  cardId,
  triviaGameId,
  formId
}: Options): TurnstileFieldProps | undefined => {
  const siteKey = site.frontCaptcha?.siteKey;

  const [token, setToken] = useState<string>('');
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const triggerRefresh = useEvent(() => setRefreshKey((x) => ++x));

  const handleVerify = useEvent((isVerified: boolean, token: string) =>
    setToken(isVerified ? token : '')
  );

  const hasTurnstile =
    siteKey && site.frontCaptcha?.type === CaptchaTypes.CloudflareTurnstile;

  if (!hasTurnstile) {
    return undefined;
  }

  return {
    onVerify: handleVerify,
    triggerRefresh,
    token,
    isVerified: !!token,
    refreshKey,
    siteKey,
    action:
      cardId?.toString('n') ||
      triviaGameId?.toString('n') ||
      formId.toString('n')
  };
};
