import { ILogger, LogLevel } from '@microsoft/signalr';

export interface LoggerProps {
  minLevel: LogLevel;
}
export class SignalRLogger implements ILogger {
  minLevel: LogLevel = LogLevel.Information;
  constructor(props?: Partial<LoggerProps>) {
    props = props || {};
    if (props.minLevel) {
      this.minLevel = props.minLevel;
    }
  }

  private formatMessage = (logLevel: LogLevel, message: string) =>
    `[${LogLevel[logLevel]}] SignalR: ${message}`;

  log(logLevel: LogLevel, message: string) {
    if (logLevel < this.minLevel) {
      return;
    }

    if (message.indexOf('WebSocket closed with status code: 1006') !== -1) {
      return;
    }

    if (logLevel === LogLevel.Error) {
      console.error(this.formatMessage(logLevel, message));
      return;
    }

    console.debug(this.formatMessage(logLevel, message));
  }
}
