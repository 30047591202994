import React, { useEffect } from 'react';
import { FieldValues, useController, useFormContext } from 'react-hook-form';

import { FormHookHelpers } from '../FormHookHelpers';
import { FieldValidator, Validator } from '../Validators';
import { predicateValidator } from '../Validators/_rest';
import { FormFileInput, FormFileInputProps } from './FormFileInput';

interface BaseFormHookFieldComponent<
  TFormValues extends object = any,
  TValidateData = any
> {
  validators?: FieldValidator<TFormValues, TValidateData>[];
  validateData?: TValidateData;
}

interface Props<TFormValues extends FieldValues = any, TValidateData = any>
  extends Omit<FormFileInputProps<TFormValues>, 'value' | 'onDelete'>,
    BaseFormHookFieldComponent<TFormValues, TValidateData> {
  overrideHasError?: boolean;
}

export function FormHookFileInput<
  TFormValues extends FieldValues = any,
  TValidateData = any
>(props: Props<TFormValues, TValidateData>) {
  const {
    name,
    onUploadAsync,
    required,
    validators = [],
    validateData,
    overrideHasError,
    uploadDisabled,
    ...rest
  } = props;
  const {
    control,
    getValues,
    setValue,
    trigger,
    formState: { submitCount, isSubmitting, errors }
  } = useFormContext<TFormValues>();

  const resolveValidators = () => {
    const vals = [...validators];
    if (required) {
      vals.push(
        predicateValidator(
          ({ value }) => value !== '[]',
          () => 'Field is required'
        )
      );
    }
    return vals;
  };

  const {
    fieldState: { isTouched, error },
    field
  } = useController<TFormValues>({
    name: name as any,
    control: control,
    defaultValue: getValues()[name as any],
    rules: {
      required: required,
      validate: (v) =>
        new Validator(resolveValidators()).validate(
          v,
          getValues() as any,
          validateData
        )
    }
  });

  //If validation data changes then trigger validation
  useEffect(() => {
    trigger(name as any);
  }, [name, validateData]);

  const getFileFormFieldPaths = (value?: string) => {
    try {
      return value ? (JSON.parse(value) as string[]) : [];
    } catch {
      return [];
    }
  };

  const getCurrentValue = () => getFileFormFieldPaths(field.value);

  const handleUploadAsync = async (files: FileList) => {
    const newPaths = await onUploadAsync(files);
    //hack to make setValue work
    setTimeout(() => {
      setValue(
        name as any,
        JSON.stringify([...getCurrentValue(), ...newPaths]) as any,
        {
          shouldValidate: true,
          shouldDirty: true
        }
      );
    }, 100);
    return newPaths;
  };

  const handleDelete = (filePath: string) => {
    const files = getCurrentValue();
    if (!files.length) return;
    setValue(
      name as any,
      JSON.stringify(files.filter((f) => f !== filePath)) as any,
      {
        shouldValidate: true,
        shouldDirty: true
      }
    );
  };

  const resolveHasError = () => {
    if (overrideHasError !== undefined && overrideHasError !== null) {
      return overrideHasError;
    }

    return !!error && (isTouched || submitCount > 0);
  };

  return (
    <FormFileInput
      {...rest}
      inputRef={field.ref}
      name={name}
      required={required}
      value={field.value || ''}
      hasError={resolveHasError()}
      onUploadAsync={handleUploadAsync}
      onDelete={handleDelete}
      uploadDisabled={uploadDisabled || isSubmitting}
      errorMessage={FormHookHelpers.getError<TFormValues>(errors, name)}
    />
  );
}
