const loadGooglePlacesApi = (placesKey: string, callback: () => void) => {
  if (!window.google) {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    script.onload = () => callback();

    script.src = `https://maps.googleapis.com/maps/api/js?key=${placesKey}&libraries=places`;
    document.getElementsByTagName('head')[0].appendChild(script);
  } else {
    callback();
  }
};

export const handleScriptLoad = (
  inputFieldRef: HTMLInputElement,
  autoCompleteRef: React.MutableRefObject<any>,
  handlePlaceChanged: () => Promise<void>,
  options?: google.maps.places.AutocompleteOptions
) => {
  const googleAutoComplete = new google.maps.places.Autocomplete(
    inputFieldRef,
    options
  );

  google.maps.event.addListener(
    googleAutoComplete,
    'place_changed',
    handlePlaceChanged
  );
  autoCompleteRef.current = googleAutoComplete;

  return () => {
    if (!autoCompleteRef.current) {
      return;
    }
    google.maps.event.clearListeners(autoCompleteRef.current, 'place_changed');
  };
};

export const loadGooglePlacesApiFront = (callback: () => void) =>
  loadGooglePlacesApi('AIzaSyDg0yXWz5r0YT2U2pOXh6maHS4jgnJ-zq8', callback);

export const loadGooglePlacesApiPortal = (callback: () => void) =>
  loadGooglePlacesApi('AIzaSyC_keF93fj4jFM7HHWLBeER5YSsODNofOA', callback);
