'use client';
import type { FieldsetProps as MantineFieldsetProps } from '@mantine/core';
import {
  factory as mantineFactory,
  Fieldset as MantineFieldset,
  FieldsetFactory as MantineFieldsetFactory
} from '@mantine/core';

export type FieldsetProps = MantineFieldsetProps;
type FieldsetReturnType = ReturnType<
  typeof mantineFactory<MantineFieldsetFactory>
>;
/**
 * @see https://mantine.dev/core/fieldset/
 */
export const Fieldset = MantineFieldset as FieldsetReturnType;
