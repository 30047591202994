import { FC } from 'react';

import { IHasIconSizeAndColors } from '../IconProps';
import { FacebookIcon } from './FacebookIcon';
import { InstagramIcon } from './InstagramIcon';
import { LinkedInIcon } from './LinkedInIcon';
import { PinterestIcon } from './PinterestIcon';
import { RedditIcon } from './RedditIcon';
import { SnapchatIcon } from './SnapchatIcon';
import { SocialNetworks } from './SocialNetworks';
import { TikTokIcon } from './TikTokIcon';
import { TwitterIcon } from './TwitterIcon';
import { WebsiteIcon } from './WebsiteIcon';
import { YoutubeIcon } from './YoutubeIcon';

export interface SocialIconProps extends IHasIconSizeAndColors {
  network: SocialNetworks;
}

export const SocialIcon: FC<SocialIconProps> = ({ network, ...rest }) => {
  switch (network) {
    case SocialNetworks.Facebook:
      return <FacebookIcon {...rest} />;
    case SocialNetworks.Twitter:
      return <TwitterIcon {...rest} />;
    case SocialNetworks.Instagram:
      return <InstagramIcon {...rest} />;
    case SocialNetworks.Linkedin:
      return <LinkedInIcon {...rest} />;
    case SocialNetworks.Website:
      return <WebsiteIcon {...rest} />;
    case SocialNetworks.Youtube:
      return <YoutubeIcon {...rest} />;
    case SocialNetworks.TikTok:
      return <TikTokIcon {...rest} />;
    case SocialNetworks.Snapchat:
      return <SnapchatIcon {...rest} />;
    case SocialNetworks.Reddit:
      return <RedditIcon {...rest} />;
    case SocialNetworks.Pinterest:
      return <PinterestIcon {...rest} />;
    default:
      throw new Error(`Invalid network ${network}`);
  }
};
