import { FCC } from 'fcc';
import { createPortal } from 'react-dom';

import { useKomoDebug } from '@/front/components/komo-debug/KomoDebugContext';

export const KomoDebugPortal: FCC = ({ children }) => {
  const debugPortalRef = useKomoDebug((x) => x?.portalRef?.current);
  const debugInited = useKomoDebug((x) => x?.initialized);

  if (!debugPortalRef || !debugInited) {
    return null;
  }
  return createPortal(<>{children}</>, debugPortalRef);
};
