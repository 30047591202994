import { createPolymorphicComponent } from '@mantine/core';
import cx from 'clsx';
import { forwardRef, ReactNode } from 'react';

import { useTheme } from '../../theme/useTheme';
import { Box, BoxProps } from '../Box';
import { useListContext } from './ListContext';
import classes from './ListItem.module.css';

export interface ListItemProps extends BoxProps {
  shadow?: boolean;
  active?: boolean;
  activeBackgroundColor?: string;
  activeColor?: string;
  selectable?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  zoomOnHover?: boolean;
  children?: ReactNode;
}

export const ListItem = createPolymorphicComponent<'li', ListItemProps>(
  forwardRef<HTMLLIElement, ListItemProps>(
    (
      {
        children,
        shadow,
        selectable,
        expanded = false,
        active = false,
        disabled = false,
        padding,
        zoomOnHover,
        w = '100%',
        activeColor,
        activeBackgroundColor,
        className,
        ...rest
      },
      ref
    ) => {
      const {
        itemsHaveShadow,
        itemsTextColorVariant,
        itemsSelectable,
        itemsHaveBackgroundColor,
        itemsBorder,
        itemsPadding
      } = useListContext();

      const theme = useTheme();

      return (
        <Box
          ref={ref}
          w={w}
          component="li"
          __vars={{
            '--list-item-active-color': activeColor,
            '--list-item-active-bg-color':
              activeBackgroundColor ?? theme.other.colors.menuHighlight,
            '--list-item-items-border': itemsBorder
          }}
          className={cx(classes.root, className)}
          data-has-shadow={!!itemsHaveShadow || !!shadow}
          color={itemsTextColorVariant}
          data-has-bg-color={!!itemsHaveBackgroundColor}
          data-zoom-on-hover={zoomOnHover}
          p={(padding || itemsPadding) as string}
          data-selectable={
            selectable === undefined ? itemsSelectable : selectable
          }
          data-active={active}
          data-expanded={expanded}
          data-disabled={disabled}
          {...rest}
        >
          {children}
        </Box>
      );
    }
  )
);

ListItem.displayName = 'ListItem';
