interface CookieDetails {
  name: string;
  defaultMaxAgeSeconds: number;
}

export const CookieConstants = {
  Fingerprint: {
    name: 'komo.fp',
    defaultMaxAgeSeconds: 400 * 24 * 60 * 60
  } as CookieDetails,
  ProfileId: {
    name: 'komo.profile',
    defaultMaxAgeSeconds: 365 * 24 * 60 * 60
  } as CookieDetails,
  DraftPassword: {
    name: 'komo_draft_password',
    defaultMaxAgeSeconds: 60 * 24 * 60 * 60
  } as CookieDetails
};
