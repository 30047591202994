import { Box, BoxProps } from '@komo-tech/ui/Box';
import { darken, isLightColor, lighten } from '@komo-tech/ui/theme/fns';
import cx from 'clsx';
import { forwardRef, ReactNode, useMemo } from 'react';

import { ThemeConstants } from '@/common/utils/ThemeConstants';

import classes from './CardShell.module.css';

export interface CardShellProps
  extends Omit<BoxProps, '__vars' | 'c' | 'bd' | 'bg'> {
  aspectRatio?: string;
  mobileMargin?: string | number;
  withRadius?: boolean;
  children?: ReactNode;
  id?: string;
  textColor?: string;
  borderColor?: string;
  backgroundColor?: string;
}

export const CardShell = forwardRef<HTMLDivElement, CardShellProps>(
  (
    {
      children,
      mobileMargin,
      aspectRatio,
      textColor,
      borderColor,
      backgroundColor,
      className,
      withRadius,
      ...rest
    },
    ref
  ) => {
    const vars = useCardShellVars({
      backgroundColor: backgroundColor,
      textColor: textColor,
      borderColor: borderColor,
      aspectRatio,
      mobileMargin
    });

    return (
      <Box
        className={cx(classes.root, className)}
        data-with-radius={withRadius}
        ref={ref}
        maw={672}
        data-has-mobile-margin={!!mobileMargin}
        __vars={vars}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

export const useCardShellVars = (
  props: Pick<CardShellProps, 'aspectRatio' | 'mobileMargin'> &
    CardShellColorVarsOptions
) => {
  const { aspectRatio, backgroundColor, textColor, borderColor, mobileMargin } =
    props || {};

  return useMemo<BoxProps['__vars']>(() => {
    return {
      '--komo-card-shell-box-shadow': ThemeConstants.KomoCardShellBoxShadow,
      '--komo-card-shell-aspect-ratio': aspectRatio,
      '--komo-card-shell-mobile-margin':
        mobileMargin?.toString() ?? '2rem 0.75rem',
      ...resolveCardShellColorVars({
        backgroundColor,
        textColor: textColor,
        borderColor: borderColor
      })
    };
  }, [backgroundColor, textColor, borderColor, aspectRatio, mobileMargin]);
};

export const useCardShellColorVars = (props: CardShellColorVarsOptions) => {
  const { backgroundColor, textColor, borderColor } = props || {};

  return useMemo<BoxProps['__vars']>(
    () =>
      resolveCardShellColorVars({
        backgroundColor,
        textColor: textColor,
        borderColor: borderColor
      }),
    [backgroundColor, textColor, borderColor]
  );
};

export interface CardShellColorVarsOptions
  extends Pick<
    CardShellProps,
    'backgroundColor' | 'textColor' | 'borderColor'
  > {}

const resolveCardShellColorVars = (props: CardShellColorVarsOptions) => {
  const vars: BoxProps['__vars'] = {};

  if (!props) return vars;

  const { backgroundColor, borderColor, textColor } = props;
  if (textColor) {
    vars['--komo-card-shell-c'] = textColor;
    vars['--komo-card-shell-dimmed-c'] = isLightColor(textColor, 0.4)
      ? darken(textColor, 0.3)
      : lighten(textColor, 0.3);
  }

  if (backgroundColor) {
    vars['--komo-card-shell-bg'] = backgroundColor;
    vars['--komo-card-shell-skeleton-c'] = isLightColor(backgroundColor, 0.4)
      ? darken(backgroundColor, 0.3)
      : lighten(backgroundColor, 0.3);
  }

  if (borderColor) {
    vars['--komo-card-shell-bd'] = borderColor;
  }

  return vars;
};
