export enum SiteAuthState {
  None = 'None',
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  SignUpConfirm = 'SignUpConfirm',
  ForgotPassword = 'ForgotPassword',
  Profile = 'Profile',
  Verify = 'Verify'
}

type AuthPage = 'sign-in' | 'forgot-password';
export const resolveAuthUrl = (
  type: AuthPage,
  params: { email?: string; returnUrl?: string }
) => {
  let url = '/auth/site/sign-in';
  if (type === 'forgot-password') {
    url = '/auth/site/forgot-password';
  }

  const query: string[] = [];
  if (params?.email) query.push(`email=${encodeURIComponent(params.email)}`);
  if (params?.returnUrl)
    query.push(`return=${encodeURIComponent(params.returnUrl)}`);
  return !query.length ? url : `${url}?${query.join('&')}`;
};
