import React, { FC } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const MoreVerticalIcon: FC<IconProps> = ({ color, ...rest }) => {
  return (
    <IconSvg {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9927 4C13.0972 4 13.9927 4.89522 13.9927 5.99953C13.9927 7.10385 13.0972 7.99907 11.9927 7.99907C10.8881 7.99907 9.99268 7.10385 9.99268 5.99953C9.99268 4.89522 10.8881 4 11.9927 4ZM13.9927 12C13.9927 10.8957 13.0972 10.0005 11.9927 10.0005C10.8881 10.0005 9.99268 10.8957 9.99268 12C9.99268 13.1043 10.8881 13.9996 11.9927 13.9996C13.0972 13.9996 13.9927 13.1043 13.9927 12ZM13.9927 18.0004C13.9927 16.8961 13.0972 16.0009 11.9927 16.0009C10.8881 16.0009 9.99268 16.8961 9.99268 18.0004C9.99268 19.1047 10.8881 20 11.9927 20C13.0972 20 13.9927 19.1047 13.9927 18.0004Z"
        fill={color || getDefaultColor()}
      />
    </IconSvg>
  );
};
