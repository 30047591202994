const isDirectorySeparator = (char: string) => char === '\\' || char === '/';

/*
 * from: https://stackoverflow.com/a/67994693
 */
export const getFilenameFromContentDisposition = (disposition: string) => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  let fileName: string = null;
  if (utf8FilenameRegex.test(disposition)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart);
      const matches = asciiFilenameRegex.exec(partialDisposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
  }
  return fileName;
};

export const getFileName = (fileName: string) => {
  if (!fileName) {
    return '';
  }
  for (let i = fileName.length; --i >= 0; ) {
    if (isDirectorySeparator(fileName[i])) {
      return fileName.substring(i + 1);
    }
  }
  return fileName;
};

export const getFileExtension = (fileName: string) => {
  if (!fileName) {
    return '';
  }
  const length = fileName.length;
  for (let i = length - 1; i >= 0; i--) {
    const ch = fileName[i];
    if (ch == '.') {
      if (i != length - 1) return fileName.substring(i);
      else return '';
    }
    if (isDirectorySeparator(ch)) break;
  }
  return '';
};

export const getFileNameWithoutExtension = (fileName: string) => {
  if (!fileName) {
    return '';
  }
  const index = fileName.lastIndexOf('.');
  return index === -1
    ? fileName // No extension was found
    : fileName.substring(0, index);
};

export const sanitizeFileNamePart = (part: string) => {
  if (!part) {
    return part;
  }
  return part
    .trim()
    .replace(/[<>#:"/\\|?*+]/g, '') // wipe out illegal chars
    .replace(/(\s)/g, '_')
    .replace(/([^a-zA-Z\d_.]+)/gi, '_')
    .replace(/(_+)/g, '_')
    .replace(/^(_*)(.*)(_*)$/g, '$2');
};

export const sanitizeFileName = (fileName: string) => {
  if (!fileName) {
    throw new Error('Invalid file name');
  }
  const extension = getFileExtension(fileName);
  const name = getFileNameWithoutExtension(fileName);
  return extension
    ? sanitizeFileNamePart(name) + sanitizeFileNamePart(extension)
    : sanitizeFileNamePart(name);
};

export const dataUriToBlobAsync = async (dataUri: string) => {
  const fetchResponse = await fetch(dataUri);
  return await fetchResponse.blob();
};

export const dataUriToFileAsync = async (dataUri: string, fileName: string) => {
  const blob = await dataUriToBlobAsync(dataUri);
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type
  });
};

export const blobToFile = (blob: Blob, fileName: string) => {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type
  });
};

export const blobToDataURL = (blob: Blob, callback) => {
  const a = new FileReader();
  a.onload = function (e) {
    callback(e.target.result);
  };
  a.readAsDataURL(blob);
};

export const uploadAsync = (
  uploadURL: string,
  file: File | Blob,
  contentType?: string
) => {
  const headers = {
    'Content-Type': contentType || file.type
  };
  return fetch(uploadURL, { method: 'PUT', headers, body: file });
};

export const getMimes = (type: FormFileAllowedType) => {
  switch (type) {
    case 'csv':
      return ['text/csv', 'application/vnd.ms-excel'];
    case 'excel':
      return [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
    case 'image':
      return [
        'image/png',
        'image/gif',
        'image/jpeg',
        'image/jpg',
        'image/webp'
      ];
    case 'video':
      return [
        'video/3gpp',
        'video/mp4',
        'video/mpeg',
        'video/ogg',
        'video/quicktime',
        'video/webm',
        'video/x-m4v',
        'video/x-ms-wmv',
        'video/x-msvideo'
      ];
    case 'pdf':
      return ['application/pdf'];
    default:
      return [];
  }
};

export type FormFileAllowedType = 'csv' | 'excel' | 'image' | 'pdf' | 'video';
export const FormFileAllowedTypes: FormFileAllowedType[] = [
  'csv',
  'excel',
  'image',
  'pdf',
  'video'
];

export const getFileType = (file: File) => {
  let type: FormFileAllowedType | null = null;
  if (!file || !file.type) return type;
  if (getMimes('excel').some((m) => m.toLowerCase() === file.type)) {
    type = 'excel';
  } else if (getMimes('csv').some((m) => m.toLowerCase() === file.type)) {
    type = 'csv';
  } else if (getMimes('image').some((m) => m.toLowerCase() === file.type)) {
    type = 'image';
  } else if (getMimes('pdf').some((m) => m.toLowerCase() === file.type)) {
    type = 'pdf';
  } else if (getMimes('video').some((m) => m.toLowerCase() === file.type)) {
    type = 'video';
  }
  return type;
};

export const isFileImage = (file: File) => {
  const type = getFileType(file);
  return type && type === 'image';
};

export const fileToDataUrlAsync = (file: File | Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (...args) => reject(args);
    reader.readAsDataURL(file);
  });
};

export const downloadFileViaGet = (
  url: string,
  fileName: string,
  newTab?: boolean
) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  if (newTab) {
    a.setAttribute('target', '_blank');
    a.setAttribute('rel', 'noopener noreferrer');
  }
  document.body.appendChild(a);
  a.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  );
  document.body.removeChild(a);
};

export const downloadFileViaPost = async (
  apiCall: () => Promise<any>,
  fileName: string
) => {
  const response = await apiCall();
  const a: any = document.createElement('a');
  a.style.display = 'none';
  a.setAttribute('href', window.URL.createObjectURL(new Blob([response.data])));
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  );
  document.body.removeChild(a);
};
