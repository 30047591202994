import React, { FC, useMemo } from 'react';

type AcceptTypes = 'image' | 'video';
interface Props {
  onUpload: (files: FileList) => void;
  inputRef: any;
  disabled?: boolean;
  accept?: AcceptTypes[];
}

const FormHiddenFileInputElement: FC<Props> = ({
  onUpload,
  accept,
  disabled,
  inputRef
}) => {
  const acceptAttribute = useMemo(() => {
    if (!accept?.length) return undefined;
    return accept
      .map((x) => {
        switch (x) {
          case 'image':
            return 'image/*';
          case 'video':
            return 'video/*';
        }
      })
      .join(' ');
  }, [accept]);

  return (
    <input
      type="file"
      accept={acceptAttribute}
      disabled={disabled}
      onChange={(e) => onUpload(e.currentTarget.files)}
      style={{ display: 'none' }}
      ref={inputRef}
    />
  );
};
export const FormHiddenFileInput = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => <FormHiddenFileInputElement inputRef={ref} {...props} />
);
