import { HubConnection } from '@microsoft/signalr';
import { useEffect } from 'react';

import { GroupName, HubMethods } from '@/common/data/signal-r/types';

interface UseConnectionGroupsProps {
  groupNames: GroupName[];
  connection?: HubConnection;
  refreshKey: number;
}

const ignoredMessage =
  "Cannot send data if the connection is not in the 'Connected' State.";

export const useConnectionGroups = ({
  groupNames = [],
  connection,
  refreshKey
}: UseConnectionGroupsProps) => {
  useEffect(() => {
    if (!connection || !groupNames.length) {
      return;
    }

    connection.send(HubMethods.AddToGroups, groupNames).catch((e) => {
      if (e?.message == ignoredMessage) {
        return;
      }

      console.error(e);
    });

    // Cleanup
    return () => {
      if (connection)
        connection.send(HubMethods.RemoveFromGroups, groupNames).catch(() => {
          /*Ignore errors on cleanup*/
        });
    };
  }, [groupNames.map((x) => x.toString()).join('|'), connection, refreshKey]);
};
