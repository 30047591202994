import { FC } from 'react';

import { useMeasure } from '../../../hooks/useMeasure';
import { Box } from '../../Box';
import { Skeleton } from '../../Skeleton';
import { Text } from '../../Text';
import classes from './QrCodeReader.module.css';
import { QrCodeReaderProps } from './QrCodeReader.types';

export const QrCodeReaderDrawerSkeleton: FC<
  Pick<QrCodeReaderProps, 'qrBorderColor' | 'infoContent'>
> = ({ qrBorderColor = 'var(--mantine-color-text)', infoContent }) => {
  const container = useMeasure();
  const hasInfoContent = !!infoContent;

  return (
    <Box className={classes.root} ref={container.register}>
      <Box
        __vars={{
          '--qr-border-c': qrBorderColor
        }}
        className={classes.camera}
      >
        <Skeleton className={classes.cameraSkeleton} />
      </Box>
      {hasInfoContent && (
        <Skeleton>
          <Text ta="center" mt="xl">
            {infoContent}
          </Text>
        </Skeleton>
      )}
    </Box>
  );
};
