import { forwardRef, MouseEvent, PropsWithChildren } from 'react';

import { useEvent } from '../../hooks/useEvent';
import { Avatar, AvatarProps } from '../Avatar';

export const getInitials = (name: string, fallbackToMe?: boolean) => {
  const sanitised = (name || '').trim().replace('[', '');
  if (sanitised.length === 0) {
    if (fallbackToMe) return fallbackToMe ? 'ME' : '';
  }
  const [firstName, lastName] = sanitised.split(' ');
  const f = (s: string) => (s ? s[0] : '');
  return `${f(firstName)}${f(lastName)}`;
};

interface Props extends Pick<AvatarProps, 'c' | 'bg' | 'bd' | '__vars'> {
  name?: string;
  disabled?: boolean;
  rounded?: boolean;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

export const InitialsIcon = forwardRef<
  HTMLDivElement,
  PropsWithChildren<Props>
>(({ name, disabled, rounded, onClick, children, ...rest }, ref) => {
  const hasOnClick = !!onClick;
  const handleClick = useEvent((e: MouseEvent<HTMLElement>) => {
    if (!disabled) {
      onClick?.(e);
    }
  });
  return (
    <Avatar
      ref={ref as any}
      component={hasOnClick ? 'button' : 'div'}
      variant="transparent"
      styles={{
        placeholder: {
          textTransform: 'uppercase'
        }
      }}
      radius={rounded ? 'xl' : undefined}
      size="md"
      onClick={handleClick}
      name={name}
      {...rest}
    >
      {children}
    </Avatar>
  );
});
