import { AchievementAwardedEvent } from './AchievementAwardedEvent';
import { CardUpdatedEvent } from './CardUpdatedEvent';
import { LiveSurveyGameplayQuestionUpdatedEvent } from './LiveSurveyGameplayQuestionUpdatedEvent';
import { LiveSurveyQuestionReactionUpdatedEvent } from './LiveSurveyQuestionReactionUpdatedEvent';
import { LiveSurveyQuestionUpdatedEvent } from './LiveSurveyQuestionUpdatedEvent';
import { PageCardAddedEvent } from './PageCardAddedEvent';
import { PageStylingEvent } from './PageStylingEvent';
import { PredictiveQuestionPointsUpdatedEvent } from './PredictiveQuestionPointsUpdatedEvent';
import { PrizeAwardedEvent } from './PrizeAwardedEvent';
import { SiteStylingEvent } from './SiteStylingEvent';

export * from './AchievementAwardedEvent';
export * from './CardUpdatedEvent';
export * from './LiveSurveyGameplayQuestionUpdatedEvent';
export * from './LiveSurveyQuestionUpdatedEvent';
export * from './PrizeAwardedEvent';
export * from './SiteStylingEvent';

/**
 * Methods called by the server. Must match ISiteHubClient method names in the dotnet code.
 */
export enum SiteHubMethods {
  OnCardUpdated = 'OnCardUpdated',
  OnLiveSurveyQuestionUpdated = 'OnLiveSurveyQuestionUpdated',
  OnLiveSurveyGameplayQuestionUpdated = 'OnLiveSurveyGameplayQuestionUpdated',
  OnLiveSurveyQuestionReactionUpdated = 'OnLiveSurveyQuestionReactionUpdated',
  OnPrizeAwarded = 'OnPrizeAwarded',
  OnCardPublished = 'OnCardPublished',
  OnSiteUpdated = 'OnSiteUpdated',
  OnPageUpdated = 'OnPageUpdated',
  OnPageCardAdded = 'OnPageCardAdded',
  OnAchievementAwarded = 'OnAchievementAwarded',
  OnPredictiveQuestionPointsUpdated = 'OnPredictiveQuestionPointsUpdated'
}

export type SiteHubMethodSignatures = {
  [SiteHubMethods.OnCardUpdated]: (e: CardUpdatedEvent) => void;
  [SiteHubMethods.OnLiveSurveyQuestionUpdated]: (
    e: LiveSurveyQuestionUpdatedEvent
  ) => void;
  [SiteHubMethods.OnLiveSurveyGameplayQuestionUpdated]: (
    e: LiveSurveyGameplayQuestionUpdatedEvent
  ) => void;
  [SiteHubMethods.OnLiveSurveyQuestionReactionUpdated]: (
    e: LiveSurveyQuestionReactionUpdatedEvent
  ) => void;
  [SiteHubMethods.OnPrizeAwarded]: (e: PrizeAwardedEvent) => void;
  [SiteHubMethods.OnCardPublished]: (e: CardUpdatedEvent) => void;
  [SiteHubMethods.OnSiteUpdated]: (e: SiteStylingEvent) => void;
  [SiteHubMethods.OnPageUpdated]: (e: PageStylingEvent) => void;
  [SiteHubMethods.OnPageCardAdded]: (e: PageCardAddedEvent) => void;
  [SiteHubMethods.OnAchievementAwarded]: (e: AchievementAwardedEvent) => void;
  [SiteHubMethods.OnPredictiveQuestionPointsUpdated]: (
    e: PredictiveQuestionPointsUpdatedEvent
  ) => void;
};

export const sanitiseSiteHubEventPayload = (
  method: SiteHubMethods,
  eventPayload: any
) => {
  switch (method) {
    case SiteHubMethods.OnCardUpdated:
    case SiteHubMethods.OnCardPublished:
      return new CardUpdatedEvent(eventPayload);
    case SiteHubMethods.OnLiveSurveyQuestionUpdated:
      return new LiveSurveyQuestionUpdatedEvent(eventPayload);
    case SiteHubMethods.OnLiveSurveyGameplayQuestionUpdated:
      return new LiveSurveyGameplayQuestionUpdatedEvent(eventPayload);
    case SiteHubMethods.OnLiveSurveyQuestionReactionUpdated:
      return new LiveSurveyQuestionReactionUpdatedEvent(eventPayload);
    case SiteHubMethods.OnPredictiveQuestionPointsUpdated:
      return new PredictiveQuestionPointsUpdatedEvent(eventPayload);
    default:
      return eventPayload;
  }
};
