import { blobToFile, fileToDataUrlAsync } from '@komo-tech/core/utils/file';
import { Box } from '@komo-tech/ui/Box';
import { Center } from '@komo-tech/ui/Center';
import { DisplayCard } from '@komo-tech/ui/DisplayCard';
import { useActionHandler } from '@komo-tech/ui/hooks/useActionHandler';
import { ShareIcon } from '@komo-tech/ui/Icons/ShareIcon';
import { Loader } from '@komo-tech/ui/Loader';
import { Stack } from '@komo-tech/ui/Stack';
import { useQuery } from '@tanstack/react-query';
import saveAs from 'file-saver';
import { toBlob } from 'html-to-image';
import { FC, memo, useRef } from 'react';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { BlockStageModel } from '@/common/components/BlockBuilder/types/BlockStageModel';
import { BlockViewerSingleStageHost } from '@/common/components/BlockBuilder/Viewer/Hosts';
import { ResultButton } from '@/common/components/Card/ResultScreen/ResultButton';
import { BlockContactBadgeAlbumItemResolver as BlockItemResolver } from '@/common/components/Contacts/BlockBuilder/BlockBadgeAlbumItemResolver';
import { BlockContactItemViewDefinitions as Definitions } from '@/common/components/Contacts/BlockBuilder/BlockContactItemViewDefinitions';
import { PublicContactsService as Service } from '@/front/data/Contacts';
import { ContactDataSourceFns } from '@/front/utils/Contacts/ContactDataSourceFunctions';

import { useFrontSite } from '../../../Store';

export const BadgeAlbum: FC = memo(() => {
  const stageElementRef = useRef<HTMLDivElement>();
  const [handleAsync, { isHandling }] = useActionHandler();
  const { data: stage, isLoading } = useQuery(
    Service.cacheKeys.achievements.badgeAlbumBlocks(),
    async () => {
      const { dataJson } =
        await Service.actions.achievements.getBadgeAlbumBlocksDataAsync();
      return new BlockStageModel({
        ...JSON.parse(dataJson),
        itemResolver: BlockItemResolver
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  const site = useFrontSite((x) => x.site);

  const dataSourcesRef = useRef<DataSourceInstance[]>(
    ContactDataSourceFns.resolveInstances({
      siteId: site.id,
      isPortal: false,
      isServerGenerated: false
    })
  );

  const shareButton = site.properties.getProfileAlbumShareModel();
  const handleClick = () =>
    handleAsync(
      async () => {
        const fileName = 'badge-album.png';
        const file = blobToFile(
          await toBlob(stageElementRef.current, {
            canvasHeight: stage?.data?.size?.maxHeight,
            canvasWidth: stage?.data?.size?.maxWidth,
            // need to include query params otherwise image caching won't work properly
            includeQueryParams: true
          }),
          fileName
        );
        return {
          imageUrl: await fileToDataUrlAsync(file),
          fileName: fileName,
          fileBlob: file
        };
      },
      {
        onSuccess: async ({ imageUrl, fileBlob, fileName }) => {
          try {
            const files = [fileBlob];
            if (!!navigator.canShare && navigator.canShare({ files })) {
              await navigator.share({
                files
              });
            } else {
              saveAs(imageUrl, fileName, {
                autoBom: true
              });
            }
          } catch (err) {
            try {
              // If you close the native share without sharing, it will raise an
              // abort error
              if (err.toString().indexOf('AbortError') === -1) {
                console.error(err);
                // try again, to be safe
                saveAs(imageUrl, fileName, {
                  autoBom: true
                });
              }
            } catch (err2) {
              console.error(err2);
            }
          }
        }
      }
    );
  return (
    <Box p="md">
      {isLoading && (
        <Center mih={200}>
          <Loader size="3rem" />
        </Center>
      )}
      {!isLoading && !!stage && (
        <Stack gap={'sm'}>
          <DisplayCard p="0" shadow="sm" style={{ aspectRatio: '1/1' }}>
            <BlockViewerSingleStageHost
              definitions={Definitions}
              dataSourcesRef={dataSourcesRef}
              stage={stage}
              onStageElementRegister={(e) => (stageElementRef.current = e)}
            />
          </DisplayCard>

          <ResultButton
            value={shareButton}
            disabled={isLoading}
            onClick={handleClick}
            leftSection={<ShareIcon />}
            busy={isHandling}
          />
        </Stack>
      )}
    </Box>
  );
});
