import { HubConnection } from '@microsoft/signalr';
import { useEffect, useState } from 'react';

import { createHubConnectionAsync, HubFactoryOptions } from './SignalRFactory';

export interface HubConnectionOptions extends HubFactoryOptions {
  init?: boolean;
  refreshKey?: string;
}

export const useHubConnection = (options: HubConnectionOptions) => {
  const { init = false, refreshKey, ...signalROptions } = options;
  const [connection, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    if (!init) {
      return;
    }

    let isCleaning = false;
    let _connection: HubConnection | null = null; // Track locally otherwise we need to create an effect dependency on `connection`

    if (signalROptions.url) {
      createHubConnectionAsync(signalROptions).then((conn) => {
        _connection = conn;
        if (isCleaning) return;
        setConnection(conn);
      });
    }

    // Cleanup
    return () => {
      isCleaning = true;
    };
  }, [
    init,
    options.url,
    options.onClose?.toString(),
    options.onReconnected?.toString(),
    options.onReconnecting?.toString(),
    refreshKey
  ]);

  return connection;
};
