import { Box } from '@komo-tech/ui/Box';
import { Drawer } from '@komo-tech/ui/Drawer';
import { Stack } from '@komo-tech/ui/Stack';
import { FC } from 'react';

import { SiteAuthState } from '@/common/components/Card/shared/Front/Routing/AuthSiteUrlFunctions';
import { useDynamicFormRendererSizes } from '@/common/components/Form/DynamicForm/Renderer';
import { SiteUser } from '@/common/models/SiteUser';
import {
  DataDogService,
  DataDogServiceHelper
} from '@/common/utils/DataDogService';

import { useUser, useUserContextSelector } from '../../../UserContext';
import { useFrontSite } from '../../Store';
import classes from './AuthDrawer.module.css';
import { AuthDrawerForgotPassword } from './ForgotPassword';
import { AuthDrawerHeader } from './Header';
import { AuthDrawerSignIn } from './SignIn';
import { AuthDrawerSignUp } from './SignUp';
import { AuthDrawerVerify } from './Verify';

interface Props {
  open: boolean;
  state: SiteAuthState;
  resetPasswordCode?: string;
  returnUrl?: string;
  email?: string;
  onStateChange: (state: SiteAuthState) => void;
}

export const AuthDrawer: FC<Props> = ({
  open,
  state,
  onStateChange,
  returnUrl,
  email
}) => {
  const user = useUser();
  const site = useFrontSite((x) => x.site);
  const setUser = useUserContextSelector((s) => s.setUser);

  const { fieldSize } = useDynamicFormRendererSizes();

  return (
    <>
      <Drawer
        position="top"
        opened={open}
        zIndex={2222}
        closeOnClickOutside
        onClose={() => onStateChange(SiteAuthState.None)}
        withinPortal
        styles={{
          content: {
            height: 'fit-content',
            maxHeight: 'calc(100svh - 4rem)'
          },
          body: {
            padding: '0.5rem'
          }
        }}
        transitionProps={{ duration: 200, transition: 'slide-down' }}
        withCloseButton={false}
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <Stack gap={0} w="100%" justify="center" align="center">
          <Stack w="100%" maw={380} align="center">
            <Box className={classes.content}>
              <Box p={'0rem 1rem'}>
                <AuthDrawerHeader
                  state={state}
                  onStateChange={onStateChange}
                  size={fieldSize}
                  site={site}
                  user={user}
                  onSignOutSuccess={() => {
                    DataDogService.forgetUser();
                    onStateChange(SiteAuthState.None);
                    // Need to reload to update saved states for cards
                    location.reload();
                  }}
                />
                {state === SiteAuthState.Verify ? (
                  <AuthDrawerVerify
                    site={site}
                    size={fieldSize}
                    onCancel={() => onStateChange(SiteAuthState.None)}
                    onVerifySuccess={() => onStateChange(SiteAuthState.None)}
                    email={user?.email}
                  />
                ) : undefined}
                {state === SiteAuthState.SignIn && (
                  <AuthDrawerSignIn
                    site={site}
                    size={fieldSize}
                    onCancel={() => onStateChange(SiteAuthState.None)}
                    onForgotPasswordClick={() =>
                      onStateChange(SiteAuthState.ForgotPassword)
                    }
                    onSignInSuccess={(user: SiteUser) => {
                      setUser(user);
                      if (returnUrl) {
                        location.href = returnUrl;
                      }
                      onStateChange(SiteAuthState.None);
                    }}
                    email={email}
                  />
                )}
                {(state === SiteAuthState.SignUp ||
                  state === SiteAuthState.SignUpConfirm) && (
                  <AuthDrawerSignUp
                    site={site}
                    size={fieldSize}
                    state={state}
                    onCancel={() => onStateChange(SiteAuthState.SignIn)}
                    onSignUpFormCheckSuccess={() =>
                      onStateChange(SiteAuthState.SignUpConfirm)
                    }
                    onSignUpSuccessConfirm={(u) => {
                      setUser(u);
                      DataDogServiceHelper.trySetUserInformation(user);
                      onStateChange(SiteAuthState.None);
                    }}
                  />
                )}
                {state === SiteAuthState.ForgotPassword && (
                  <AuthDrawerForgotPassword
                    site={site}
                    size={fieldSize}
                    returnUrl={returnUrl}
                    onCancel={() => onStateChange(SiteAuthState.SignIn)}
                    onForgotPasswordSuccess={() =>
                      onStateChange(SiteAuthState.SignIn)
                    }
                  />
                )}
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};
