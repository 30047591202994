import {
  FormTextInput,
  FormTextInputProps
} from '@komo-tech/ui/Form/TextInput';
import { forwardRef } from 'react';

import { resolveFieldName } from './DynamicFieldAddress.types';

interface Props
  extends Pick<FormTextInputProps, 'error' | 'disabled' | 'size' | 'required'> {
  fieldName: string;
  onChange: (value: string) => void;
  value: FormTextInputProps['value'];
}

export const StateField = forwardRef<HTMLInputElement, Props>(
  ({ onChange, fieldName, value, ...rest }, ref) => {
    return (
      <FormTextInput
        ref={ref}
        name={resolveFieldName(fieldName, 'state')}
        label="State/Province"
        onChange={(_, v) => onChange(v)}
        value={value || ''}
        autoComplete="address-level1"
        {...rest}
      />
    );
  }
);
