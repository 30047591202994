import { isLocalhost, isServer } from '@komo-tech/core/utils/browser';
import {
  createContext,
  useContextSelector
} from '@komo-tech/ui/hooks/useContextSelector';
import { FCC } from 'fcc';
import dynamic from 'next/dynamic';
import { RefObject, useMemo, useRef, useState } from 'react';

const KomoDebugDisplay = dynamic(
  () => import('@/front/components/komo-debug/KomoDebugDisplay'),
  {
    ssr: false
  }
);

interface KomoDebugContextProps {
  portalRef?: RefObject<HTMLDivElement>;
  initialized: boolean;
}

const KomoDebugContext = createContext<KomoDebugContextProps>(null);

export const KomoDebugProvider: FCC = ({ children }) => {
  const isDebug = !isServer() && isLocalhost();

  if (!isDebug) {
    return <>{children}</>;
  }

  return <InnerKomoDebugProvider>{children}</InnerKomoDebugProvider>;
};

const InnerKomoDebugProvider: FCC = ({ children }) => {
  const portalRef = useRef<HTMLDivElement>();
  const [inited, setInited] = useState(false);
  const value = useMemo(() => ({ initialized: inited, portalRef }), [inited]);

  return (
    <KomoDebugContext.Provider value={value}>
      <KomoDebugDisplay
        debugHostRef={(x) => {
          portalRef.current = x;
          setInited(true);
        }}
      />
      {children}
    </KomoDebugContext.Provider>
  );
};

export function useKomoDebug<T>(
  selector: (value: KomoDebugContextProps) => T
): T {
  return useContextSelector(
    KomoDebugContext,
    (state) => {
      if (!state) {
        return null;
      }
      return selector(state);
    },
    'Value'
  );
}

export const useIsDebug = () => useKomoDebug((x) => x) !== null;
