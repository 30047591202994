import {
  MantineSize,
  Tabs as MantineTabs,
  TabsProps as MantineTabsProps
} from '@mantine/core';
import cx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';

import classes from './Tabs.module.css';

/**
 * @see https://mantine.dev/core/tabs/
 */
export interface TabsProps<TValue extends string = string>
  extends Omit<
    MantineTabsProps,
    'defaultValue' | 'value' | 'onTabChange' | 'bg' | 'onChange' | 'styles'
  > {
  defaultValue?: TValue;
  variant?: MantineTabsProps['variant'] | 'styled';
  value?: TValue;
  tabSize?: MantineSize;
  onChange?: (value: TValue) => void;
  styledProps?: {
    bg?: string;
    activeColor?: string;
    activeBorderColor?: string;
    color?: string;
    borderColor?: string;
    borderWidth?: string | number;
  };
}

function _Tabs<TValue extends string = string>(
  {
    tabSize = 'md',
    variant: variantProp,
    children,
    styledProps,
    onChange,
    className,
    ...rest
  }: TabsProps<TValue>,
  forwardedRef?: ForwardedRef<HTMLDivElement>
) {
  let variant: TabsProps['variant'] = variantProp || 'default';
  if (!variantProp && !!styledProps) {
    variant = 'styled';
  }

  return (
    <MantineTabs
      ref={forwardedRef}
      onChange={onChange}
      __vars={{
        '--tabs-styled-bg-color': styledProps?.bg || '',
        '--tabs-styled-active-color': styledProps?.activeColor || '',
        '--tabs-styled-active-border-color':
          styledProps?.activeBorderColor || 'inherit',
        '--tabs-styled-color': styledProps?.color || '',
        '--tabs-styled-border-color': styledProps?.borderColor || 'transparent',
        '--tabs-styled-border-width': (styledProps?.borderWidth || 2).toString()
      }}
      className={cx(className, classes.tabs)}
      data-variant={variant}
      variant={variant === 'styled' ? 'default' : variant}
      styles={{
        tab: {
          padding: `calc(var(--mantine-spacing-${tabSize}) - 0.125rem) calc(var(--mantine-spacing-${tabSize}) + 0.125rem)`,
          fontSize: `var(--mantine-font-size-${tabSize})`
        }
      }}
      {...rest}
    >
      {children}
    </MantineTabs>
  );
}

const _ForwardedTabs = forwardRef(_Tabs) as <TValue extends string = string>(
  props: TabsProps<TValue> & { ref?: ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof _Tabs>;

const ForwardedTabs = _ForwardedTabs as any;
ForwardedTabs.List = MantineTabs.List;
ForwardedTabs.Tab = MantineTabs.Tab;
ForwardedTabs.Panel = MantineTabs.Panel;

//https://mantine.dev/core/tabs/
export const Tabs = ForwardedTabs as typeof _ForwardedTabs & {
  List: typeof MantineTabs.List;
  Tab: typeof MantineTabs.Tab;
  Panel: typeof MantineTabs.Panel;
};
