import {
  ElementProps as MantineElementProps,
  MantineSize
} from '@mantine/core';
import cx from 'clsx';
import { FC, ReactNode } from 'react';

import { useTheme } from '../../theme/useTheme';
import { ActionButton } from '../ActionButton';
import { Button, ButtonProps } from '../Button';
import { Group } from '../Group';
import { ChevronDownIcon } from '../Icons/ChevronDownIcon';
import { Menu, MenuItemProps } from '../Menu';
import classes from './SplitButton.module.css';

export interface SplitButtonProps
  extends Omit<ButtonProps, 'component' | 'size'> {
  size?: MantineSize;
  options: SplitButtonOption[];
}

export interface SplitButtonOption
  extends Omit<MenuItemProps, 'children'>,
    Pick<MantineElementProps<'button'>, 'onClick'> {
  label: ReactNode;
}

export const SplitButton: FC<SplitButtonProps> = ({
  options,
  color,
  children,
  variant = 'filled',
  disabled,
  size = 'sm',
  classNames = {},
  ...rest
}) => {
  const theme = useTheme();
  const buttonSize = getIconSizeFromButtonSize(size);
  return (
    <Group align="center" gap={0}>
      <Button
        size={size}
        color={color}
        variant={variant}
        disabled={disabled}
        classNames={{
          ...classNames,
          root: cx(classes.root, (classNames as unknown as any)?.root)
        }}
        {...rest}
      >
        {children}
      </Button>
      <Menu
        transitionProps={{ transition: 'pop-top-right' }}
        disabled={disabled}
        position="bottom-end"
        withinPortal
        zIndex={1100}
      >
        <Menu.Target>
          <ActionButton
            variant={variant}
            disabled={disabled}
            size={buttonSize}
            color={color || theme.primaryColor}
            className={classes.control}
          >
            <ChevronDownIcon size={buttonSize <= 36 ? '0.8rem' : undefined} />
          </ActionButton>
        </Menu.Target>
        <Menu.Dropdown>
          {options.map(({ label, ...rest }, index) => (
            <Menu.Item key={index} {...rest}>
              {label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

const getIconSizeFromButtonSize = (size: MantineSize) => {
  switch (size) {
    case 'xs':
      return 30;

    case 'sm':
      return 36;

    case 'md':
      return 42;

    case 'lg':
      return 50;

    case 'xl':
      return 60;
  }
};
