import React, { FC } from 'react';

import { IconProps } from './IconProps';
import { getDefaultColor, IconSvg } from './IconSvg';

export const ArrowLeftIcon: FC<IconProps> = ({ color, ...rest }) => {
  return (
    <IconSvg {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12Z"
        fill={color || getDefaultColor()}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7071 5.29289C11.0976 5.68342 11.0976 6.31658 10.7071 6.70711L5.41421 12L10.7071 17.2929C11.0976 17.6834 11.0976 18.3166 10.7071 18.7071C10.3166 19.0976 9.68342 19.0976 9.29289 18.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929L9.29289 5.29289C9.68342 4.90237 10.3166 4.90237 10.7071 5.29289Z"
        fill={color || getDefaultColor()}
      />
    </IconSvg>
  );
};
