import { useRouter } from 'next/router';
import { parse, ParsedUrlQuery } from 'querystring';
import { useEffect, useState } from 'react';

const getQueryParamsFromUrl = (url: string) => {
  const split = url.split('?');
  const q = split.length > 1 ? split[1] : '';
  return !q ? {} : (parse(q) as any);
};

export const usePreviousNextQueryParams = () => {
  const { query, asPath } = useRouter();
  const [queryParamHistory, setQueryParamHistory] = useState<ParsedUrlQuery[]>([
    query
  ]);

  //Keeping track of the last 2 routes
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setQueryParamHistory((previous) => {
        const newValues = [...previous];
        newValues.unshift(getQueryParamsFromUrl(url));
        if (newValues.length > 2) newValues.splice(-1, 1);
        return newValues;
      });
    };
    handleRouteChange(asPath);
  }, [asPath]);

  return queryParamHistory;
};
