import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';

import { Predictive } from '@/common/models/predictive/Predictive';
import { PredictiveOptionPoints } from '@/common/models/predictive/PredictiveOptionPoints';

export class PredictiveQuestionPointsUpdatedEvent {
  cardId: Guid;
  questionId: Guid;
  optionPoints: PredictiveOptionPoints[];

  constructor(props?: Partial<PredictiveQuestionPointsUpdatedEvent>) {
    props = props || {};
    Object.assign(this, props);
    this.cardId = Guid.valueOrNew(props.cardId);
    this.questionId = Guid.valueOrNew(props.questionId);
    this.optionPoints = mapArray(
      props.optionPoints,
      (o) => new PredictiveOptionPoints(o)
    );
  }

  canHandle(cardId: Guid | string) {
    return this.cardId.equals(cardId);
  }

  applyChange(predictive: Predictive) {
    const newPredictive = new Predictive(predictive);
    const question = newPredictive.getQuestion(this.questionId);
    if (question) {
      question.updateOptionPoints(this.optionPoints);
    }
    return newPredictive;
  }
}
