import { Guid } from '@komo-tech/core/models/Guid';
import { utcDateOrNow } from '@komo-tech/core/utils/date';

import { SiteMenuItemProperties } from './shared/SiteMenuItemProperties';

export class SiteMenuItem {
  id: Guid;
  siteMenuId: Guid;
  pageId?: Guid;
  name: string;
  url?: string;
  updatedAt: Date;
  properties: SiteMenuItemProperties;

  constructor(props?: Partial<SiteMenuItem>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.siteMenuId = Guid.valueOrUndefined(props.siteMenuId);
    this.pageId = Guid.valueOrUndefined(props.pageId);
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.properties = new SiteMenuItemProperties(props.properties);
  }
}
