import {
  __InputWrapperProps as MantineInputWrapperBaseProps,
  InputWrapper as MantineInputWrapper,
  SegmentedControl as MantineSegmentedControl,
  SegmentedControlProps as MantineSegmentedControlProps
} from '@mantine/core';
import { randomId as mantineRandomId } from '@mantine/hooks';
import { forwardRef, useState } from 'react';

import { useOnMount } from '../../../hooks/useOnMount';
import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '../InputWrapper/FormInputWrapper';

export interface FormSegmentedControlProps
  extends Omit<MantineSegmentedControlProps, 'size'>,
    Omit<MantineInputWrapperBaseProps, 'labelProps' | 'descriptionProps'>,
    Omit<IAmFormInputRenderProps<HTMLDivElement>, 'selectAllTextOnFocus'> {
  required?: boolean;
}

export const FormSegmentedControl = forwardRef<
  HTMLDivElement,
  FormSegmentedControlProps
>(
  (
    {
      onFocus,
      autoFocus,
      description,
      descriptionProps,
      inputContainer,
      labelProps,
      showSkeleton,
      label,
      size,
      required,
      error,
      mt,
      hideErrorMessage,
      styles,
      w,
      ...rest
    },
    forwardedRef
  ) => {
    //Weird issue when segment control is in a modal/popover, the default height of the
    //pill is not rendering the correct height
    const [key, setKey] = useState('none');
    useOnMount(() => {
      setTimeout(() => setKey(mantineRandomId()), 250);
    });

    return (
      <FormInputWrapper<HTMLDivElement>
        ref={forwardedRef}
        autoFocus={autoFocus}
        onFocus={onFocus}
        size={size}
        inputContainer={inputContainer}
        labelProps={labelProps}
        descriptionProps={descriptionProps}
        showSkeleton={showSkeleton}
        error={error}
        hideErrorMessage={hideErrorMessage}
      >
        {(p, ref) => (
          <MantineInputWrapper
            withAsterisk={required}
            label={label}
            mt={mt}
            description={description}
            w={w}
            {...p}
          >
            <MantineSegmentedControl
              ref={ref}
              key={key}
              size={size}
              styles={styles}
              fullWidth
              {...rest}
            />
          </MantineInputWrapper>
        )}
      </FormInputWrapper>
    );
  }
);
