import * as signalR from '@microsoft/signalr';

const DEFAULT_RETRY_DELAYS_IN_MILLISECONDS = [0, 2000, 10000, 30000, 30000];

export class InfiniteReconnectPolicy implements signalR.IRetryPolicy {
  private readonly retryDelays: Array<number | null>;

  constructor(retryDelays?: number[]) {
    this.retryDelays =
      retryDelays !== undefined
        ? [...retryDelays, null]
        : DEFAULT_RETRY_DELAYS_IN_MILLISECONDS;
  }

  public nextRetryDelayInMilliseconds(
    retryContext: signalR.RetryContext
  ): number | null {
    const i =
      retryContext.previousRetryCount < this.retryDelays.length - 1
        ? retryContext.previousRetryCount
        : this.retryDelays.length - 1;

    return this.retryDelays[i];
  }
}
