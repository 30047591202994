import { UiSize } from '@komo-tech/ui/theme/types';
import { FC } from 'react';

import { SiteAuthState } from '@/common/components/Card/shared/Front/Routing/AuthSiteUrlFunctions';
import { Site } from '@/common/models/site/Site';
import { SiteUser } from '@/common/models/SiteUser';
import {
  AuthButton,
  resolveAuthButtonProps
} from '@/front/components/auth/Shared';

interface Props {
  site: Site;
  disabled?: boolean;
  onStateChange: (state: SiteAuthState) => void;
  onSignOut: () => void;
  user: SiteUser;
  state: SiteAuthState;
  size?: UiSize;
}

type DrawerButtonText = 'Sign in' | 'Sign up' | 'Sign out';

export const DrawerHeaderAuthButton: FC<Props> = ({
  state,
  onSignOut,
  onStateChange,
  user,
  site,
  size,
  ...rest
}) => {
  let text: DrawerButtonText = 'Sign up';
  if (user.isAnonymous) {
    if (state === SiteAuthState.SignUp) {
      text = 'Sign in';
    }
  } else {
    text = 'Sign out';
  }

  const handleClick = () => {
    switch (text) {
      case 'Sign in':
        onStateChange(SiteAuthState.SignIn);
        break;
      case 'Sign up':
        onStateChange(SiteAuthState.SignUp);
        break;
      case 'Sign out':
        onSignOut();
        break;
    }
  };

  return (
    <AuthButton
      {...rest}
      onClick={handleClick}
      {...resolveAuthButtonProps(site, 'secondary')}
      size={size}
      label={text}
    />
  );
};
