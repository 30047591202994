import {
  ActionIconGroup as MantineActionIconGroup,
  Box as MantineBox,
  BoxProps as MantineBoxProps,
  createVarsResolver as createMantineVarsResolver,
  getRadius as getMantineRadius,
  getSize as getMantineSize,
  MantineColor,
  MantineGradient,
  MantineRadius,
  MantineSize,
  PolymorphicFactory as MantinePolymorphicFactory,
  polymorphicFactory as mantinePolymorphicFactory,
  StylesApiProps as MantineStylesApiProps,
  UnstyledButton as MantineUnstyledButton,
  useProps as useMantineProps,
  useStyles as useMantineStyles
} from '@mantine/core';
import React, { CSSProperties } from 'react';

import { useEvent } from '../../hooks/useEvent';
import {
  ButtonVariants as ButtonVariants,
  IMayHaveStyledButtonVariantProps,
  resolveButtonStyledVariantColors,
  StyledButtonVariantProps
} from '../Button/Button.styles';
import { OptionalSkeleton } from '../Skeleton';
import { Spinner, SpinnerProps } from '../Spinner';
import { OptionalTooltip, TooltipProps } from '../Tooltip';
import classes from './ActionButton.module.css';

export type ActionButtonStylesNames = 'root' | 'loader';
export type ActionButtonCssVariables = {
  root:
    | '--ai-radius'
    | '--ai-size'
    | '--ai-bg'
    | '--ai-hover'
    | '--ai-hover-color'
    | '--ai-color'
    | '--ai-bd';
};

interface Empty {}

export interface ActionButtonTooltipProps
  extends Omit<TooltipProps, 'children'> {
  showWhenDisabled?: boolean;
}

type InputSizes =
  | 'input-xs'
  | 'input-sm'
  | 'input-md'
  | 'input-lg'
  | 'input-xl';

export type ActionButtonSizes =
  | MantineSize
  | InputSizes
  | (string & Empty)
  | number;

export interface ActionButtonProps
  extends MantineBoxProps,
    MantineStylesApiProps<ActionButtonFactory> {
  __staticSelector?: string;

  /** Determines whether `Loader` component should be displayed instead of the `children`, `false` by default */
  busy?: boolean;

  /** Props added to the `Loader` component (only visible when `loading` prop is set) */
  busyProps?: SpinnerProps;

  /** Controls width and height of the button. Numbers are converted to rem. `'md'` by default. */
  size?: ActionButtonSizes;

  /** Key of `theme.colors` or any valid CSS color. Default value is `theme.primaryColor`.  */
  color?: MantineColor;

  /** Key of `theme.radius` or any valid CSS value to set border-radius. Numbers are converted to rem. `theme.defaultRadius` by default. */
  radius?: MantineRadius;

  /** Gradient data used when `variant="gradient"`, default value is `theme.defaultGradient` */
  gradient?: MantineGradient;

  /** Sets `disabled` and `data-disabled` attributes on the button element */
  disabled?: boolean;

  /** Determines whether button text color with filled variant should depend on `background-color`. If luminosity of the `color` prop is less than `theme.luminosityThreshold`, then `theme.white` will be used for text color, otherwise `theme.black`. Overrides `theme.autoContrast`. */
  autoContrast?: boolean;

  //Wraps button in loading skeleton
  showSkeleton?: boolean;

  /** Icon displayed inside the button */
  children?: React.ReactNode;

  tooltip?: ActionButtonTooltipProps;

  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  href?: string;

  target?: string;

  tabIndex?: number;

  as?: React.ElementType;

  styledVariantProps?: StyledButtonVariantProps;
}

export type ActionButtonFactory = MantinePolymorphicFactory<{
  props: ActionButtonProps;
  defaultComponent: 'button';
  defaultRef: HTMLButtonElement;
  stylesNames: ActionButtonStylesNames;
  variant: ButtonVariants;
  vars: ActionButtonCssVariables;
  staticComponents: {
    Group: typeof MantineActionIconGroup;
  };
}>;

const defaultProps: Partial<ActionButtonProps> = {};

const varsResolver = createMantineVarsResolver<ActionButtonFactory>(
  (theme, p) => {
    const {
      styledVariantProps: styledProps,
      size,
      gradient,
      radius,
      autoContrast
    } = p;
    let color = p.color;
    let variant = !!styledProps ? 'styled' : p.variant;

    if (!color && (variant === 'subtle' || variant === 'transparent')) {
      color = 'gray';
    }

    if ((variant || '').endsWith('-danger')) {
      color = 'red';
    } else if ((variant || '').endsWith('-success')) {
      color = 'green';
    }

    if ((variant || '').startsWith('filled-')) {
      variant = 'filled';
    } else if ((variant || '').startsWith('light-')) {
      variant = 'light';
    }

    const colors =
      variant === 'styled'
        ? resolveButtonStyledVariantColors({
            theme,
            styledVariantProps: styledProps
          })
        : theme.variantColorResolver({
            color: color || theme.primaryColor,
            theme,
            gradient,
            variant: variant || 'filled',
            autoContrast
          });

    return {
      root: {
        '--ai-size': getMantineSize(size, 'ai-size'),
        '--ai-radius':
          radius === undefined ? undefined : getMantineRadius(radius),
        '--ai-bg': color || variant ? colors.background : undefined,
        '--ai-hover': color || variant ? colors.hover : undefined,
        '--ai-hover-color': color || variant ? colors.hoverColor : undefined,
        '--ai-color': color || variant ? colors.color : undefined,
        '--ai-bd': color || variant ? colors.border : undefined
      }
    };
  }
);

type ActionButtonReturnType = ReturnType<
  typeof mantinePolymorphicFactory<ActionButtonFactory>
>;

export const ActionButton = mantinePolymorphicFactory<ActionButtonFactory>(
  (_props, ref) => {
    const props = useMantineProps('ActionButton', defaultProps, _props);
    const {
      className,
      unstyled,
      classNames,
      styles,
      style,
      busy,
      busyProps,
      size,
      __staticSelector,
      vars,
      children,
      disabled: disabledProp,
      tooltip,
      onClick,
      tabIndex,
      showSkeleton,

      //These are used in the variant resolver
      color,
      radius,
      gradient,
      styledVariantProps,
      variant,
      as,

      ...others
    } = props;

    // The default color from 'useComponentDefaultProps' will default to themes primary color
    // However for variants subtle and transparent we want the default to be gray
    if (variant === 'subtle' || variant === 'transparent') {
      props.color = _props.color || 'gray';
    }

    const getStyles = useMantineStyles<ActionButtonFactory>({
      name: ['ActionButton', __staticSelector],
      props,
      className,
      style,
      classes,
      classNames,
      styles,
      unstyled,
      vars,
      varsResolver
    });

    const handleClick = useEvent((e: React.MouseEvent<HTMLElement>) => {
      if (disabled) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      onClick?.(e as any);
    });

    if (as) {
      (others as any).component = as;
    }

    if (others.href && !(others as any).component) {
      (others as any).component = 'a';
    }

    const disabled = disabledProp || busy || showSkeleton || false;

    const { showWhenDisabled, ...tooltipProps } = tooltip || {};

    const tooltipHidden = disabled && !showWhenDisabled;

    const disabledDataProps: CSSProperties = {};
    if (disabled) {
      disabledDataProps['aria-disabled'] = '';
      disabledDataProps['data-disabled'] = '';
    }

    return (
      <OptionalSkeleton visible={showSkeleton}>
        <OptionalTooltip disabled={tooltipHidden} {...tooltipProps}>
          <MantineUnstyledButton
            {...getStyles('root', {
              active: !disabled
            })}
            {...(others as any)}
            unstyled={unstyled}
            variant={variant}
            size={size}
            ref={ref}
            mod={{ loading: busy, disabled: disabled }}
            tabIndex={disabled ? -1 : tabIndex}
            onClick={handleClick}
            {...disabledDataProps}
          >
            {busy ? (
              <MantineBox component="span" {...getStyles('loader')} aria-hidden>
                <Spinner
                  size="calc(var(--ai-size) * 0.55)"
                  color="var(--ai-color)"
                  {...busyProps}
                />
              </MantineBox>
            ) : (
              children
            )}
          </MantineUnstyledButton>
        </OptionalTooltip>
      </OptionalSkeleton>
    );
  }
) as ActionButtonReturnType;

ActionButton.classes = classes;
ActionButton.displayName = 'ActionButton';
ActionButton.Group = MantineActionIconGroup;
