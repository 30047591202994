import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { CloseButton } from '@komo-tech/ui/CloseButton';
import { Group } from '@komo-tech/ui/Group';
import { Stack } from '@komo-tech/ui/Stack';
import React, { FC, forwardRef, ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';

import { Image } from '@/common/components/Image';

import classes from './CardModalTopNav.module.css';

interface Props {
  backgroundColor?: string;
  background?: string;
  onClose?: () => void;
  brandImage?: ImageDataModel;
  color?: string;
  disableSticky?: boolean;
  zIndex?: number;
  title?: ReactNode;
  animate?: boolean;
  placeholderIcon?: ReactNode;
}

export const CardModalTopNavHeight = 70;

export const CardModalTopNav = forwardRef<HTMLDivElement, Props>(
  (
    {
      color,
      brandImage,
      backgroundColor,
      background,
      onClose,
      disableSticky,
      zIndex,
      title,
      animate,
      placeholderIcon
    },
    ref
  ) => {
    const style = useSpring({
      from: {
        height: animate ? 0 : 'auto'
      },
      to: {
        height: 'auto'
      }
    });

    return (
      <animated.div
        ref={ref}
        style={{ ...style, background, backgroundColor, zIndex }}
        data-disable-sticky={disableSticky}
        className={classes.root}
      >
        {title}
        <span className={classes.topNavBtn} />
        <Stack className={classes.logo}>
          <Stack className={classes.wrapper}>
            {!!brandImage && (
              <Image image={brandImage} className={classes.image} />
            )}
            {!brandImage && !!placeholderIcon && (
              <Group className={classes.placeholder}>{placeholderIcon}</Group>
            )}
          </Stack>
        </Stack>
        <CardModalTopCloseButton onClick={onClose} color={color} />
      </animated.div>
    );
  }
);

interface CardModalTopCloseButtonProps {
  onClick: () => void;
  color?: string;
}

export const CardModalTopCloseButton: FC<CardModalTopCloseButtonProps> = ({
  onClick,
  color
}) => {
  return (
    <CloseButton
      aria-label="Close modal"
      variant="transparent"
      size="xl"
      onClick={onClick}
      c={color}
    />
  );
};

export const CardModalTopNavPadding = '0.875rem 1rem 1rem';
export const CardModalTopNavLogoMaxHeight = '40px';
