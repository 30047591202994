'use client';
import { ComponentType, lazy, useEffect, useState } from 'react';

import { NoSsr } from '../NoSsr';
import type { LottiePlayerProps } from './LottiePlayer.types';

/**
 * @see https://github.com/LottieFiles/lottie-react
 */
const _LottiePlayer = lazy<ComponentType<LottiePlayerProps>>(() =>
  import('@lottiefiles/react-lottie-player').then((x) => ({
    default: x.Player
  }))
);

export const LottiePlayer = (props: LottiePlayerProps) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <NoSsr>
      <_LottiePlayer {...props} />
    </NoSsr>
  );
};
