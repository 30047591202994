import { removeDuplicates, tryAddToArray } from '@komo-tech/core/utils/array';
import {
  asSingularOrPlural,
  capitaliseFirstWord
} from '@komo-tech/core/utils/string';
import { useVirtualizer } from '@tanstack/react-virtual';
import { FC, ReactNode, useMemo, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useEvent } from '../../../hooks/useEvent';
import { useFlag } from '../../../hooks/useFlag';
import { useLatest } from '../../../hooks/useLatest';
import { ActionButton, ActionButtonProps } from '../../ActionButton';
import { Box } from '../../Box';
import { Button } from '../../Button';
import { Flex } from '../../Flex';
import { BulkAddPopover } from '../../Form/BulkAddPopover';
import { FormHook } from '../../Form/Hook';
import { FormHookField } from '../../Form/HookField';
import { FormHookTextInput, FormTextInputProps } from '../../Form/TextInput';
import { FormSearchInput } from '../../Form/TextInput/FormSearchInput';
import { Group } from '../../Group';
import { DeleteIcon } from '../../Icons/DeleteIcon';
import { ListIcon } from '../../Icons/ListIcon';
import { PlusIcon } from '../../Icons/PlusIcon';
import { List } from '../../List/List';
import { ListItem } from '../../List/ListItem';
import { ListItemAction } from '../../List/ListItemAction';
import { ListItemText } from '../../List/ListItemText';
import { Popover } from '../../Popover';
import { SplitButton } from '../../SplitButton';
import { Stack } from '../../Stack';
import { Text } from '../../Text';

export interface ValueListEditorProps<TValue = string> {
  items: TValue[];
  valueKey?: (a: TValue) => string;
  resolveValue: (value: string) => TValue;
  sanitizeValue?: (value: string) => string;
  onChange: (values: TValue[]) => void;
  actionsFn?: (
    value: TValue,
    iconProps: Partial<ActionButtonProps>
  ) => ReactNode;
  disabled?: boolean;
  itemName?: string;
  emptyLabel?: ReactNode;
  bulkAddPlaceholder?: string;
  addPlaceholder?: string;
  hideEmptyList?: boolean;
  withSearch?: boolean;
  popoverWithinPortal?: boolean;
}

const DefaultValueKey = (x) => x as any as string;

export function ValueListEditor<TValue = string>({
  items = [],
  onChange,
  disabled,
  resolveValue,
  valueKey = DefaultValueKey,
  actionsFn,
  itemName = 'option',
  bulkAddPlaceholder,
  addPlaceholder,
  emptyLabel,
  hideEmptyList = false,
  withSearch = true,
  popoverWithinPortal
}: ValueListEditorProps<TValue>) {
  const [search, setSearch] = useState('');
  const handleRemove = useEvent((item: TValue) => {
    onChange((items || []).filter((x) => x !== item));
  });

  const handleAdd = useEvent((newItems: TValue[]) => {
    const updated = [...(items || [])];
    newItems.forEach((i) => {
      tryAddToArray(updated, i, (x) => valueKey(x) === valueKey(i));
    });
    onChange(removeDuplicates([...(items || []), ...(newItems || [])]));
  });

  const handleClear = useEvent(() => {
    onChange([]);
  });

  const hasValues = items?.length > 0;

  const valueKeyRef = useLatest(valueKey);

  const { orderedItems, count, totalCount } = useMemo(() => {
    let i = items || [];
    if (search) {
      i = i.filter((x) =>
        valueKeyRef.current(x).toLowerCase().includes(search.toLowerCase())
      );
    }
    const orderedItems = i?.sort((a, b) =>
      valueKeyRef.current(a).localeCompare(valueKeyRef.current(b))
    );
    return {
      orderedItems,
      count: orderedItems.length,
      totalCount: items.length
    };
  }, [items, search]);

  const hideList = hideEmptyList && !hasValues;

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 38.5
  });

  const virtualItems = virtualizer.getVirtualItems();
  const hasVirtualItems = virtualItems.length > 0;
  const hasExtraActions = !!actionsFn;

  return (
    <Stack w="100%" flex="1 1 auto" gap={6}>
      {!hideList && (
        <>
          {hasValues ? (
            <>
              {withSearch && (
                <FormSearchInput
                  disabled={disabled}
                  placeholder="Search..."
                  value={search}
                  onChange={(_, v) => setSearch(v)}
                  withClear
                />
              )}
              <Box
                style={{
                  border: `solid ${hasVirtualItems ? '1' : '0'}px var(--color-body-border)`,
                  borderRadius: 'var(--mantine-radius-sm)',
                  overflow: 'auto'
                }}
                mah="12.5rem"
                ref={parentRef}
              >
                <List
                  h={virtualizer.getTotalSize() + 1}
                  itemStyles={{
                    noGap: true,
                    noShadow: true,
                    padding: '4px 8px'
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${virtualItems[0]?.start ?? 0}px)`
                    }}
                  >
                    {virtualItems.map((virtualItem) => (
                      <ListItem
                        key={virtualItem.key}
                        data-index={virtualItem.index}
                        ref={virtualizer.measureElement}
                      >
                        <ListItemText>
                          {valueKey(orderedItems[virtualItem.index])}
                        </ListItemText>
                        <ListItemAction>
                          {hasExtraActions &&
                            actionsFn(orderedItems[virtualItem.index], {
                              size: 'md',
                              disabled
                            })}
                          <ActionButton
                            tooltip={{ label: 'Remove' }}
                            variant="light-danger"
                            disabled={disabled}
                            size="md"
                            onClick={() =>
                              handleRemove(orderedItems[virtualItem.index])
                            }
                          >
                            <DeleteIcon />
                          </ActionButton>
                        </ListItemAction>
                      </ListItem>
                    ))}
                  </div>
                </List>
              </Box>
            </>
          ) : (
            <Text p={'4px 8px'} c={'dimmed'} fz={'0.75rem'}>
              {emptyLabel ?? `No ${itemName}s yet...`}
            </Text>
          )}
        </>
      )}

      <Group align="center" gap={'0.5rem'} justify="space-between">
        <Group align="center" gap={'0.5rem'}>
          <_AddPopover
            itemName={itemName}
            onAdd={(v) => handleAdd(v.map(resolveValue))}
            disabled={disabled}
            addPlaceholder={addPlaceholder}
            withinPortal={popoverWithinPortal}
            bulkAddPlaceholder={bulkAddPlaceholder}
          />
          <_ClearPopover
            itemName={itemName}
            onClear={handleClear}
            disabled={disabled || !hasValues}
          />
        </Group>
        {count > 0 && (
          <Text size="xs" c="dimmed">
            {totalCount.toLocaleString()} {asSingularOrPlural(itemName, count)}
          </Text>
        )}
      </Group>
    </Stack>
  );
}

interface AddPopoverProps
  extends Pick<
    ValueListEditorProps,
    'disabled' | 'addPlaceholder' | 'bulkAddPlaceholder'
  > {
  onAdd: (value: string[]) => void;
  itemName: string;
  withinPortal?: boolean;
}

interface AddFormValue {
  value: string;
}

const _AddPopover: FC<AddPopoverProps> = ({
  onAdd,
  disabled,
  itemName,
  bulkAddPlaceholder,
  addPlaceholder,
  withinPortal
}) => {
  const isOpen = useFlag(false);
  const [variant, setVariant] = useState<'single' | 'bulk'>('single');

  return (
    <>
      <Popover
        withArrow
        withinPortal={withinPortal}
        opened={isOpen.value}
        onChange={(v) => isOpen.set(v)}
        trapFocus
        shadow={'md'}
        target={({ opened, setOpened }) => (
          // warning - wrapping in a tooltip may break popover positioning
          <div>
            <SplitButton
              variant="default"
              size="xs"
              disabled={disabled}
              leftSection={<PlusIcon size={'0.8rem'} />}
              onClick={() => {
                setVariant('single');
                setOpened(!opened);
              }}
              options={[
                {
                  label: 'Add Multiple',
                  leftSection: <ListIcon />,
                  onClick: () => {
                    setVariant('bulk');
                    setOpened(!opened);
                  }
                }
              ]}
            >
              Add
            </SplitButton>
          </div>
        )}
      >
        {variant === 'single' ? (
          <_AddSingleForm
            onAdd={(v) => onAdd([v])}
            onCancel={isOpen.setFalse}
            disabled={disabled}
            itemName={itemName}
            placeholder={addPlaceholder}
          />
        ) : (
          <BulkAddPopover.Form
            onAdd={onAdd}
            placeholder={bulkAddPlaceholder}
            onCancel={isOpen.setFalse}
            disabled={disabled}
            itemName={itemName}
          />
        )}
      </Popover>
    </>
  );
};

interface AddSingleFormProps
  extends Pick<FormTextInputProps, 'disabled' | 'placeholder'> {
  onAdd: (value: string) => void;
  itemName: string;
  onCancel: () => void;
}

const _AddSingleForm: FC<AddSingleFormProps> = ({
  onAdd,
  disabled,
  itemName,
  placeholder,
  onCancel
}) => {
  const formRef = useRef<UseFormReturn<AddFormValue>>();

  const handleSubmit = ({ value }: AddFormValue) => {
    onAdd(value.trim());
    formRef.current.reset({ value: '' });
    setTimeout(() => {
      formRef.current.setFocus('value');
    }, 50);
  };

  return (
    <Stack p={'0.5rem 1rem'} gap={'lg'}>
      <FormHook<AddFormValue>
        stopPropagationOnSubmit
        onInit={(f) => (formRef.current = f)}
        onSubmit={handleSubmit}
        defaultValues={{ value: '' }}
        withHiddenSubmit={false}
      >
        <FormHookField<AddFormValue>
          name={'value'}
          required={{ enabled: true }}
          disabled={disabled}
        >
          {(h) => (
            <FormHookTextInput
              size="xs"
              hook={h}
              autoFocus
              placeholder={placeholder}
              label={capitaliseFirstWord(itemName)}
            />
          )}
        </FormHookField>
      </FormHook>
      <Flex direction="row-reverse" gap="0.5rem">
        <Button
          variant="default"
          disabled={disabled}
          size="xs"
          onClick={() => formRef.current.handleSubmit(handleSubmit)()}
        >
          Add
        </Button>
        <Button onClick={onCancel} variant={'subtle'} size="xs" color={'gray'}>
          Cancel
        </Button>
      </Flex>
    </Stack>
  );
};

interface ClearPopoverProps {
  onClear: () => void;
  disabled?: boolean;
  itemName: string;
}

const _ClearPopover: FC<ClearPopoverProps> = ({
  onClear,
  disabled,
  itemName
}) => {
  const isOpen = useFlag(false);

  const handleConfirm = () => {
    onClear();
    isOpen.setFalse();
  };

  return (
    <Popover
      withArrow
      withinPortal
      opened={isOpen.value}
      onChange={(v) => isOpen.set(v)}
      trapFocus
      shadow={'md'}
      target={({ opened, setOpened }) => (
        // warning - wrapping in a tooltip may break popover positioning
        <Button
          size={'xs'}
          variant="light-danger"
          disabled={disabled}
          leftSection={<DeleteIcon />}
          onClick={() => setOpened(!opened)}
        >
          Clear all
        </Button>
      )}
    >
      <Stack p={'0.5rem 1rem'} gap={'lg'}>
        <Text>Are you sure you want to clear all {itemName}s?</Text>
        <Group gap="0.5rem" justify={'flex-end'}>
          <Button
            onClick={() => isOpen.setFalse()}
            variant={'subtle'}
            color={'gray'}
            size="xs"
          >
            Cancel
          </Button>
          <Button
            size="xs"
            disabled={disabled}
            color={'red'}
            variant="light"
            onClick={handleConfirm}
          >
            Yes, clear all
          </Button>
        </Group>
      </Stack>
    </Popover>
  );
};
