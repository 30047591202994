import { FC, ReactNode, useState } from 'react';

import { useFlag } from '../../../hooks/useFlag';
import { Button } from '../../Button';
import { Flex } from '../../Flex';
import { ListIcon } from '../../Icons/ListIcon';
import { Popover, PopoverTargetProps } from '../../Popover';
import { Stack } from '../../Stack';
import { FormTextArea } from '../TextArea';

interface BulkAddPopoverFormProps {
  onAdd: (values: string[]) => void;
  onCancel: () => void;
  disabled?: boolean;
  placeholder?: string;
  itemName?: string;
}

const BulkAddPopoverForm: FC<BulkAddPopoverFormProps> = ({
  onAdd,
  onCancel,
  disabled,
  placeholder,
  itemName = 'option'
}) => {
  const [options, setOptions] = useState<string>('');

  const canLoad = !!options;
  const handleLoad = () => {
    if (!canLoad) return;

    onAdd(
      options
        .split(/\r\n|\r|\n/)
        .filter((x) => !!x)
        .map((x) => x.trim())
    );

    setOptions('');
  };

  return (
    <Stack px="1rem" py="0.5rem" gap={'md'}>
      <FormTextArea
        label={`Place each ${itemName} on their own line`}
        placeholder={placeholder}
        value={options}
        minRows={10}
        autosize
        maxRows={10}
        onChange={(_, value) => setOptions(value)}
      />
      <Flex direction="row-reverse" gap="0.5rem">
        <Button
          size="xs"
          variant="default"
          disabled={disabled || !canLoad}
          tooltip={
            !canLoad
              ? {
                  label: `Must have at least one ${itemName}`,
                  showWhenDisabled: true
                }
              : undefined
          }
          onClick={handleLoad}
        >
          Add
        </Button>
        <Button onClick={onCancel} variant={'subtle'} color={'gray'} size="xs">
          Cancel
        </Button>
      </Flex>
    </Stack>
  );
};

export interface BulkAddPopoverProps
  extends Omit<BulkAddPopoverFormProps, 'onCancel'> {
  /**
   * Override the default popover target. Target must have a forward ref
   */
  target?: (props: PopoverTargetProps) => ReactNode;
}

export function BulkAddPopover({
  disabled,
  target,
  itemName = 'option',
  ...rest
}: BulkAddPopoverProps) {
  const isOpen = useFlag(false);

  return (
    <Popover
      withArrow
      withinPortal
      opened={isOpen.value}
      onChange={(v) => isOpen.set(v)}
      trapFocus
      shadow={'md'}
      target={
        target
          ? target
          : ({ opened, setOpened }) => (
              // warning - wrapping in a tooltip may break popover positioning
              <Button
                size={'xs'}
                variant="default"
                disabled={disabled}
                onClick={() => setOpened(!opened)}
                leftSection={<ListIcon />}
              >
                Add {itemName}s
              </Button>
            )
      }
    >
      <BulkAddPopoverForm
        itemName={itemName}
        onCancel={isOpen.setFalse}
        disabled={disabled}
        {...rest}
      />
    </Popover>
  );
}

BulkAddPopover.Form = BulkAddPopoverForm;
