import {
  __InputWrapperProps as MantineInputWrapperBaseProps,
  InputWrapper as MantineInputWrapper
} from '@mantine/core';
import { forwardRef, ReactNode } from 'react';

import { useFlag } from '../../../hooks/useFlag';
import { Button } from '../../Button';
import { Group } from '../../Group';
import { CorrectIconFilled } from '../../Icons/CorrectIconFilled';
import { QrCodeReaderProps } from '../../QrCode/Reader';
import { QrCodeReaderDrawer } from '../../QrCode/Reader/Drawer';
import { Text } from '../../Text';
import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '../InputWrapper/FormInputWrapper';

export interface FormQrCodeReaderProps {
  label?: ReactNode;
  error?: MantineInputWrapperBaseProps['error'];
  labelProps?: IAmFormInputRenderProps<HTMLElement>['labelProps'];
  description?: ReactNode;
  size?: IAmFormInputRenderProps<HTMLElement>['size'];
  id?: string;
  disabled?: boolean;
  onCheckAsync: QrCodeReaderProps['onCheckAsync'];
  onError?: QrCodeReaderProps['onError'];
  isValid?: boolean;
  isChecking?: boolean;
  required?: boolean;
}

export const FormQrCodeReader = forwardRef<
  HTMLButtonElement,
  FormQrCodeReaderProps
>(
  (
    { onCheckAsync, isChecking, isValid, size, error, id, required, disabled },
    ref
  ) => {
    const drawerOpen = useFlag();

    return (
      <>
        <FormInputWrapper<HTMLButtonElement> ref={ref} size={size}>
          {(p, r) => (
            <MantineInputWrapper
              withAsterisk={required}
              error={error}
              {...(p as any)}
              id={id}
            >
              {isValid ? (
                <Group align="center" gap="0.5rem">
                  <CorrectIconFilled color="var(--mantine-color-green-9)" />
                  <Text size="sm">Verified</Text>
                </Group>
              ) : (
                <Button
                  display="block"
                  variant="default"
                  w="100%"
                  id={id}
                  busy={isChecking}
                  disabled={disabled}
                  size={size}
                  style={
                    !!error
                      ? {
                          borderColor: 'var(--mantine-color-red-7)',
                          color: 'var(--mantine-color-red-7)'
                        }
                      : undefined
                  }
                  onClick={drawerOpen.setTrue}
                  ref={r}
                >
                  Scan QR Code
                </Button>
              )}
            </MantineInputWrapper>
          )}
        </FormInputWrapper>
        <QrCodeReaderDrawer
          isValid={isValid}
          onCheckAsync={onCheckAsync}
          onClose={drawerOpen.setFalse}
          opened={drawerOpen.value}
          title="Scan"
          infoContent="Point your camera at the QR code to scan"
          onIsValidCallback={drawerOpen.setFalse}
        />
      </>
    );
  }
);
