import { Guid } from '@komo-tech/core/models/Guid';
import { useReactQuerySync } from '@komo-tech/ui/hooks/useReactQuerySync';

import { Page } from '@/common/models/pages/Page';

import { PublicPageCacheKeys as CacheKeys } from '../_cacheKeys';

interface Options {
  pageId: Guid;
  onPageChange: (page: Page) => void;
}

export function usePublicPageCacheSync({ pageId, onPageChange }: Options) {
  useReactQuerySync({
    items: [
      {
        name: 's',
        cacheKey: CacheKeys.get(pageId),
        callback: onPageChange
      }
    ]
  });
}
