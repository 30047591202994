import { FC, ReactText } from 'react';

import { Box } from '../Box';
import classes from './HtmlRenderer.module.css';

interface Props {
  html: string;
  padding?: ReactText;
  backgroundColor?: string;
  color?: string;
  linkColor?: string;
  display?: string;
}
/**
 * @deprecated Try using HTMLRendererV2
 */
export const HtmlRenderer: FC<Props> = ({
  html,
  padding,
  backgroundColor,
  color,
  display,
  linkColor
}) => {
  return (
    <Box
      p={padding}
      __vars={{
        '--html-renderer-text-color': color,
        '--html-renderer-background-color': backgroundColor,
        '--html-renderer-link-color': linkColor || color || 'inherit'
      }}
      display={display}
      className={classes.root}
      dangerouslySetInnerHTML={{
        __html: html
      }}
    />
  );
};
