import {
  FormTextInput,
  FormTextInputProps
} from '@komo-tech/ui/Form/TextInput';
import { forwardRef } from 'react';

import { resolveFieldName } from './DynamicFieldAddress.types';

interface Props
  extends Pick<FormTextInputProps, 'error' | 'disabled' | 'size'> {
  fieldName: string;
  onChange: (value: string) => void;
  value: FormTextInputProps['value'];
}

export const SubPremiseField = forwardRef<HTMLInputElement, Props>(
  ({ onChange, fieldName, ...rest }, ref) => {
    return (
      <FormTextInput
        ref={ref}
        name={resolveFieldName(fieldName, 'subpremise')}
        label="Apartment, suite, etc."
        onChange={(_, v) => onChange(v)}
        {...rest}
      />
    );
  }
);
