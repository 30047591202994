'use client';
import {
  Anchor as MantineAnchor,
  AnchorFactory as MantineAnchorFactory,
  AnchorProps as MantineAnchorProps,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';
import isBoolean from 'lodash/isBoolean';
import { ReactNode } from 'react';

import { OptionalTooltip, TooltipProps } from '../Tooltip';

/**
 * @see https://mantine.dev/core/anchor/
 */

export interface AnchorTooltipProps extends Omit<TooltipProps, 'children'> {}

interface AnchorFactory extends Omit<MantineAnchorFactory, 'props'> {
  props: AnchorProps;
}

export interface AnchorProps extends Omit<MantineAnchorProps, 'underline'> {
  /**
   * @deprecated use ta instead
   */
  align?: MantineAnchorProps['ta'];

  underline?: MantineAnchorProps['underline'] | boolean;

  tooltip?: AnchorTooltipProps;

  children?: ReactNode;
}

type AnchorReturnType = ReturnType<
  typeof mantinePolymorphicFactory<AnchorFactory>
>;

/**
 * @see https://mantine.dev/core/anchor/
 */
export const Anchor = mantinePolymorphicFactory<AnchorFactory>(
  ({ children, underline: underlineProp, color, tooltip, ...rest }, ref) => {
    const underline = isBoolean(underlineProp)
      ? underlineProp
        ? 'always'
        : 'never'
      : underlineProp;

    return (
      <OptionalTooltip {...(tooltip || {})}>
        <MantineAnchor ref={ref} underline={underline} c={color} {...rest}>
          {children}
        </MantineAnchor>
      </OptionalTooltip>
    );
  }
) as AnchorReturnType;
