import { FC } from 'react';

import { IconProps } from '../IconProps';
import { IconSvg } from '../IconSvg';

export const InstagramIcon: FC<IconProps> = ({ color, ...rest }) => {
  return (
    <IconSvg {...rest}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 .2a11.8 11.8 0 100 23.6A11.8 11.8 0 0012 .2zM8.25 2.946a9.8 9.8 0 117.5 18.108 9.8 9.8 0 01-7.5-18.108zM14.667 6H9.333A3.337 3.337 0 006 9.333v5.334A3.337 3.337 0 009.333 18h5.334A3.337 3.337 0 0018 14.667V9.333A3.337 3.337 0 0014.667 6zm2 8.667c0 1.102-.898 2-2 2H9.333c-1.102 0-2-.898-2-2V9.333c0-1.102.898-2 2-2h5.334c1.102 0 2 .898 2 2v5.334zm-4.667-6A3.337 3.337 0 008.667 12 3.337 3.337 0 0012 15.333 3.337 3.337 0 0015.333 12 3.337 3.337 0 0012 8.667zM12 14c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm3.333-6a.667.667 0 100 1.334.667.667 0 000-1.334z"
          fill={color || 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={color || 'currentColor'} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </IconSvg>
  );
};
