import { Guid } from '@komo-tech/core/models/Guid';

export class SiteMenuProperties {
  ItemOrder: string;
  constructor(props?: Partial<SiteMenuProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.ItemOrder = props.ItemOrder || '[]';
  }

  get ItemOrderArray(): Guid[] {
    return parseItemOrder(this.ItemOrder).reduce((ids, itemId) => {
      const id = Guid.valueOrUndefined(itemId);
      if (id) {
        ids.push(id);
      }

      return ids;
    }, []);
  }
}

const parseItemOrder = (order: string): Guid[] => {
  try {
    return JSON.parse(order);
  } catch (e) {
    return [];
  }
};
