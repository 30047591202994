import { KeyOf } from '@komo-tech/core/models/path';
import { utcDateOrUndefined } from '@komo-tech/core/utils/date';
import { FieldValues, useFormContext } from 'react-hook-form';

import { ActionButton } from '../../ActionButton';
import { ChevronDownIcon } from '../../Icons/ChevronDownIcon';
import { Combobox } from '../Combobox';
import { useCombobox } from '../Combobox/useCombobox';

export interface FormHookDateTimeModifierDropdownProps<
  TFieldValues extends FieldValues = FieldValues
> {
  fieldKey: KeyOf<TFieldValues>;
  baseDate?: Date;
  baseDateKey?: KeyOf<TFieldValues>;
  modifiers: DateModifier[];
}

interface DateModifier {
  label: string;
  modifier: (date: Date) => Date;
}

export function FormHookDateTimeModifierDropdown<
  TFieldValues extends FieldValues = FieldValues
>({
  fieldKey,
  baseDate,
  baseDateKey,
  modifiers
}: FormHookDateTimeModifierDropdownProps<TFieldValues>) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption()
  });

  const { getValues, setValue } = useFormContext<TFieldValues>();

  const baseDateValue =
    utcDateOrUndefined(getValues()[baseDateKey]) || baseDate || new Date();

  const options = (modifiers || []).map((item) => (
    <Combobox.Option
      value={item.label}
      key={item.label}
      c={'var(--mantine-color-text)'}
    >
      {item.label}
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(v) => {
        const modifier = modifiers.find((m) => m.label === v);

        if (modifier) {
          setValue(fieldKey as any, modifier.modifier(baseDateValue) as any);
          combobox.closeDropdown();
        }
      }}
      position="bottom"
      width={200}
    >
      <Combobox.Target>
        <ActionButton
          variant="transparent"
          onClick={() => combobox.toggleDropdown()}
        >
          <ChevronDownIcon />
        </ActionButton>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
