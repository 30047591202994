import { KeyOf } from '@komo-tech/core/models/path';
import { nanoid } from '@komo-tech/core/utils/nanoid';
import React, {
  CSSProperties,
  FC,
  ReactNode,
  ReactText,
  Ref,
  useEffect,
  useMemo
} from 'react';

import { Button } from '../../Button';
import { CheckIcon } from '../../Icons/CheckIcon';
import { Label } from '../../Label';
import {
  BaseListRendererItemProps,
  ListDeleteRenderer
} from '../../List/ListRenderer';
import { FormGroup } from '../Group';
import { FormInputDescription } from '../InputDescription';
import { FormInputError } from '../InputError';

export interface FormFileInputProps<TFormValues extends object = any> {
  name: KeyOf<TFormValues>;
  onDelete: (file: string) => void;
  onUploadAsync: (files: FileList) => Promise<string[]>;
  uploading?: boolean;
  placeholder?: string;
  uploadDisabled?: boolean;
  required?: boolean;
  value?: string;
  inputRef?: Ref<HTMLInputElement>;
  label?: ReactNode;
  helpText?: string;
  hasError?: boolean;
  errorMessage?: string;
  containerStyle?: CSSProperties;
  mb?: ReactText;
  fileLimit?: number;
  pickFileOnMount?: boolean;
}
export const FormFileInputElement: FC<FormFileInputProps> = ({
  inputRef,
  onDelete,
  onUploadAsync,
  name,
  value,
  label,
  required,
  helpText,
  hasError,
  errorMessage,
  containerStyle,
  mb,
  fileLimit,
  uploading,
  uploadDisabled,
  placeholder,
  pickFileOnMount,
  ...rest
}) => {
  const id = useMemo(() => `${nanoid()}-${name as string}`, [name]);

  const getFileFormFieldPaths = (val?: string) => {
    try {
      return val ? (JSON.parse(val) as string[]) : [];
    } catch {
      return [];
    }
  };

  useEffect(() => {
    if (pickFileOnMount && (inputRef as any)?.current?.click) {
      (inputRef as any).current.click();
    }
  }, [inputRef]);

  const getCurrentValue = () => getFileFormFieldPaths(value);

  const getFileItemListItems = () => {
    const items: BaseListRendererItemProps[] = [];
    const files = getCurrentValue();
    if (!files?.length) return items;
    files.forEach((f, i) => {
      items.push({
        id: f,
        text: `File ${i + 1} uploaded`,
        icon: <CheckIcon />
      });
    });
    return items;
  };

  const resolveDisabled = () => {
    if (uploadDisabled) return true;
    if (fileLimit <= 0) return false;
    const files = getCurrentValue();
    return files.length >= fileLimit;
  };

  const disabled = resolveDisabled();

  return (
    <FormGroup mb={mb} display="block" style={containerStyle}>
      {label && (
        <Label htmlFor={id} required={required}>
          {label}
        </Label>
      )}
      <ListDeleteRenderer
        itemStyles={{ textColorVariant: 'green.9' }}
        items={getFileItemListItems()}
        onDelete={({ id }) => onDelete(id)}
      />
      <Button
        component="label"
        disabled={uploading || disabled}
        busy={uploading}
      >
        <input
          id={id}
          name={name as string}
          type="file"
          accept="image/*"
          disabled={disabled}
          onChange={async (e) => await onUploadAsync(e.currentTarget.files)}
          style={{ display: 'none' }}
          ref={inputRef}
          {...rest}
        />
        {placeholder || 'Upload file'}
      </Button>
      {helpText && !hasError && (
        <FormInputDescription>{helpText}</FormInputDescription>
      )}
      {hasError && errorMessage && (
        <FormInputError>{errorMessage}</FormInputError>
      )}
    </FormGroup>
  );
};
export const FormFileInput = React.forwardRef<
  HTMLInputElement,
  FormFileInputProps
>((props, ref) => <FormFileInputElement inputRef={ref} {...props} />);
FormFileInput.displayName = 'FormFileInput';
