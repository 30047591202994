export class GroupName {
  readonly Scope: string;
  readonly GroupId: string;
  constructor(props?: Partial<GroupName>) {
    props = props || {};
    Object.assign(this, props);
  }

  toString() {
    return `${this.Scope}::${this.GroupId}`;
  }
}

export interface HubConnectionMethodHandler {
  methodName: string;
  handler: (...args: any[]) => void;
  id: string;
}

export enum HubMethods {
  AddToGroup = 'AddToGroup',
  AddToGroups = 'AddToGroups',
  RemoveFromGroup = 'RemoveFromGroup',
  RemoveFromGroups = 'RemoveFromGroups'
}

export enum HubGroupScopes {
  Site = 'Site',
  UserSession = 'UserSession'
}

export const WebSocketBasePath = '/ws';
export const SiteHubPath = `${WebSocketBasePath}/hubs/site`;
