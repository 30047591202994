import { ElementProps as MantineElementProps } from '@mantine/core';
import { FC } from 'react';

import { Center, CenterProps } from '../Center';
import { CloseButton, CloseButtonProps } from '../CloseButton';
import classes from './ModalHeaderClose.module.css';

interface Props extends CloseButtonProps, MantineElementProps<'button'> {
  dataHelpId?: string;
  wrapperProps?: CenterProps;
}

export const ModalHeaderClose: FC<Props> = ({
  dataHelpId,
  wrapperProps,
  ...rest
}) => {
  return (
    <Center
      pl="0.5rem"
      pr="1.5rem"
      className={classes.wrapper}
      {...(wrapperProps || {})}
    >
      <CloseButton data-help-id={dataHelpId} size="lg" {...rest} />
    </Center>
  );
};
