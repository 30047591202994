'use client';
import type { PaperProps as MantinePaperProps } from '@mantine/core';
import {
  Paper as MantinePaper,
  PaperFactory as MantinePaperFactory,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';

export type PaperProps = MantinePaperProps;
type PaperReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantinePaperFactory>
>;
export const Paper = MantinePaper as PaperReturnType;
