import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { Anchor } from '@komo-tech/ui/Anchor';
import { FC } from 'react';

import { Image } from '@/common/components/Image';
import { useFrontPage } from '@/front/components/site/SiteHost/Store';

import classes from './SiteHeaderImage.module.css';

interface Props {
  image: ImageDataModel;
  height?: number;
}
export const SiteHeaderImage: FC<Props> = ({ image, height }) => {
  const isSingleCardPage = useFrontPage((x) => x.isSingleCardPage);
  const href = isSingleCardPage ? window.location.pathname : '/';

  return (
    <Anchor href={href} className={classes.root}>
      <Image
        isPriority
        image={image}
        style={{
          flex: '0 0',
          height: height ?? '50px',
          width: 'auto'
        }}
      />
    </Anchor>
  );
};
