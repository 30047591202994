import { nanoid } from '@komo-tech/core/utils/nanoid';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';

export interface FeedbackContextState {
  isBusy: boolean;
  unSavedState: UnSavedState;
  setUnSavedState: Dispatch<SetStateAction<UnSavedState>>;
  addWork: <T>(work: Promise<T>) => Promise<T>;
  clearWork: () => void;
}

/**
 * Throw error when FeedbackContext is used outside of context provider
 */
const invariantViolation = () => {
  throw new Error(
    'Attempted to call outside of Feedback context. Make sure your app is rendered inside FeedbackProvider.'
  );
};

export const FeedbackContext = createContext<FeedbackContextState>({
  isBusy: false,
  unSavedState: { hasChanges: false },
  setUnSavedState: invariantViolation,
  addWork: invariantViolation,
  clearWork: invariantViolation
});
FeedbackContext.displayName = 'FeedbackContext';

interface WorkItem<T = any> {
  id: string;
  work: Promise<T>;
}

interface Props {
  children: ReactNode;
}

interface UnSavedState {
  hasChanges: boolean;
  clearStateOnCallbackSuccess?: boolean;
  callback?: () => Promise<any>;
}
export const FeedbackProvider: FC<Props> = ({ children }) => {
  const [queue, setQueue] = useState<WorkItem[]>([]);
  const [unSavedState, setUnSavedState] = useState<UnSavedState>({
    hasChanges: false
  });

  const { clearWork, addWork } = useMemo(() => {
    const removeWork = (id: string) => {
      setQueue((state) => state.filter((x) => x.id !== id));
    };

    return {
      clearWork: () => {
        setQueue([]);
      },
      addWork: async (work: Promise<any>) => {
        const id = nanoid();
        try {
          const item: WorkItem = { id, work };
          setQueue((state) => [...state, item]);
          const response = await item.work;
          removeWork(id);
          return response;
        } catch (error) {
          removeWork(id);
          return Promise.reject(error);
        }
      }
    };
  }, []);

  const isBusy = queue.length > 0;

  const value = useMemo<FeedbackContextState>(
    () => ({
      isBusy,
      unSavedState,
      setUnSavedState,
      addWork,
      clearWork
    }),
    [isBusy, unSavedState.hasChanges]
  );
  return (
    <FeedbackContext.Provider value={value}>
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = (): FeedbackContextState => {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }
  return context;
};
