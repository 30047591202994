'use client';
import type { CloseButtonProps as MantineCloseButtonProps } from '@mantine/core';
import {
  CloseButton as MantineCloseButton,
  CloseButtonFactory as MantineCloseButtonFactory,
  polymorphicFactory as mantinePolymorphicFactory
} from '@mantine/core';

export type CloseButtonProps = MantineCloseButtonProps;
type CloseButtonReturnType = ReturnType<
  typeof mantinePolymorphicFactory<MantineCloseButtonFactory>
>;
/**
 * @see https://mantine.dev/core/close-button/
 */
export const CloseButton = MantineCloseButton as CloseButtonReturnType;
