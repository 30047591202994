import { PaperProps as MantinePaperProps } from '@mantine/core';
import cx from 'clsx';
import { forwardRef, ReactNode, useMemo } from 'react';

import { Paper } from '../Paper';
import classes from './Modal.module.css';
import { ModalSizeVariantProps, ModalStyler } from './ModalStyles';

interface ModalContentProps extends MantinePaperProps, ModalSizeVariantProps {
  children?: ReactNode;
}

export const ModalContent = forwardRef<HTMLDivElement, ModalContentProps>(
  ({ children, sizeVariant, className, radius = 0, ...rest }, ref) => {
    const sizePadding = useMemo(() => {
      const dto = ModalStyler.sizeData(sizeVariant);
      return `${dto.paddingY} ${dto.paddingXOutside}`;
    }, [sizeVariant]);

    return (
      <Paper
        flex={1}
        ref={ref}
        className={cx(classes.content, className)}
        p={sizePadding}
        radius={radius}
        {...rest}
      >
        {children}
      </Paper>
    );
  }
);
