import { FormHiddenField } from '@komo-tech/ui/Form/HiddenField';
import { FieldValues } from 'react-hook-form';

import { DynamicFormContextData, IAmDynamicFormFieldProps } from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormHiddenField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  contextData,
  disabled
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => <FormHiddenField name={h.field.name} value={h.field.value} />}
    </DynamicFormHookField>
  );
}
