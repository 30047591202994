import { SiteUser } from '../SiteUser';

export class SiteEntryConfirmationResponse {
  currentUser?: SiteUser;
  conditionPassed: boolean;

  constructor(props?: Partial<SiteEntryConfirmationResponse>) {
    props = props || {};
    Object.assign(this, props);
    this.currentUser = props.currentUser
      ? new SiteUser(props.currentUser)
      : undefined;
  }
}
