import { Guid } from '@komo-tech/core/models/Guid';
import { useDebouncedCallback } from '@komo-tech/ui/hooks/debounce';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect } from 'react';

import { PublicCardsService } from '@/front/data/Cards';

import { useSignalRContextReconnectedKey } from '../SignalRProvider';
import { CardUpdatedEvent, SiteHubMethods } from './_types';
import { useSiteHubSubscribe } from './useSiteHubSubscribe';

const CardCacheKeys = PublicCardsService.cacheKeys;

interface Props {
  cardId: Guid;
  disabled: boolean;
  fetchOnLoad?: boolean;
  fetchOnReconnect?: boolean;
}

const debouncedRefetchTime = 4_000;

/**
 * For the given card descriptor, listen to any "OnCardUpdated" events and refresh from the API.
 * Returns the live-updating card descriptor.
 * Must be called within a SignalRContext and a SiteContext.
 */
export const useCardDescriptorSignalRUpdateSync = ({
  cardId,
  disabled = false,
  fetchOnReconnect = false
}: Props) => {
  const queryClient = useQueryClient();

  const subscribe = useSiteHubSubscribe();

  const refetchAsync = useDebouncedCallback(() => {
    return Promise.all([
      queryClient.invalidateQueries(CardCacheKeys.descriptor(cardId)),
      queryClient.invalidateQueries(CardCacheKeys.typed(cardId))
    ]);
  }, debouncedRefetchTime);

  useEffect(() => {
    if (disabled) {
      return () => {};
    }

    const unsubscribe = subscribe(
      SiteHubMethods.OnCardUpdated,
      (e: CardUpdatedEvent) => {
        if (!cardId.equals(e.cardId)) {
          return;
        }

        queryClient.setQueryData(
          CardCacheKeys.descriptor(cardId),
          e.cardDescriptor
        );
        queryClient.setQueryData(CardCacheKeys.typed(cardId), e.typedCard);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [disabled, subscribe, cardId]);

  // refetch on reconnect
  const reconnectedKey = useSignalRContextReconnectedKey();

  useEffect(() => {
    if (fetchOnReconnect && !disabled && reconnectedKey > 0) {
      refetchAsync();
    }
  }, [fetchOnReconnect, reconnectedKey]);
};

export const CardDescriptorSignalRUpdateSync: FC<Props> = (props) => {
  useCardDescriptorSignalRUpdateSync(props);
  return null;
};
