import { forwardRef, ReactNode } from 'react';

import { Group } from '../Group';

export const FeedbackDisplay = forwardRef<
  HTMLDivElement,
  { children?: ReactNode }
>(({ children }, ref) => {
  return (
    <Group ref={ref} align="center" c={'dimmed'} fz="xs" mb={0} gap={'0.5rem'}>
      {children}
    </Group>
  );
});
