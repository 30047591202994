import { IAmFormHookField } from '@komo-tech/ui/Form/HookField';
import { FormQrCodeReader } from '@komo-tech/ui/Form/QrCodeReader';
import { useActionHandler } from '@komo-tech/ui/hooks/useActionHandler';
import { useOnMount } from '@komo-tech/ui/hooks/useOnMount';
import { FC, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  QrCodeDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormQrCodeField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => <FieldElement hook={h} field={field} {...rest} />}
    </DynamicFormHookField>
  );
}

interface FieldElementProps
  extends IAmFormHookField,
    Pick<IAmDynamicFormFieldProps, 'field' | 'size' | 'id'> {}

const FieldElement: FC<FieldElementProps> = ({ field, hook, id, size }) => {
  const {
    onCheckAsync,
    defaultIsValid,
    description,
    label,
    labelProps,
    onError
  } = field.data.options as QrCodeDynamicFieldOptions;

  const [isValid, setIsValid] = useState(defaultIsValid && !!hook.field.value);
  const [handleAsync, { isHandling }] = useActionHandler();

  const handleCheckAsync = (value: string) => {
    return handleAsync(() => onCheckAsync(value), {
      onSuccess: (r) => {
        if (r?.success) {
          setIsValid(true);
          hook.setFieldValue(r.data.code);
        } else {
          hook.setFieldValue('');
          setIsValid(false);
        }
      }
    });
  };

  useOnMount(() => {
    if (!hook.field.value) {
      return;
    }

    const defaultValue = hook.field.value;
    hook.setFieldValue('');
    handleCheckAsync(defaultValue);
  });

  return (
    <FormQrCodeReader
      ref={hook.field.ref}
      size={size}
      isChecking={isHandling}
      required={hook.required}
      id={id}
      disabled={hook.disabled}
      isValid={isValid}
      onCheckAsync={handleCheckAsync}
      label={label}
      labelProps={labelProps}
      description={description}
      onError={onError}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
};
